import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import setupAxios from './redux/setupAxios';
//import * as serviceWorker from './serviceWorker';
import { AuthProvider } from './auth/provider/authProvieder';
import Routes from './Routes';
import store from "./redux/store";
import { Provider } from 'react-redux'

setupAxios(axios, store);
ReactDOM.render(
    <Provider store={store}>
        <AuthProvider>
            <Routes />
        </AuthProvider>
    </Provider>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();