import axios from 'axios';
import { URL_COVERS } from './config';

export async function getPCoversList() {
    const result = await axios.get(URL_COVERS);
    return result.data.data;
};//SELECT * FROM admin_panel.sp_get_packs_summary()

export async function getPCoversDetails(id) {
    const result = await axios.get(`${URL_COVERS}/${id}`);
    return result.data.data;
    //console.log(result.data.data);
};//admin_panel.sp_get_subscription_to_cancel

export async function CoversEdit(id, data) {
    const result = await axios.put(`${URL_COVERS}/${id}`, data);
    console.log(result);
    return result.data; 
};

/*
export async function postMakeCancel(data) {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }//// CAMBIAR POR LA DATA QUE SE NECESITA PARA CANCELAR UNA SUBSCRIPCION
    };
    const result = await axios.post(`${URL_CANCEL_SUBSCRIPTION}`, data, config);
    return result.data;
};*/