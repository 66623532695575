import { createSlice } from '@reduxjs/toolkit';

const initialStateHotmersList = {
    listLoading: false,
    actionsLoading: false,
    hotmersList: undefined,
    hotmerDetail: undefined,
    success: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const hotmersListSlice = createSlice({
    name: "hotmersList",
    initialState: initialStateHotmersList,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        catchSuccess: (state, action) => {
            state.error = null;
            state.success = action.payload.success;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        startCall: (state, action) => {
            state.error = null;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            };
        },
        //When delete hotmer. You can send success message
        deleteHotmer: (state, action) => {
            state.error = null;
            if (action.payload.success) {
                state.success = action.payload.success;
            } else {
                state.success = null;
            };
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
            if (Array.isArray(state.hotmersList)) {
                state.hotmersList = state.hotmersList.filter(hotmer => hotmer.id !== action.payload.id);
            };
        },
        // get List Users
        hotmersListFetched: (state, action) => {
            state.listLoading = false;
            state.hotmersList = action.payload.hotmersList;
            state.error = null;
        },
        // get hotmer detail
        hotmerDetailFetched: (state, action) => {
            state.listLoading = false;
            state.hotmerDetail = action.payload.hotmerDetail;
            state.error = null;
        },
        clear: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.hotmerDetail = undefined;
            state.error = null;
            state.success = null;
        },
        clearAll: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.hotmersList = undefined;
            state.hotmerDetail = undefined;
            state.error = null;
            state.success = null;
        }
    }
});