import { combineReducers } from "redux";
import { authSlice } from '../auth/_redux/authSlice';
import { usersListSlice } from '../modules/pages/users/usersList/_redux/usersListSlice';
import { hotmersListSlice } from '../modules/pages/users/hotmersList/_redux/hotmesrListSlice';
import { dashboardSlice } from '../modules/pages/dashboard/_redux/dashboardSlice';
import { pPendingsSlice } from '../modules/pages/financial/paymentsPending/_redux/pPendingsSlice';
import { pMadeSlice } from '../modules/pages/financial/paymentsMade/_redux/pMadeSlice';
import { bHotmerSlice } from '../modules/pages/financial/balanceHotmer/_redux/bHotmerSlice';
//import { pPaymentsSlice } from '../modules/pages/financial/paymentsList/_redux/pPaymentsSlice';
import { pAgencyListSlice } from '../modules/pages/users/agencyList/_redux/agencyListSlice';
import { pCollaborationListSlice } from '../modules/pages/agreement/collaborationList/_redux/collaborationListSlice'
import { pCommercialListSlice } from '../modules/pages/agreement/commercialList/_redux/commercialListSlice'
//DROPDOWN STATES
import { gendersListSlice } from '../modules/components/dropdowns/genders/_redux/gendersSlice';
import { documentsListSlice } from '../modules/components/dropdowns/documents/_redux/documentsSlice';
import { countriesListSlice } from '../modules/components/dropdowns/countries/_redux/countriesSlice';
import { rolesListSlice } from '../modules/components/dropdowns/roles/_redux/rolesSlice';
import { packsStatusListSlice } from '../modules/components/dropdowns/packStatus/_redux/PacksStatusSlice';

//STATS DROPDOWNS
import { statsListSlice } from '../modules/components/dropdowns/stats/_redux/statsSlice'
import { pPaymentsSlice } from "../modules/pages/financial/paymentsList/_redux/pPaymentsSlice";
import { pSubscriptionsSlice } from "../modules/pages/financial/subscriptionList/_redux/pSubscriptionsSlice";
import { pPacksSlice } from "../modules/pages/content/packList/_redux/pPacksSlice";
import { pCoversSlice } from "../modules/pages/content/coverList/_redux/pCoversSlice";

export const rootReducer = combineReducers({
    auth: authSlice.reducer,

    //DASHBOARD
    dashboard: dashboardSlice.reducer,
    //USERS
    usersList: usersListSlice.reducer,
    //HOTMERS
    hotmersList: hotmersListSlice.reducer,
    //PAYMENTS PENDINGS
    pPendings: pPendingsSlice.reducer,
    //PAYMENTS LIST
    pPayments: pPaymentsSlice.reducer,
    //PAYMENTS LIST
    pSubscriptions: pSubscriptionsSlice.reducer,
    //Packs LIST
    pPacks: pPacksSlice.reducer,
    //Cover List
    pCovers: pCoversSlice.reducer,
    //PAYMENTS MADE
    pMade: pMadeSlice.reducer,
    //BALANCE HOTMER
    bHotmer: bHotmerSlice.reducer,
    //Colaboracion List LIST
    pAgreementCollaboration: pCollaborationListSlice.reducer,
    //acuerdos comerciales LIST
    pAgreementCommercial: pCommercialListSlice.reducer,
    //HOTMERS
    pAgencyList: pAgencyListSlice.reducer,

    //DROPDOWN STATES
    genders: gendersListSlice.reducer,
    documents: documentsListSlice.reducer,
    countries: countriesListSlice.reducer,
    roles: rolesListSlice.reducer,
    packstatus: packsStatusListSlice.reducer,

    //STATS DROPDOWNS
    stats: statsListSlice.reducer,
});


