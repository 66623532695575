import React, { useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actionspSubscriptions from '../../pages/financial/subscriptionList/_redux/pSubscriptionsActions';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';


const DialogPPendingMake = ({ item, show, setShow }) => {

    const dispatch = useDispatch();
    const { actionsLoading } = useSelector(
        state => state.pSubscriptions,
        shallowEqual
    );

    const closeDialog = () => {
        setShow(false);
    };
    useEffect(() => {
        if (!actionsLoading) {
            setShow(false);
        };
    }, [actionsLoading])
    const renderFooter = () => {
        return (
            <>
                <Button
                    label="Close" icon="pi pi-times" className={`p-button-secondary`} autoFocus
                    onClick={() => {
                        closeDialog();
                    }}
                />

            </>
        )
    };
    const Hedaer = () => {
        return (
            <div className="p-d-flex p-jc-between p-ai-center p-pt-3 p-pl-3 p-pr-3 p-mb-4" style={{ border: '2px solid #d0d0d0', padding: 5, borderTopRightRadius: 5, borderTopLeftRadius: 5 }}>
                <h2 className="bold"> Cancel Subscription of - {item.id_user_of_origin}</h2>
                <Button onClick={() => closeDialog()} icon="pi pi-times" className="p-button-rounded p-button-secondary p-button-text" />
            </div>
        )
    };

    return (
        <Dialog
            className="p-col-12 p-md-8"
            closable={false}
            visible={show} onHide={closeDialog}
            position="center"
            draggable={false} resizable={false} baseZIndex={1000}
            footer={renderFooter}
        >
            <Hedaer />
            {item ?
                <Card className="p-col-12">
                    <div className="p-d-flex p-fluid p-formgrid p-grid">
                        <h2 className="p-col-12">Details</h2>
                        <div className="p-col-12 p-md-6">
                            <p><b>Nº Profit: </b>{item.id_profit_account}</p>
                            <p><b>Status: </b>{item.ds_name_status}</p>
                            <p><b>Month Payed: </b>{item.bl_payed}</p>
                            <p><b>Month to Cancel: </b>{item.vl_month}</p>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <p><b>Date Start: </b>{moment(item.dt_insert).format("YYYY/MM/DD")}</p>
                            <p><b>Date Finish: </b>{moment(item.dt_month_finish).format("YYYY/MM/DD")}</p>
                            <p><b>User: </b>{item.id_user_of_origin}</p>
                            <p><b>Hotmer: </b>{item.id_user_of_destination}</p>
                        </div>
                    </div>
                    <Formik
                        initialValues={{
                            ds_observations: ''
                                        }}
                        validationSchema={Yup.object({
                            ds_observations: Yup.string()
                                .max(255, "255 is the maximum number of characters")
                                .required('Observations for subscription is required!')
                               /* .matches(
                                    /^\w+$/,
                                    "Only numbers and characters"
                                )*/
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            const formDataServer = new FormData();                         
                            formDataServer.append('id_user_of_origin', item.id_user_of_origin);
                            formDataServer.append('id_user_of_destination', item.id_user_of_destination);
                            formDataServer.append('id_transaction_subscription_profit_detail', item.id_transaction_subscription_profit_detail);
                            formDataServer.append('ds_observations', values.ds_observations);
                            dispatch(actionspSubscriptions.fetchMakeCancel(formDataServer));
                            setSubmitting(false);
                        }}
                    >
                        {formik =>
                            <Form className="p-mt-4" >
                                <div className="p-d-flex p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-6">
                                        <label htmlFor="ds_observations">Observations</label>
                                        <InputText disabled={actionsLoading}
                                            value={formik.values.ds_observations}
                                            maxLength={255}
                                            onChange={e => formik.setFieldValue('ds_observations', e.currentTarget.value)}
                                        />
                                        <small className="p-error p-d-block"><ErrorMessage name="ds_observations" /></small>
                                    </div>
                                </div>
                                <div className="p-d-flex p-jc-center">
                                    <div>
                                        <Button type="submit" label="Cancel Subscription" disabled={actionsLoading}
                                            icon={`pi ${actionsLoading ? `pi-spin pi-spinner` : `pi-check`}`}
                                            className="p-button-danger" />
                                    </div>
                                </div>
                            </Form>
                        }
                    </Formik>
                </Card>
                :
                <h1 className="p-text-center">Subscription to Cancel Not Found</h1>
            }
        </Dialog>
    )
};

export default DialogPPendingMake;