import * as requestFromServer from './pCoversCrud'
import { pCoversSlice, callTypes } from './pCoversSlice';

const { actions } = pCoversSlice;

export const fetchCoversList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getPCoversList(data)
        .then(response => {
            const pCovers = response;
            dispatch(actions.pCoversFetched({ pCovers }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting Covers list", callType: callTypes.list }));
        });
};

export const fetchCoversDetails = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getPCoversDetails(id)
        .then(response => {
            const pCoversDetails = response;
            dispatch(actions.pCoversDetailsFetched({ pCoversDetails }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting Covers detail", callType: callTypes.list }));
        });
};


export const editCover = (id, data) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .CoversEdit(id, data)
        //.CoversEdit(id, data)
        .then(response => {
            if (response && response.status === "Success") {
                dispatch(actions.catchSuccess({ success: "Covers edit sucess", callType: callTypes.action }));
                fetchCoversDetails(id);
            } else {
                dispatch(actions.catchError({ error: "Failed to edit Cover", callType: callTypes.action }));
            };
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Failed to edit Cover", callType: callTypes.action }));
        });
};



///////////// POR SI HAY QUE HACER OTRO POST.
/*export const fetchMakeCancel = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .postMakeCancel(data)
        .then(response => {
            if (response && response.status === "Success") {
                dispatch(actions.catchSuccess({ success: "Make Cancel", callType: callTypes.action }));
                dispatch(fetchSubscriptionsList({}))
            } else {
                dispatch(actions.catchError({ error: "Failed to make cancel subscription", callType: callTypes.action }));
            };
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error to make cancel subscription", callType: callTypes.action }));
        });
};
*/
export const clear = id => dispatch => {
    dispatch(actions.clear());
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
