import { createSlice } from '@reduxjs/toolkit';

const initialStateUsersList = {
    listLoading: false,
    actionsLoading: false,
    usersList: undefined,
    userDetail: undefined,
    success: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const usersListSlice = createSlice({
    name: "usersList",
    initialState: initialStateUsersList,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        catchSuccess: (state, action) => {
            state.error = null;
            state.success = action.payload.success;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        startCall: (state, action) => {
            state.error = null;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            };
        },
        //When delete user. You can send success message
        deleteUSer: (state, action) => {
            state.error = null;
            if (action.payload.success) {
                state.success = action.payload.success;
            } else {
                state.success = null;
            };
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
            if (Array.isArray(state.usersList)) {
                state.usersList = state.usersList.filter(user => user.id !== action.payload.id);
            };
        },
        // get List Users
        usersListFetched: (state, action) => {
            state.listLoading = false;
            state.usersList = action.payload.usersList;
            state.error = null;
        },
        // get user detail
        userDetailFetched: (state, action) => {
            state.listLoading = false;
            state.userDetail = action.payload.userDetail;
            state.error = null;
        },
        clear: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.userDetail = undefined;
            state.error = null;
            state.success = null;
        },
        clearAll: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.usersList = undefined;
            state.userDetail = undefined;
            state.error = null;
            state.success = null;
        }
    }
});