import { createSlice } from '@reduxjs/toolkit';

const auth = JSON.parse(sessionStorage.getItem('auth'));
const initialAuthState = {
    listLoading: false,
    actionsLoading: false,
    auth,
    error: null
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const authSlice = createSlice({
    name: "auth",
    initialState: initialAuthState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        startCall: (state, action) => {
            state.error = undefined;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            };
        },
        // getauth By user and pass
        authFetched: (state, action) => {
            state.auth = action.payload.auth.data;
            sessionStorage.setItem('auth', JSON.stringify(action.payload.auth.data));
            state.listLoading = false;
            state.error = null;
        },
        reloadAuth: (state, action) => {
            state.auth = JSON.stringify(sessionStorage.getItem('auth'));
            state.error = null;
            state.actionsLoading = false;
        },
        clearAll: (state, action) => {
            sessionStorage.clear();
            state.auth = undefined;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
        }
    }
});