import { createSlice } from '@reduxjs/toolkit';

const initialStatePMade = {
    listLoading: false,
    actionsLoading: false,
    pMade: undefined,
    pMadeDetails: undefined,
    success: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const pMadeSlice = createSlice({
    name: "pMade",
    initialState: initialStatePMade,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        catchSuccess: (state, action) => {
            state.error = null;
            state.success = action.payload.success;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        startCall: (state, action) => {
            state.error = null;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            };
        },
        // get List pMade
        pMadeFetched: (state, action) => {
            state.listLoading = false;
            state.pMade = action.payload.pMade;
            state.error = null;
        },
        // get pMade detail
        pMadeDetailsFetched: (state, action) => {
            state.listLoading = false;
            state.pMadeDetails = action.payload.pMadeDetails;
            state.error = null;
        },
        clear: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.pMadeDetails = undefined;
            state.error = null;
            state.success = null;
        },
        clearAll: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.pMade = undefined;
            state.pMadeDetails = undefined;
            state.error = null;
            state.success = null;
        }
    }
});