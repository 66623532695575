import axios from 'axios';
import { URL_HOTMERS_LIST } from './config';

export async function getHotmersList() {
    const result = await axios.get(URL_HOTMERS_LIST);
    return result.data.data;
};

export async function getHotmerDetail(id) {
    const result = await axios.get(`${URL_HOTMERS_LIST}/${id}`);
    return result.data.data;
};

export async function hotmerEdit(id, data) {
    const result = await axios.put(`${URL_HOTMERS_LIST}/${id}`, data);
    return result.data;
};

export async function hotmerDelete(id) {
    const result = await axios.delete(`${URL_HOTMERS_LIST}/${id}`);
    return result.data;
};

export async function hotmerValidate(id, data) {
    const result = await axios.post(`${URL_HOTMERS_LIST}/${id}/approbation`, data);
    return result.data;
};