import { createSlice } from '@reduxjs/toolkit';

const initialStatePPaymentDetails = {
    listLoading: false,
    actionsLoading: false,
    paymentList: undefined,
    paymentDetails: undefined,
    success: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const pPaymentsSlice = createSlice({
    name: "pPayments",
    initialState: initialStatePPaymentDetails,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        catchSuccess: (state, action) => {
            state.error = null;
            state.success = action.payload.success;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        startCall: (state, action) => {
            state.error = null;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            };
        },
        // get List PPendings
        pPaymentFetched: (state, action) => {
            state.listLoading = false;
            state.pPayments = action.payload.pPayments;
            state.error = null;
        },
        // get pPendings detail
        pPendingDetailsFetched: (state, action) => {
            state.listLoading = false;
            state.pPaymentsDetails = action.payload.pPaymentsDetails;
            state.error = null;
        },
        clear: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.paymentDetails = undefined;
            state.error = null;
            state.success = null;
        },
        clearAll: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.paymentList = undefined;
            state.paymentDetails = undefined;
            state.error = null;
            state.success = null;
        }
    }
});