import React, { useState }from 'react';
import { shallowEqual, useSelector } from "react-redux";
// import * as actionsStatsList from './_redux/statsActions';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
// import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

const DropdownChest = ({ select = undefined, selected, setSelected }) => {
    const [change, setChange] = useState(false);
    // const toast = useRef(null);
    // const dispatch = useDispatch();
    const { statsList, listLoading } = useSelector(
        state => state.stats,
        shallowEqual
    );
    // useEffect(() => {
    //     if (!statsList && !error && !listLoading) {
    //         dispatch(actionsStatsList.fetchStatsList({}));
    //     };
    // }, [statsList, error, listLoading, dispatch]);
    // useEffect(() => {
    //     if (error) {
    //         toast.current.show({ severity: 'error', summary: 'Stats', detail: error });
    //     };
    // }, [error]);
    return (
        <div>
            {/* <Toast ref={toast} position="bottom-right" /> */}
            {select && !change ?
                <div className="p-inputgroup">
                    <InputText value={select} disabled />
                    <Button icon="pi pi-pencil" style={{ color: 'white' }} className="p-button-warning" onClick={() => setChange(true)} type="button" />
                </div>
                :
                !statsList && listLoading ?
                    <div className="p-d-flex p-jc-center">
                        <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="8" animationDuration="3s" />
                    </div>
                    :
                    <div className="p-d-flex p-flex-row">
                        <Dropdown className={select ? "p-col-10" : "p-col-12"} optionLabel="chest" optionValue="id_type_of_chest" value={selected}
                            options={statsList && statsList.type_of_chest}
                            onChange={(e) => setSelected(e.value)}
                            placeholder="Select a chest"
                        />
                        {select &&
                            <Button icon="pi pi-times" className="p-button-danger"
                                onClick={() => {
                                    setSelected(0);
                                    setChange(false);
                                }}
                                type="button"
                                tooltip="Cancel"
                            />
                        }
                    </div>
            }
        </div>
    )
}

export default DropdownChest;