import * as requestFromServer from './gendersCrud'
import { gendersListSlice, callTypes } from './gendersSlice';

const { actions } = gendersListSlice;

export const fetchGendersList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getGendersList(data)
        .then(response => {
            const gendersList = response;
            dispatch(actions.gendersListFetched({ gendersList }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting genders list", callType: callTypes.list }));
        });
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
