import React, { useEffect, useRef, useState, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AuthContext } from '../../../../auth/provider/authProvieder';
import * as actionspSubscriptions from './_redux/pSubscriptionsActions';
import { BreadCrumb } from 'primereact/components/breadcrumb/BreadCrumb';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import DialogPSubscriptionsDetail from '../../../components/dialogs/DialogPSubscriptionsDetail';
//import DialogPPendingMake from '../../../components/dialogs/DialogPPendingMake';  /// esto lo tengo que cambiar.
import DialogPCancelSubscription from '../../../components/dialogs/DialogPCancelSubscriptionPreview';  /// esto lo 
import { commonTemplate, dateTemplate } from '../../../components/dialogs/dataTableTemplates';
import { filterOptions } from '../../../functions/arrayFunctions'

const SubscriptionsPending = () => {
    const [selectedItem, setSelectedItem] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');
    const [showDialogDetail, setshowDialogDetail] = useState(false);
    const [showDialogMake, setShowDialogMake] = useState(false);
    const [orderFilter, setOrderFilter] = useState('');
    const [selectedMethod, setSelectedMethod] = useState(null);

    const [iduserdestinyfilter, setiduserdestinyfilter] = useState('');
    const [iduseroriginFilter, setiduseroriginFilter] = useState('');
    const [idprofitFilter, setidprofitFilter] = useState('');
    const [DateDesdeFilter, setDateDesdeFilter] = useState('');
    const [DateHastaFilter, setDateHastaFilter] = useState('');

    const [selectedStatus, setselectedStatus] = useState(null);
    const [selectedPayed, setselectedPayed] = useState(null);
    const [selectedUserCancel, setselectedUserCancel] = useState(null);


    const toast = useRef(null);
    const dt = useRef(null);
    const { isPSubscriptionsAuthorized } = useContext(AuthContext);
    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    const breadcrumbItems = [
        { label: 'Financial' },
        { label: 'Subscription List' },
    ];
    const dispatch = useDispatch();
    const { pSubscriptions, pSubscriptionsDetails, listLoading, actionsLoading, error, success } = useSelector(
        state => state.pSubscriptions,
        shallowEqual
    );

    useEffect(() => {
        if (!pSubscriptions) {
            dispatch(actionspSubscriptions.fetchSubscriptionsList({}));
        };
    }, [pSubscriptions, dispatch]);

    //TOAST DETAIL NOT FOUND
    useEffect(() => {
        if (!listLoading && !pSubscriptionsDetails && showDialogDetail) {
            toast.current.show({ severity: 'warn', summary: 'Subscription', detail: 'Subscription detaill not found' });
        }
    }, [listLoading, pSubscriptionsDetails, showDialogDetail]);

    //ERROR TOAST
    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Subscription', detail: error });
        };
    }, [error]);

    //SUCCESS TOAST
    useEffect(() => {
        if (success) {
            toast.current.show({ severity: 'success', summary: 'Subscription', detail: success });
        };
    }, [success]);

    useEffect(() => {
        return () => {
            setSelectedItem('');
            setshowDialogDetail(false);
            dispatch(actionspSubscriptions.clearAll({}));
        }
    }, [dispatch]);

    const leftToolbarTemplate = () => {
        return (
            <>
                <Button label="Reload" icon="pi pi-refresh" className="p-button-warning p-ml-2"
                    onClick={() => {
                        dispatch(actionspSubscriptions.fetchSubscriptionsList({}));
                    }}
                    disabled={listLoading || actionsLoading}
                />
            </>
        )
    };

    const rightToolbarTemplate = () => {
        return (
            <>
                <Button label="Export" icon="pi pi-upload" className="p-button-help p-ml-2"
                    disabled={listLoading || actionsLoading} /*onClick={exportCSV}*/
                    onClick={()=>dt.current.exportCSV()}
                    />
            </>
        )
    };
    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Subscription List</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowdata) => {
        return (
            <>
                {isPSubscriptionsAuthorized(1) &&
                    <Button tooltip="Details" icon="pi pi-eye" className="p-button-rounded p-button-info p-mr-2"
                        onClick={() => {
                            dispatch(actionspSubscriptions.fetchSubscriptionsDetails(rowdata.id_extraction_order));  /// ESTO NO FUNCIONA
                            setshowDialogDetail(true);
                        }}
                    />
                }
                {isPSubscriptionsAuthorized(2) &&
                    <Button tooltip="Cancel Subscription" icon="pi pi-exclamation-circle" className="p-button-rounded p-button-danger p-mr-2"
                        onClick={() => {
                            setSelectedItem(rowdata);
                            setShowDialogMake(true);
                        }}
                    />
                }
            </>
        );
    };
    //FILTERS

     ///id_user_of_origin
     const iduseroriginFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'id_user_of_origin', 'contains');
        setiduseroriginFilter(e.currentTarget.value);
     };
    const iduseroriginFilterCustom =
    <InputText
        value={iduseroriginFilter}
        placeholder="user"
        onChange={iduseroriginFilterChange}
        style={{ width: "100%" }}
    />;

            //id_user_of_destination
            const iduserdestinyFilterChange = (e) => {
                dt.current.filter(e.currentTarget.value, 'id_user_of_destination', 'contains');
                setiduserdestinyfilter(e.currentTarget.value);
        };
            const iduserdestinyFilterCustom =
            <InputText
                value={iduserdestinyfilter}
                placeholder="hotmer"
                onChange={iduserdestinyFilterChange}
                style={{ width: "100%" }}
            />;
            const idprofitFilterChange = (e) => {
                dt.current.filter(e.currentTarget.value, 'id_profit_account', 'contains');
                setidprofitFilter(e.currentTarget.value);
            };

            //id_profit
            const idprofitFilterCustom =
                <InputText
                    value={idprofitFilter}
                    placeholder="Nº"
                    onChange={idprofitFilterChange}
                    style={{ width: "100%" }}
                />;    


                // DIVISION DE MESES
    const methodFilterChange = ({ value }) => {
        dt.current.filter(value, 'vl_month', 'in');
        setSelectedMethod(value)
    };

    
    const methodFilter =
        <MultiSelect style={{ width: "100%" }} className="p-column-filter" value={selectedMethod} options={filterOptions(pSubscriptions, "vl_month")}
            onChange={methodFilterChange} placeholder="All"
    />;

/*
 const [selectedStatus, setselectedStatus] = useState(null);
    const [selectedPayed, setselectedPayed] = useState(null);
    const [selectedUserCancel, setselectedUserCancel] = useState(null);
*/ 

        // PAGADO 
    const PayedFilterChange = ({ value }) => {
        dt.current.filter(value, 'bl_payed', 'in');
        setselectedPayed(value)
    };  
    const PayedFilterCustom =
        <MultiSelect style={{ width: "100%" }} className="p-column-filter" value={selectedPayed} options={filterOptions(pSubscriptions, "bl_payed")}
            onChange={PayedFilterChange} placeholder="All"
    />;

            // Cancelado 
            const UserCancelFilterChange = ({ value }) => {
                dt.current.filter(value, 'bl_user_cancel', 'in');
                setselectedUserCancel(value)
            };  
            const UserCancelFilterCustom =
                <MultiSelect style={{ width: "100%" }} className="p-column-filter" value={selectedUserCancel} options={filterOptions(pSubscriptions, "bl_user_cancel")}
                    onChange={UserCancelFilterChange} placeholder="All"
            />;
        


        const nameFilterChange = ({ value }) => {
            dt.current.filter(value, 'ds_name_status', 'in');
            setselectedStatus(value)
        };  
        const nameFilterCustom =
            <MultiSelect style={{ width: "100%" }} className="p-column-filter" value={selectedStatus} options={filterOptions(pSubscriptions, "ds_name_status")}
                onChange={nameFilterChange} placeholder="All"
        />;

        const DesdeDateFilterChange = (e) => {
            dt.current.filter(e.currentTarget.value, 'dt_month_start', 'contains');
            setDateDesdeFilter(e.currentTarget.value);
        };
        const DesdeDateFilterCustom =
            <InputText
                value={DateDesdeFilter}
                placeholder="Date"
                onChange={DesdeDateFilterChange}
                style={{ width: "100%" }}
        />;
        //FECHA FIN
        const HastaDateFilterChange = (e) => {
            dt.current.filter(e.currentTarget.value, 'dt_month_finish', 'contains');
            setDateHastaFilter(e.currentTarget.value);
        };
        const HastaDateFilterCustom =
            <InputText
                value={DateHastaFilter}
                placeholder="Date"
                onChange={HastaDateFilterChange}
                style={{ width: "100%" }}
        />;
    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12 card">
                <Toast ref={toast} position="bottom-right" onClose={() => setshowDialogDetail(false)} />
                <BreadCrumb className="p-mb-2" home={breadcrumbHome} model={breadcrumbItems} />
                {listLoading && !pSubscriptions ?
                    <div className="p-d-flex p-jc-center">
                        <ProgressSpinner />
                    </div>
                    :
                    <>
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
                        <DataTable ref={dt}
                            value={pSubscriptions}
                            selection={selectedItem} onSelectionChange={(e) => setSelectedItem(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[ 10, 25, 50, 75, 100, 200]}
                            className="p-datatable-customers p-datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} payments pendings"
                            loading={listLoading || actionsLoading}
                            globalFilter={globalFilter} emptyMessage="Not payments pendings found." header={header}>
                            {/*<Column headerStyle={{ width: '8%' }} className="p-text-center" field="id_transaction_subscription_profit_detail" header="Order" sortable body={e => commonTemplate(e.id_transaction_subscription_profit_detail)} filter filterElement={orderFilterCustom} />*/}
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="id_user_of_origin" header="id User" sortable body={e => commonTemplate(e.id_user_of_origin)} filter filterElement={iduseroriginFilterCustom} />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="id_user_of_destination" header="id Hotmer" sortable body={e => commonTemplate(e.id_user_of_destination)} filter filterElement={iduserdestinyFilterCustom} />
                            <Column className="p-text-center" field="dt_month_start" header="Date Start" sortable body={e => dateTemplate(e.dt_month_start)} filter filterElement={DesdeDateFilterCustom} />
                            <Column className="p-text-center" field="dt_month_finish" header="Date Finish" sortable body={e => dateTemplate(e.dt_month_finish)} filter filterElement={HastaDateFilterCustom} />
                            <Column className="p-text-center" field="vl_month" header="Mes Actual/Mes Total" sortable body={e => commonTemplate(e.vl_month)} filter filterElement={methodFilter} />
                            <Column className="p-text-center" field="ds_name_status" header="Status" sortable body={e => commonTemplate(e.ds_name_status)} filter filterElement={nameFilterCustom} /> 
                            <Column className="p-text-center" field="bl_payed" header="Mes Pagado a Hotmer" sortable body={e => commonTemplate(e.bl_payed)} filter filterElement={PayedFilterCustom} />
                            <Column className="p-text-center" field="bl_user_cancel" header="Usuario Cancelo" sortable body={e => commonTemplate(e.bl_user_cancel)} filter filterElement={UserCancelFilterCustom} />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="id_profit_account" header="id Profit" sortable body={e => commonTemplate(e.id_profit_account)} filter filterElement={idprofitFilterCustom} />
                            <Column field="action" header="Actions" sortable body={actionBodyTemplate} />
                        </DataTable>
                    </>
                }
            </div>
            <DialogPCancelSubscription
                item={selectedItem}
                show={showDialogMake}
                setShow={setShowDialogMake}
            />
            <DialogPSubscriptionsDetail
                payment={pSubscriptionsDetails}
                show={!listLoading && pSubscriptionsDetails && showDialogDetail}
                setShow={setshowDialogDetail}
            />
        </div>
    )
}

export default SubscriptionsPending;