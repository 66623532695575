export default function setupAxios(axios, store) {
    axios.interceptors.request.use(
        config => {
            // const {
            //   auth: { authToken }
            // } = store.getState();

            const auth = sessionStorage.getItem('auth');
            let authParse;
            if (auth) {
                authParse = JSON.parse(auth);
            };

            if (authParse && authParse.token) {
                const authToken = authParse.token;
                config.headers.Authorization = `Bearer ${authToken}`;
            };

            return config;
        },
        err => Promise.reject(err)
    );
}
