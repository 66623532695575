import * as requestFromServer from './pSubscriptionsCrud'
import { pSubscriptionsSlice, callTypes } from './pSubscriptionsSlice';

const { actions } = pSubscriptionsSlice;

export const fetchSubscriptionsList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getPSubscriptionsList(data)
        .then(response => {
            const pSubscriptions = response;
            dispatch(actions.pSubscriptionsFetched({ pSubscriptions }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting Subscriptions list", callType: callTypes.list }));
        });
};

export const fetchSubscriptionsDetails = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getPSubscriptionsDetails(id)
        .then(response => {
            const pSubscriptionsDetails = response;
            dispatch(actions.pSubscriptionsDetailsFetched({ pSubscriptionsDetails }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting Subscriptions detail", callType: callTypes.list }));
        });
};


///////////// revisar esto.
export const fetchMakeCancel = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .postMakeCancel(data)
        .then(response => {
            if (response && response.status === "Success") {
                dispatch(actions.catchSuccess({ success: "Make Cancel", callType: callTypes.action }));
                dispatch(fetchSubscriptionsList({}))
            } else {
                dispatch(actions.catchError({ error: "Failed to make cancel subscription", callType: callTypes.action }));
            };
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error to make cancel subscription", callType: callTypes.action }));
        });
};

export const clear = id => dispatch => {
    dispatch(actions.clear());
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
