import * as requestFromServer from './agencyListCrud'
import { pAgencyListSlice, callTypes } from './agencyListSlice';

const { actions } = pAgencyListSlice;

export const fetchAgencyList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getAgencyList(data)
        .then(response => {
            const pAgencyList = response;
           // console.log("🚀 ~ file: agencyListActions.js ~ line 12 ~ agencyList", agencyList)
            
            dispatch(actions.agencyListFetched({ pAgencyList }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting agency list", callType: callTypes.list }));
        });
};
