import * as requestFromServer from './pMadeCrud'
import { pMadeSlice, callTypes } from './pMadeSlice';

const { actions } = pMadeSlice;

export const fetchPMadeList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getPMadeList(data)
        .then(response => {
            const pMade = response;
            dispatch(actions.pMadeFetched({ pMade }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting made list", callType: callTypes.list }));
        });
};

export const clear = id => dispatch => {
    dispatch(actions.clear());
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
