import React, { useEffect, useState, useRef, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AuthContext } from '../../../../auth/provider/authProvieder';
import * as actionsCommercialList from './_redux/commercialListActions';
import { Link } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { BreadCrumb } from 'primereact/components/breadcrumb/BreadCrumb';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import '../../../styles/customers.scss'
import { status } from '../../../functions/hotmerFunctions';
import moment from 'moment';

const CommercialList = () => {
    const [selectedUser, setSelectedUser] = useState('');
    //const [showDialogDetail, setshowDialogDetail] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');
    const [userStatus, setUserStatus] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const { isAgreementAuthorized } = useContext(AuthContext);
    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    const breadcrumbItems = [
        { label: 'Agreement' },
        { label: 'Commercial List' },
    ];
    const dispatch = useDispatch();
    const { pAgreementCommercial, listLoading, actionsLoading, error, success } = useSelector(
        state => state.pAgreementCommercial,
        shallowEqual
    );

    useEffect(() => {
        if (!pAgreementCommercial) {
            dispatch(actionsCommercialList.fetchCommercialList({}));
        };
    }, [pAgreementCommercial, dispatch]);

    useEffect(() => {
        return () => {
            setSelectedUser('');
            //setshowDialogDetail(false);
        }
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Commercial', detail: error });
        };
    }, [error]);
    useEffect(() => {
        if (success) {
            toast.current.show({ severity: 'success', summary: 'Commercial', detail: success });
        };
    }, [success]);

    const leftToolbarTemplate = () => {
        return (
            <>
                <Button label="Reload" icon="pi pi-refresh" className="p-button-warning p-ml-2"
                    onClick={() => {
                        setSelectedUser('');
                        dispatch(actionsCommercialList.fetchCommercialList({}));
                    }}
                    disabled={listLoading || actionsLoading}
                />
            </>
        )
    };

    const rightToolbarTemplate = () => {
        return (
            <>
                <Button label="Export" icon="pi pi-upload" className="p-button-help p-ml-2"
                    disabled={listLoading || actionsLoading} /*onClick={exportCSV}*/ 
                    onClick={()=>dt.current.exportCSV()}
                    />
            </>
        )
    };
    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Agreement Commercials</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    
        
    const nickNameTemplate = (rowData) => {
        // return <Chip label={rowData.ds_full_name} image={rowData.ds_profile_photo_thumbnail_path}  />
        return (
                <span>{rowData.ds_full_name_origin}</span>
        )
    };


       const idBodyTemplate = (rowData) => {
        return <span>{rowData.id_user_origin}</span>
    };

    const idDestinyBodyTemplate = (rowData) => {
        return <span>{rowData.id_user_destiny}</span>
    };

    const TypeRolBodyTemplate = (rowData) => {
        return (
            <>
                {/*<span className={`flag flag-${rowData.ds_flag_code.toLowerCase()}`} style={{ width: 30, height: 20 }} />*/}
                <span style={{ marginLeft: '.5em', verticalAlign: 'left' }}>{rowData.ds_type_of_role_origin}</span>
            </>
        );
    };
    const vlPackBodyTemplate = (rowData) => {
        return <span style={{ fontWeight: 'bold'}}>{rowData.vl_agreement_pack}</span>
    };

    const vlSubscriptionBodyTemplate = (rowData) => {
        return <span style={{ fontWeight: 'bold'}}>{rowData.vl_agreement_subscription}</span>
    };
    
    const vlTipnBodyTemplate = (rowData) => {
        return <span style={{ fontWeight: 'bold'}}>{rowData.vl_agreement_tip}</span>
    };

    const blActiveTemplate = (rowData) => {
        return <span>{rowData.bl_agreement_status}</span>
    };



    const dateLastPackTemplate = (rowData) => {
        return <span>{moment(rowData.dt_start_date).format("DD/MM/YYYY")}</span>
    };


    

    

    
    


    const actionBodyTemplate = (rowdata) => {
        return (
            <>
                {isAgreementAuthorized(1) &&
                    <Button tooltip="Details" icon="pi pi-eye" className="p-button-rounded p-button-info p-mr-1"
                        onClick={() => {
                            dispatch(actionsCommercialList.fetchCommercialList(rowdata.id_user));
                            //setshowDialogDetail(true);
                        }}
                    />
                }
                {isAgreementAuthorized(2) &&
                    <Link to={`/agreement/commercial/${rowdata.id_user}`}>
                        <Button tooltip="Edit" icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-1"
                        />
                    </Link>
                }
            </>
        );
    };

    //FILTERS CUSTOMS

    const userStatuses = [
        { label: 'All', value: null },
        { label: 'PENDING', value: 0 },
        { label: 'QUALIFIED', value: 3 },
        { label: 'UNQUALIFIED', value: 4 },
    ];
    const onStatusChange = (event) => {
        dt.current.filter(event.value, 'id_status_hotmer', 'equals');
        setUserStatus(event.value);
    };
    const statusItemTemplate = ({ label }) => {
        return <span className={`customer-badge status-${label.toLowerCase()}`}>{label}</span>
    };
    const statusFilterCustom =
        <Dropdown className="ui-column-filter" style={{ width: '80%' }}
            value={userStatus} options={userStatuses} onChange={onStatusChange} itemTemplate={statusItemTemplate}
        />;

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12 card">
                <Toast ref={toast} position="bottom-right" />
                <BreadCrumb className="p-mb-2" home={breadcrumbHome} model={breadcrumbItems} />
                {listLoading && !pAgreementCommercial ?
                    <div className="p-d-flex p-jc-center">
                        <ProgressSpinner />
                    </div>
                    :
                    <>
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                        <DataTable ref={dt}
                            value={pAgreementCommercial} selection={selectedUser} onSelectionChange={(e) => setSelectedUser(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[ 10, 25, 50, 75, 100, 200]}
                            className="p-datatable-customers p-datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                            loading={listLoading || actionsLoading}
                            globalFilter={globalFilter} emptyMessage="Not users found." header={header}>
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="id_user_origin" header="Id origin" sortable body={idBodyTemplate} filter filterPlaceholder="id origin" />
                            <Column headerStyle={{ width: '12%' }} className="p-text-center" field="ds_full_name_origin" header="Nick" sortable body={nickNameTemplate} filter filterPlaceholder="Name" />
                            <Column headerStyle={{ width: '12%' }} className="p-text-center" field="ds_type_of_role_origin" header="Role" sortable body={TypeRolBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Role" />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="id_user_destiny" header="id hotmer destiny" sortable body={idDestinyBodyTemplate} filter filterPlaceholder="id destiny" />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="vl_agreement_pack" header="Value Pack" sortable body={vlPackBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Pack" />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="vl_agreement_subscription" header="Value subscripction" sortable body={vlSubscriptionBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Subsc" />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="vl_agreement_tip" header="Value tip" sortable body={vlTipnBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="tip" />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="bl_agreement_status" header="Active" sortable body={blActiveTemplate} filter filterMatchMode="contains" filterPlaceholder="Activo" /> 
                            <Column headerStyle={{ width: '14%' }} className="p-text-center" field="dt_start_date" header="Date Start" sortable body={dateLastPackTemplate} filter filterMatchMode="contains" filterPlaceholder="Date" /> 
                            <Column headerStyle={{ width: '10%' }} className="p-text-center" header="Actions" sortable body={actionBodyTemplate} />
                        </DataTable>
                    </>
                }
            </div>
        </div>
    )
}

export default CommercialList;