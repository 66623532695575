import React, { useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actionsPendings from '../../pages/financial/paymentsPending/_redux/pPendingsActions';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';


const DialogPPendingMake = ({ user, show, setShow }) => {

    const dispatch = useDispatch();
    const { actionsLoading } = useSelector(
        state => state.pPendings,
        shallowEqual
    );

    const closeDialog = () => {
        setShow(false);
    };
    useEffect(() => {
        if (!actionsLoading) {
            setShow(false);
        };
    }, [actionsLoading])
    const renderFooter = () => {
        return (
            <>
                <Button
                    label="Close" icon="pi pi-times" className={`p-button-secondary`} autoFocus
                    onClick={() => {
                        closeDialog();
                    }}
                />

            </>
        )
    };
    const Hedaer = () => {
        return (
            <div className="p-d-flex p-jc-between p-ai-center p-pt-3 p-pl-3 p-pr-3 p-mb-4" style={{ border: '2px solid #d0d0d0', padding: 5, borderTopRightRadius: 5, borderTopLeftRadius: 5 }}>
                <h2 className="bold">Payment Make - {user.full_name}</h2>
                <Button onClick={() => closeDialog()} icon="pi pi-times" className="p-button-rounded p-button-secondary p-button-text" />
            </div>
        )
    };

    return (
        <Dialog
            className="p-col-12 p-md-8"
            closable={false}
            visible={show} onHide={closeDialog}
            position="center"
            draggable={false} resizable={false} baseZIndex={1000}
            footer={renderFooter}
        >
            <Hedaer />
            {user ?
                <Card className="p-col-12">
                    <div className="p-d-flex p-fluid p-formgrid p-grid">
                        <h2 className="p-col-12">Details</h2>
                        <div className="p-col-12 p-md-6">
                            <p><b>Nº order: </b>{user.id_extraction_order}</p>
                            <p><b>Currency origen: </b>{user.ds_currency_origen}</p>
                            <p><b>Currency detination: </b>{user.ds_currency_destination}</p>
                            <p><b>Covertion: </b>{user.bl_conversion ? "Yes" : "No"}</p>
                            <p><b>Status: </b>{user.ds_status}</p>
                            <p><b>Mail: </b>{user.ds_email}</p>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <p><b>Date insert: </b>{moment(user.dt_insert).format("YYYY/MM/DD")}</p>
                            <p><b>Date expiration: </b>{moment(user.dt_expiration_payment).format("YYYY/MM/DD")}</p>
                            <p><b>Convertion to local currency: </b>${user.vl_conversion_rate}</p>
                            <p><b>Withdarw: </b>${user.vl_withdraw}</p>
                            <p><b>Method: </b>{user.ds_type_of_payment_method}</p>
                        </div>
                    </div>
                    <Formik
                        initialValues={{
                            voucherCode: '',
                            voucherFile: ''
                        }}
                        validationSchema={Yup.object({
                            voucherCode: Yup.string()
                                .max(255, "255 is the maximum number of characters")
                                .required('Voucher code is required!')
                                .matches(
                                    /^\w+$/,
                                    "Only numbers and characters"
                                ),
                            voucherFile: Yup.string()
                                .required('Voucher file is required!')
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            const { voucherFile } = values;
                            const formDataServer = new FormData();
                            formDataServer.append('receipt', voucherFile);
                            formDataServer.append('id_hotmer', user.id_user);
                            formDataServer.append('id_extraction_order', user.id_extraction_order);
                            formDataServer.append('ds_identificator', values.voucherCode);
                            dispatch(actionsPendings.fetchMakePayment(formDataServer));
                            setSubmitting(false);
                        }}
                    >
                        {formik =>
                            <Form className="p-mt-4" >
                                <div className="p-d-flex p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="voucherCode">Voucher Code</label>
                                        <InputText disabled={actionsLoading}
                                            value={formik.values.voucherCode}
                                            maxLength={255}
                                            onChange={e => formik.setFieldValue('voucherCode', e.currentTarget.value)}
                                        />
                                        <small className="p-error p-d-block"><ErrorMessage name="voucherCode" /></small>
                                    </div>
                                    <div className="p-field p-col-12">
                                        <label htmlFor="voucherFile">Voucher file</label>
                                        <div className="p-d-flex p-ai-center" >
                                            <FileUpload mode="basic" disabled={actionsLoading}
                                                previewWidth={60}
                                                name="voucherFile" accept=".png, .jpg, .jpeg"
                                                onSelect={e => {
                                                    formik.setFieldValue('voucherFile', e.files[0])
                                                }}
                                                emptyTemplate={<p className="p-m-0">Drag and drop files to here to upload.</p>}
                                            />
                                            <Button disabled={actionsLoading} icon="pi pi-times" className="p-button-warning" type="button"
                                                onClick={() => formik.setFieldValue('voucherFile', '')}
                                            />
                                        </div>
                                        <small className="p-error p-d-block"><ErrorMessage name="voucherFile" /></small>
                                    </div>
                                </div>
                                <div className="p-d-flex p-jc-center">
                                    <div>
                                        <Button type="submit" label="Make" disabled={actionsLoading}
                                            icon={`pi ${actionsLoading ? `pi-spin pi-spinner` : `pi-check`}`}
                                            className="p-button-success" />
                                    </div>
                                </div>
                            </Form>
                        }
                    </Formik>
                </Card>
                :
                <h1 className="p-text-center">User not found</h1>
            }
        </Dialog>
    )
};

export default DialogPPendingMake;