import React, { useState } from 'react';
import * as  actionsHotmersList from '../_redux/hotmesrListActions'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Fieldset } from 'primereact/fieldset';
import InnerImageZoom from 'react-inner-image-zoom'
import DropdownDocuments from '../../../../components/dropdowns/documents/DropdownDocuments';
import { status } from '../../../../functions/hotmerFunctions';

const Identity = ({ id }) => {
    const dispatch = useDispatch();
    const { hotmerDetail, actionsLoading } = useSelector(
        state => state.hotmersList,
        shallowEqual
    );
    const [zoomFront, setZoomFront] = useState(1);
    const [zoomBack, setZoomBack] = useState(1);
    const [zoomSelfie, setZoomSelfie] = useState(1);
    const [approved, setApproved] = useState(true);
    const { full_name, ds_nick_name, ds_address, ds_country, ds_type_of_gender_name, ds_document_id, id_status_profile_hotmer, vl_score,
        ds_type_of_role_name, ds_instagram, ds_url_photo_id_front, ds_url_photo_id_reverse, ds_url_photo_id_holding } = hotmerDetail.identity;

    const initialValues = {
        bl_approbation: false,
        bl_certificated: false,
        ds_comment: '',
        vl_score: vl_score ? vl_score : 0,
        id_type_of_document: undefined,
        ds_document_id: ds_document_id ? ds_document_id : '',
    };
    const validationSchema = Yup.object({
        ds_comment: Yup.string()
            .max(255, 'Maximum characters 255'),
        vl_score: Yup.number()
            .min(0, 'Min score is 0')
            .max(10, 'Max score is 10')
            .required('Score is required'),
        id_type_of_document: Yup.number()
            .required('Type of document is required'),
        ds_document_id: Yup.string()
           // .matches("^[0-9]+$", 'Only numbers')
            .min(7, 'Minimum characters 7')
            .required('Document number is required'),
    });

    return (
        <>
            <h3 className="p-text-center">Application Information</h3>
            <Fieldset className="p-mb-2" legend="Data" toggleable>
                <div className="p-formgrid p-grid">
                    <p className="p-col-12 p-md-6"><b>Full Name: </b>{full_name}</p>
                    <p className="p-col-12 p-md-6"><b>Status: </b>{status(id_status_profile_hotmer)}</p>
                </div>
                <p><b>hotmerDetail Name: </b>{ds_nick_name}</p>
                <p><b>Address: </b>{ds_address}</p>
                <p><b>Country: </b>{ds_country}</p>
                <p><b>Gener: </b>{ds_type_of_gender_name}</p>
                <p><b>Rol: </b>{ds_type_of_role_name}</p>
                <p><b>Instagram: </b>{ds_instagram}</p>
            </Fieldset>
            <Fieldset className="p-mb-2" legend="Attachment" toggleable>
                <div className="p-d-flex p-fluid p-formgrid p-grid">
                    <div className="p-col-12">
                        <label>Zoom front</label>
                        <div className="p-col-12 p-md-3">
                            <InputNumber
                                id="horizontal" value={zoomFront}
                                onChange={e => setZoomFront(e.value)}
                                min={0.5}
                                showButtons buttonLayout="horizontal" step={0.25}
                                decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success"
                                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" minFractionDigits={0} maxFracionDigits={1}
                            />
                        </div>
                        <InnerImageZoom src={ds_url_photo_id_front}
                            className="p-mt-2"
                            zoomSrc={ds_url_photo_id_front}
                            style={{ width: 'auto', height: '30%' }} display='block'
                            zoomScale={zoomFront}
                        />
                    </div>
                    <div className="p-col-12">
                        <label>Zoom back</label>
                        <div className="p-col-12 p-md-3">
                            <InputNumber
                                id="horizontal" value={zoomBack}
                                onChange={e => setZoomBack(e.value)}
                                min={0.5}
                                showButtons buttonLayout="horizontal" step={0.25}
                                decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success"
                                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" minFractionDigits={0} maxFracionDigits={1}
                            />
                        </div>
                        <InnerImageZoom src={ds_url_photo_id_reverse}
                            className="p-mt-2"
                            zoomSrc={ds_url_photo_id_reverse}
                            style={{ width: 'auto', height: '30%' }} display='block'
                            zoomScale={1}
                        />
                    </div>
                </div>
            </Fieldset>
            <Fieldset className="p-mb-2" legend="Holding" toggleable>
                <div className="p-d-flex p-fluid p-formgrid p-grid">
                    <div className="p-col">
                        <label>Zoom Selfie</label>
                        <div className="p-col-6">
                            <InputNumber
                                style={{ width: '50%' }}
                                id="horizontal" value={zoomSelfie}
                                onChange={e => setZoomSelfie(e.value)}
                                min={0.5}
                                showButtons buttonLayout="horizontal" step={0.25}
                                decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success"
                                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" minFractionDigits={0} maxFracionDigits={1}
                            />
                        </div>
                        <InnerImageZoom
                            className="p-mt-2"
                            src={ds_url_photo_id_holding}
                            zoomSrc={ds_url_photo_id_holding}
                            style={{ width: 'auto', height: '30%' }} display='block'
                            zoomScale={2}
                        />
                    </div>
                </div>
            </Fieldset>
            <Fieldset className="p-mb-2" legend="Aprobation" toggleable>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        // const result = difference(initialValues, values);
                        // console.log(values)

                        //Cuando el hotmer es aprobado no es obligatorio el comentario
                        //Cuando el hotmer es rechazado el comentario es obligatorio

                        //Si el hotmer es aprobado
                        if (approved) {
                            dispatch(actionsHotmersList.validateHotmer(id, values, values.bl_approbation));
                        } else {
                            //Si se rechaza y no tiene comentario se pide completarlo
                            if (values.ds_comment === '') {
                                values.ds_document_id = ''
                                values.id_type_of_document = undefined;
                                setSubmitting(false);

                                //Si se rechaza y tiene comentario, se envia al back con los campos solicitados    
                            } else {
                                if (values.ds_document_id === '0000000') {
                                    values.ds_document_id = 'null';
                                };
                                if (values.id_type_of_document === -1) {
                                    values.id_type_of_document = 7;
                                };
                                dispatch(actionsHotmersList.validateHotmer(id, values, values.bl_approbation));
                            };
                        };
                        setSubmitting(false);
                    }}
                >
                    {formik =>
                        <Form>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-mt-3">
                                    <span className="p-float-label">
                                        <InputTextarea disabled={actionsLoading || id_status_profile_hotmer !== 0}
                                            maxLength={255}
                                            rows={5} cols={30} autoResize
                                            id="comments" type="text"
                                            value={formik.values.ds_comment}
                                            onChange={e => formik.setFieldValue('ds_comment', e.currentTarget.value)}
                                        />
                                        <label htmlFor="ds_comment">Comments</label>
                                    </span>
                                    {!approved && formik.values.ds_comment === '' && <small className="p-error p-d-block">Comment is required</small>}
                                </div>
                                <div className="p-col-12 p-d-flex p-jc-between">
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="vl_score">Score</label>
                                        <div className="p-inputgroup">
                                            <InputNumber id="vl_score"
                                                disabled={actionsLoading || id_status_profile_hotmer !== 0}
                                                max={10} min={0} value={formik.values.vl_score}
                                                onChange={e => formik.setFieldValue('vl_score', e.value ? e.value : 0)}
                                                showButtons buttonLayout="horizontal"
                                                mode="decimal" minFractionDigits={0} maxFracionDigits={1} step={0.25}
                                                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                            />
                                            <Button disabled={actionsLoading || id_status_profile_hotmer !== 0} icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('vl_score', 0)} type="button" />
                                        </div>
                                        <small className="p-error p-d-block"><ErrorMessage name="vl_score" /></small>
                                    </div>
                                    <div className="p-field p-col-12 p-md-2">
                                        <label htmlFor="bl_certificated">Certificated </label>
                                        <div className="p-inputgroup">
                                            <InputSwitch disabled={actionsLoading || id_status_profile_hotmer !== 0} checked={formik.values.bl_certificated} onChange={e => formik.setFieldValue('bl_certificated', !formik.values.bl_certificated)} />
                                        </div>
                                        <small className="p-error p-d-block"><ErrorMessage name="bl_certificated" /></small>
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="id_type_of_document">Type document</label>
                                        <DropdownDocuments
                                            select={hotmerDetail.identity.ds_document}
                                            selected={formik.values.id_type_of_document}
                                            setSelected={value => formik.setFieldValue('id_type_of_document', value)}
                                            disabled={actionsLoading || id_status_profile_hotmer !== 0}
                                        />
                                        {approved && <small className="p-error p-d-block"><ErrorMessage name="id_type_of_document" /></small>}
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="ds_document_id">Document number</label>
                                        <div className="p-inputgroup">
                                            <InputText disabled={actionsLoading || id_status_profile_hotmer !== 0} id="ds_document_id" value={approved ? formik.values.ds_document_id : ''} onChange={e => formik.setFieldValue('ds_document_id', e.currentTarget.value)} />
                                            <Button disabled={actionsLoading || id_status_profile_hotmer !== 0} icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('ds_document_id', '')} type="button" />
                                        </div>
                                        {approved && <small className="p-error p-d-block"><ErrorMessage name="ds_document_id" /></small>}
                                    </div>
                                </div>
                            </div>
                            {id_status_profile_hotmer === 0 &&
                                <div className="p-d-flex p-jc-end p-mb-2">
                                    <div>
                                        <Button icon={`pi ${approved && actionsLoading ? `pi-spin pi-spinner` : `pi-check`}`}
                                            onClick={() => {
                                                setApproved(true);
                                                formik.setFieldValue('bl_approbation', true);
                                            }}
                                            type="submit"
                                            label="Aprobar" className="p-button-success p-m-3"
                                            disabled={!formik.errors || actionsLoading}
                                        />
                                        <Button icon={`pi ${!approved && actionsLoading ? `pi-spin pi-spinner` : `pi-check`}`}
                                            onClick={() => {
                                                setApproved(false);
                                                formik.setFieldValue('bl_approbation', false);
                                                if (!formik.values.ds_document_id) {
                                                    formik.setFieldValue('ds_document_id', '0000000');
                                                };
                                                if (!formik.values.id_type_of_document) {
                                                    formik.setFieldValue('id_type_of_document', -1);
                                                };
                                            }}
                                            type="submit"
                                            label="Rechazar" className="p-button-danger p-m-3"
                                            disabled={actionsLoading}
                                        />
                                    </div>
                                </div>
                            }
                        </Form>
                    }
                </Formik >
            </Fieldset>

        </>
    )
}

export default Identity
