import * as requestFromServer from './countriesCrud'
import { countriesListSlice, callTypes } from './countriesSlice';

const { actions } = countriesListSlice;

export const fetchCountriesList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getCountriesList(data)
        .then(response => {
            const countriesList = response;
            dispatch(actions.countriesListFetched({ countriesList }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting countries list", callType: callTypes.list }));
        });
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
