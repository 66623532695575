import React from "react";

function CardImage(props) {
    const isImageURL = props.image;
    // If an image was passed:
    if (isImageURL) {
        return (
            <div className="styleImage">
                <img
                    style={{ width: props.width + "px", height: props.height + "px"}}
                    src={props.image}
                    alt="Content"
                />
            </div>
        );
    }
    return null;
}

function CardContent(props) {
    return (
        <div className="styleCardContent">
            <p className="styleCardTitle">{props.idContentGroup} / {props.idContentImage}</p>
            <p className="styleDescription">{props.ds_comment}</p>
            <p className="styleLocationLabel">{props.location}</p>
            <p className="styleDescription">Activo: {props.bl_active}</p>
        </div>
    );
}

export default class CardsContent extends React.Component {
    render() {
        return (
            // <div style={{ width: this.props.width + "px" }}>
                <div className="styleCard">
                    <CardImage image={this.props.image} width={150} height={150} />
                    <CardContent
                        idContentGroup={this.props.idContentGroup}
                        idContentImage={this.props.idContentImage}
                        ds_comment={this.props.ds_comment}
                        location={this.props.location}
                        bl_active={this.props.bl_active}
                    />
                </div>
            //  </div>
        );
    }
}