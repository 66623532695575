import React, { useState, useEffect, useRef } from 'react'
import * as actionsPacksList from './_redux/pPacksActions';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/components/breadcrumb/BreadCrumb';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import PacksInfo from './editComponents/ContentPacks';
/*import Stats from './editComponents/Stats';
import Identity from './editComponents/Identity';*/


const PackEdit = (props) => {
    const toast = useRef(null);
    const dispatch = useDispatch();
    const { pPacksDetails, listLoading, actionsLoading, error, success } = useSelector(
        state => state.pPacks,
        shallowEqual
    );

    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    const breadcrumbItems = [
        { label: 'Content' },
        { label: 'Packs list', url: '/content/packslist' },
        { label: 'Edit' },
    ];

    useEffect(() => {
        if (!pPacksDetails) {
            dispatch(actionsPacksList.fetchPacksDetails(props.match.params.id));
        };
    }, [pPacksDetails, dispatch]);

    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Packs detail', detail: error });
        };
    }, [error]);

    useEffect(() => {
        if (success) {
            toast.current.show({ severity: 'success', summary: 'Packs', detail: success });
        };
    }, [success]);

    useEffect(() => {
        return () => {
            dispatch(actionsPacksList.clear({}));
        };
    }, [dispatch]);

    const leftToolbarTemplate = () => {
        return (
            <>
                <Button label="Reload" icon="pi pi-refresh" className="p-button-warning p-ml-2"
                    onClick={() => {
                        dispatch(actionsPacksList.fetchPacksDetails(props.match.params.id));
                    }}
                    disabled={listLoading || actionsLoading}
                />
            </>
        )
    };
    const items = [
        { label: 'Info', icon: 'pi pi-info-circle', component: <PacksInfo id={props.match.params.id} />, position: 0 }
     
    ];
    const [activeItem, setActiveItem] = useState(0);
    return (
        <div className="card">
            <BreadCrumb className="p-mb-2" home={breadcrumbHome} model={breadcrumbItems} />
            <Toast ref={toast} position="bottom-right" />
            <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>
            <TabMenu model={items} activeItem={items[activeItem]} onTabChange={e => setActiveItem(e.value.position)} />
            {!listLoading ?
                pPacksDetails ?
                    <div className="p-m-3">{items[activeItem].component}</div>
                    :
                    <h3>Packs details not found</h3>
                :
                <div className="p-d-flex p-jc-center">
                    <ProgressSpinner />
                </div>
            }
        </div>
    )
}

export default PackEdit;