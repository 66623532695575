import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const DialogCustom = ({ show, setShow, type = "info", icon = "", position = "center", header, description, functionOk }) => {

    const closeDialog = () => {
        setShow(false);
    };

    const renderFooter = () => {
        return (
            <>
                <Button label="No" icon="pi pi-times" onClick={closeDialog} className={`p-button-raised p-button-${type} p-button-text`} autoFocus />
                <Button
                    label="Yes"
                    icon="pi pi-check"
                    className={`p-button-${type}`}
                    onClick={() => {
                        functionOk();
                        closeDialog();
                    }}
                />
            </>
        );
    }
    return (
        <>
            <Dialog
                style={{ width: '30vw' }}
                header={header} visible={show} onHide={closeDialog}
                position={position}
                draggable={false} resizable={false} baseZIndex={1000}
                footer={renderFooter}
            >
                <div className="p-d-flex p-ai-center">
                    <span className={`pi ${icon} p-pr-3`} style={{ fontSize: 30 }} />{description}
                </div>
            </Dialog>
        </>
    )
}

export default DialogCustom;