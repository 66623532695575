import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { TabMenu } from 'primereact/tabmenu';
import CardsContent from "././dialogHotmer/Cards";
import "././dialogHotmer/styles.css";

// import Gallery from "react-photo-gallery";
import moment from 'moment';

const DialogPpacksDetails = ({ pack, show, setShow }) => {

    const closeDialog = () => {
        setShow(false);
    };
    const Header = () => {
        return (
            <div className="p-d-flex p-jc-between p-ai-center p-pt-3 p-pl-3 p-pr-3 p-mb-5" style={{ border: '2px solid #d0d0d0', padding: 5, borderTopRightRadius: 5, borderTopLeftRadius: 5 }}>
                <h1 className="bold">{pack.ds_title}</h1>
                <Button onClick={() => closeDialog()} icon="pi pi-times" className="p-button-rounded p-button-secondary p-button-text" />
            </div>
        )
    };

    const Summary = () => {
        return (
            <div className="p-col-12">
                <div className="p-d-flex p-fluid p-formgrid p-grid">
                    <h2 className="p-col-12">Details</h2>
                    <div className="p-col-12 p-md-6">
                        <p><b>Id Hotmer: </b>{pack.id_hotmer}</p>
                        <p><b>id Pack: </b>{pack.id_pack}</p>
                        <p><b>Price: </b>{pack.vl_price}</p>
                        <p><b>Active In Search Pack: </b>{pack.bl_active_in_search}</p>
                        <p><b>Pack Score: </b>{pack.vl_score}</p>
                        <p><b>Description: </b>{pack.ds_description}</p>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <div className="product-badge status-qualified" style={{ color: 'green' }}> Status: {pack.ds_status_name}
                            <div className="product-badge status-qualified" style={{ color: 'green' }}> Qty Sales: {pack.count_seller_of_pack}
                            </div>
                        </div>
                        <p><b>Qty Images Created: </b>{pack.count_image}</p>
                        <p><b>Qty Videos: </b>{pack.count_video}</p>
                        <p><b>Date Created: </b>{moment(pack.dt_insert).format("YYYY/MM/DD")}</p>
                    </div>
                </div>
            </div>
        )
    };

    const ViewPack = () => {

        const listFotos = pack?.content_pack?.images?.map((fotos) =>
            <CardsContent
                idContentGroup={fotos.id_pack_image}
                image={fotos.src}
                bl_active={fotos.bl_active}
                location={fotos.dt_publish}
            />
        );

        const listVideos = pack?.content_pack?.videos?.map((fotos) => 
            <CardsContent
                idContentGroup={fotos.id_pack_image}
                image={fotos.ds_url_thumbnail}
                bl_active={fotos.bl_active}
                location={fotos.dt_publish}
            />
        );

        return (
            <div>
                <h2 className="p-col-12">PACK</h2>
                <p><b>Description: </b>{pack.ds_description}</p>
                <h2 className="p-col-12">Photos</h2>
                <div className='image-grid'>
                    {listFotos}

                </div>
                {/* <Gallery 
                photos={pack?.content_pack?.images} />; */}
                <h2 className="p-col-12">Video Captura</h2>
                <div className='image-grid'>
                    {listVideos}
                </div>
            </div>
        )
    }



    const items = [
        { label: 'Summary', icon: 'pi pi-info-circle', component: <Summary />, position: 0 },
        { label: 'ViewPack', icon: 'pi pi-eye', component: <ViewPack />, position: 1 },
    ];
    const [activeItem, setActiveItem] = useState(0);
    return (
        <>
            {pack &&
                <Dialog
                    className="p-col-8"
                    closable={false}
                    visible={show} onHide={closeDialog}
                    position="center"
                    draggable={false} resizable={false} baseZIndex={1000}
                // footer={renderFooter}
                >
                    <Header />
                    <div className="p-fluid p-formgrid p-grid" style={{ borderBottom: '1px solid #d0d0d0' }}>
                        <div className="p-d-none p-d-lg-block p-p-3 p-col-3" style={{ borderRight: '1px solid #d0d0d0' }}>
                            <div className="p-d-flex p-flex-column p-ai-center p-mb-2">
                                <Avatar className="p-mb-5" image={pack?.ds_url_thumbnail} style={{ width: 200, height: 200 }} />
                            </div>
                            {/*<b>Referral Code:</b>
                            <p>{item.Resume.referral_code}</p>*/}
                        </div>
                        <div className="p-p-3 p-col-12 p-lg-9">
                            <TabMenu model={items} activeItem={items[activeItem]} onTabChange={e => setActiveItem(e.value.position)} />
                            <div className="p-mt-3">{items[activeItem].component}</div>
                        </div>
                    </div>
                </Dialog>
            }
        </>
    )
}

export default DialogPpacksDetails;