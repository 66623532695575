import axios from 'axios';
import { URL_PENDINGS, URL_MAKE } from './config';

export async function getPPendingsList() {
    const result = await axios.get(URL_PENDINGS);
    return result.data.data;
};


export async function getPPendingDetails(id) {
    const result = await axios.get(`${URL_PENDINGS}/${id}`);
    return result.data.data;
};

export async function postMakePayment(data) {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    const result = await axios.post(`${URL_MAKE}`, data, config);
    return result.data;
};