import React from 'react';
import * as actionsHotmersList from './../_redux/hotmesrListActions';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import DropdownGenders from '../../../../components/dropdowns/genders/DropdownGenders'
import DropdownDocuments from '../../../../components/dropdowns/documents/DropdownDocuments'
import DropdownCountrys from '../../../../components/dropdowns/countries/DropdownCountrys';
import DropdownRoles from '../../../../components/dropdowns/roles/DropdownRoles';
import { difference } from '../../../../functions/objectFunctions';

const Profile = ({ id }) => {
    const dispatch = useDispatch();
    const { hotmerDetail, actionsLoading } = useSelector(
        state => state.hotmersList,
        shallowEqual
    );
    const initialValues = {
        ds_full_name: hotmerDetail.identity && hotmerDetail.identity.full_name ? hotmerDetail.identity.full_name : '',
        ds_nickname: hotmerDetail.identity && hotmerDetail.identity.ds_nick_name ? hotmerDetail.identity.ds_nick_name : '',
        ds_address: hotmerDetail.identity && hotmerDetail.identity.ds_address ? hotmerDetail.identity.ds_address : '',
        id_country: 0,
        id_gender: 0,
        id_rol: 0,
        ds_phone_number: hotmerDetail.identity && hotmerDetail.identity.ds_phone_number ? hotmerDetail.identity.ds_phone_number : '',
        ds_instagram: hotmerDetail.identity && hotmerDetail.identity.ds_instagram ? hotmerDetail.identity.ds_instagram : '',
        bl_star: hotmerDetail.identity && hotmerDetail.identity.bl_star ? hotmerDetail.identity.bl_star : false,
        bl_certificated: hotmerDetail.identity && hotmerDetail.identity.bl_certificated ? hotmerDetail.identity.bl_certificated : false,
        id_type_of_document: 0,
        ds_document_number: hotmerDetail.identity && hotmerDetail.identity.ds_document ? hotmerDetail.identity.ds_document : '',
        referral_by: hotmerDetail.identity && hotmerDetail.identity.referral_by ? hotmerDetail.identity.referral_by : '',
        vl_score: hotmerDetail.identity && hotmerDetail.identity.vl_score ? parseInt(hotmerDetail.identity.vl_score) : 0,
    };
    const validationSchema = Yup.object({
        ds_full_name: Yup.string()
            .required('Full name user is required'),
        ds_nickname: Yup.string()
            .required('Full name user is required')
            .matches(/^([0-9a-z-_.]{0,18})$/),
        id_country: Yup.number()
            .required('Country is required'),
        id_gender: Yup.number()
            .required('Gender is required'),
        vl_score: Yup.string()
            .required('Score is required'),
    });
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                const result = difference(initialValues, values);
                // console.log(result)
                dispatch(actionsHotmersList.editHotmer(id, { profile: result }));
                setSubmitting(false);
            }}
        >
            {formik =>
                <Form>
                    <div className="p-d-flex p-ai-top p-jc-between">
                        <div className="p-fluid p-formgrid p-grid p-col-12 p-md-6">
                            <div className="p-field p-col-12 p-mb-4">
                                <label htmlFor="ds_full_name">Full name</label>
                                <div className="p-inputgroup">
                                    <InputText id="ds_full_name" value={formik.values.ds_full_name} onChange={e => formik.setFieldValue('ds_full_name', e.currentTarget.value)} />
                                    <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('ds_full_name', initialValues.ds_full_name)} type="button" />
                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('ds_full_name', '')} type="button" />
                                </div>
                                <small className="p-error p-d-block"><ErrorMessage name="ds_full_name" /></small>
                            </div>
                            <div className="p-field p-col-12 p-mb-4">
                                <label htmlFor="ds_nickname">User name</label>
                                <div className="p-inputgroup">
                                    <InputText id="ds_nickname" value={formik.values.ds_nickname} onChange={e => formik.setFieldValue('ds_nickname', e.currentTarget.value)} />
                                    <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('ds_nickname', initialValues.ds_nickname)} type="button" />
                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('ds_nickname', '')} type="button" />
                                </div>
                                <small className="p-error p-d-block"><ErrorMessage name="ds_nickname" /></small>
                            </div>
                            <div className="p-field p-col-12 p-mb-4">
                                <label htmlFor="ds_address">Address</label>
                                <div className="p-inputgroup">
                                    <InputText id="ds_address" value={formik.values.ds_address} onChange={e => formik.setFieldValue('ds_address', e.currentTarget.value)} />
                                    <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('ds_address', initialValues.ds_address)} type="button" />
                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('ds_address', '')} type="button" />
                                </div>
                                <small className="p-error p-d-block"><ErrorMessage name="ds_address" /></small>
                            </div>
                            <div className="p-field p-col-12 p-mb-4">
                                <label htmlFor="id_country">Country</label>
                                <DropdownCountrys
                                    select={hotmerDetail.identity.ds_country}
                                    selected={formik.values.id_country}
                                    setSelected={value => formik.setFieldValue('id_country', value)}
                                />
                                <small className="p-error p-d-block"><ErrorMessage name="id_country" /></small>
                            </div>
                            <div className="p-field p-col-12 p-mb-4">
                                <label htmlFor="id_gender">Gender</label>
                                <DropdownGenders
                                    select={hotmerDetail.identity.ds_type_of_gender_name}
                                    selected={formik.values.id_gender}
                                    setSelected={value => formik.setFieldValue('id_gender', value)}
                                />
                                <small className="p-error p-d-block"><ErrorMessage name="id_gender" /></small>
                            </div>
                            <div className="p-field p-col-12 p-mb-4">
                                <label htmlFor="id_rol">Rol</label>
                                <DropdownRoles
                                    select={hotmerDetail.identity.ds_type_of_role_name}
                                    selected={formik.values.id_rol}
                                    setSelected={value => formik.setFieldValue('id_rol', value)}
                                />
                                <small className="p-error p-d-block"><ErrorMessage name="id_rol" /></small>
                            </div>
                            <div className="p-field p-col-12 p-mb-4">
                                <label htmlFor="ds_instagram">Instagram</label>
                                <div className="p-inputgroup">
                                    <InputText id="ds_instagram" value={formik.values.ds_instagram} onChange={e => formik.setFieldValue('ds_instagram', e.currentTarget.value)} />
                                    <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('ds_instagram', initialValues.ds_instagram)} type="button" />
                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('ds_instagram', '')} type="button" />
                                </div>
                                <small className="p-error p-d-block"><ErrorMessage name="ds_instagram" /></small>
                            </div>
                            <div className="p-d-block p-d-md-none p-d-flex p-flex-column p-field p-col-12 p-mb-4">
                                <label htmlFor="bl_certificated">Certificated</label>
                                <InputSwitch checked={formik.values.bl_certificated} onChange={e => formik.setFieldValue('bl_certificated', !formik.values.bl_certificated)} />
                                <small className="p-error p-d-block"><ErrorMessage name="bl_certificated" /></small>
                            </div>
                            <div className="p-d-block p-d-md-none p-d-flex p-flex-column p-field p-col-12 p-mb-4">
                                <label htmlFor="bl_star">Perfil Destacado</label>
                                <InputSwitch checked={formik.values.bl_star} onChange={e => formik.setFieldValue('bl_star', !formik.values.bl_star)} />
                                <small className="p-error p-d-block"><ErrorMessage name="bl_star" /></small>
                            </div>
                        </div>
                        <div className="p-d-none p-d-md-block p-fluid p-formgrid p-grid p-col-12 p-md-6">
                        <div className="p-field p-d-flex p-flex-column p-mb-5">
                                <label htmlFor="bl_star">Perfil Destacado</label>
                                <InputSwitch checked={formik.values.bl_star} onChange={e => formik.setFieldValue('bl_star', !formik.values.bl_star)} />
                                <small className="p-error p-d-block"><ErrorMessage name="bl_star" /></small>
                            </div>
                            <div className="p-field p-d-flex p-flex-column p-mb-5">
                                <label htmlFor="bl_certificated">Certificated</label>
                                <InputSwitch checked={formik.values.bl_certificated} onChange={e => formik.setFieldValue('bl_certificated', !formik.values.bl_certificated)} />
                                <small className="p-error p-d-block"><ErrorMessage name="bl_certificated" /></small>
                            </div>
                            <div className="p-field p-mb-4 p-pt-1">
                                <label htmlFor="id_type_of_document">Type document</label>
                                <DropdownDocuments
                                    select={hotmerDetail.identity.ds_type_of_document}
                                    selected={formik.values.id_type_of_document}
                                    setSelected={value => formik.setFieldValue('id_type_of_document', value)}
                                />
                                <small className="p-error p-d-block"><ErrorMessage name="id_type_of_document" /></small>
                            </div>
                            <div className="p-field p-mb-4 p-pt-1">
                                <label htmlFor="ds_document">Document number</label>
                                <div className="p-inputgroup">
                                    <InputText id="ds_document" value={formik.values.ds_document} onChange={e => formik.setFieldValue('ds_document', e.currentTarget.value)} />
                                    <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('ds_document', initialValues.ds_document)} type="button" />
                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('ds_document', '')} type="button" />
                                </div>
                                <small className="p-error p-d-block"><ErrorMessage name="ds_document" /></small>
                            </div>

                            <div className="p-field p-mb-4 p-pt-1">
                                <label htmlFor="referral_by">Referral id</label>
                                <div className="p-inputgroup">
                                    <InputText id="referral_by" value={formik.values.referral_by} onChange={e => formik.setFieldValue('referral_by', e.currentTarget.value)} />
                                    <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('referral_by', initialValues.referral_by)} type="button" />
                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('referral_by', '')} type="button" />
                                </div>
                                <small className="p-error p-d-block"><ErrorMessage name="referral_by" /></small>
                            </div>

                            <div className="p-field p-col-12 p-mb-4">
                                <label htmlFor="ds_phone_number">Whats App</label>
                                <div className="p-inputgroup">
                                    <InputText id="ds_phone_number" value={formik.values.ds_phone_number} onChange={e => formik.setFieldValue('ds_phone_number', e.currentTarget.value)} />
                                    <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('ds_phone_number', initialValues.ds_phone_number)} type="button" />
                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('ds_phone_number', '')} type="button" />
                                </div>
                                <small className="p-error p-d-block"><ErrorMessage name="ds_phone_number" /></small>
                            </div>

                            <div className="p-field p-md-5 p-mb-4 p-pt-1">
                                <label htmlFor="vl_score">Score</label>
                                <div className="p-inputgroup">
                                    <InputNumber id="vl_score"
                                        max={10} min={0} value={formik.values.vl_score}
                                        onChange={e => formik.setFieldValue('vl_score', e.value ? e.value : 0)}
                                        showButtons buttonLayout="horizontal"
                                        mode="decimal" minFractionDigits={0} maxFracionDigits={1} step={0.25}
                                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                    />
                                    <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('vl_score', initialValues.vl_score)} type="button" />
                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('vl_score', 0)} type="button" />
                                </div>
                                <small className="p-error p-d-block"><ErrorMessage name="vl_score" /></small>
                            </div>



                        </div>
                    </div>
                    <div className="p-d-flex p-jc-end p-mb-2">
                        <div>
                            <Button icon={`pi ${actionsLoading ? `pi-spin pi-spinner` : `pi-check`}`}
                                label="Save" className="p-button-info p-m-3" type="submit"
                                disabled={!formik.dirty || actionsLoading}
                            />
                        </div>
                    </div>
                </Form>
            }
        </Formik >
    )
};

export default Profile
