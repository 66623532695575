import * as requestFromServer from './rolesCrud'
import { rolesListSlice, callTypes } from './rolesSlice';

const { actions } = rolesListSlice;

export const fetchRolesList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getRolesList(data)
        .then(response => {
            const rolesList = response;
            dispatch(actions.rolesListFetched({ rolesList }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting roles list", callType: callTypes.list }));
        });
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
