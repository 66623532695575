import { createSlice } from '@reduxjs/toolkit';

const initialStatePackStatusList = {
    listLoading: false,
    actionsLoading: false,
    packStatusList: undefined,
    success: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const packsStatusListSlice = createSlice({
    name: "packStatusList",
    initialState: initialStatePackStatusList,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        catchSuccess: (state, action) => {
            state.error = null;
            state.success = action.payload.success;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        startCall: (state, action) => {
            state.error = null;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            };
        },
        // get List country
        packStatusListFetched: (state, action) => {
            state.listLoading = false;
            state.packStatusList = action.payload.packStatusList;
            state.error = null;
        },
        clearAll: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.packStatusList = undefined;
            state.error = null;
            state.success = null;
        }
    }
});