import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { Fieldset } from 'primereact/fieldset';
import { TabMenu } from 'primereact/tabmenu';
import moment from 'moment';

const DialogUserDeatil = ({ user, show, setShow, }) => {

    const closeDialog = () => {
        setShow(false);
    };
    const Hedaer = () => {
        return (
            <div className="p-d-flex p-jc-between p-ai-center p-pt-3 p-pl-3 p-pr-3 p-mb-5" style={{ border: '2px solid #d0d0d0', padding: 5, borderTopRightRadius: 5, borderTopLeftRadius: 5 }}>
                <h2 className="bold">{user.Resume.full_name}</h2>
                <Button onClick={() => closeDialog()} icon="pi pi-times" className="p-button-rounded p-button-secondary p-button-text" />
            </div>
        )
    };
    const renderFooter = () => {
        return (
            <>
                {/* <Button label="No" icon="pi pi-times" onClick={closeDialog} className={`p-button-raised p-button-info p-button-text`} autoFocus /> */}
                <Button
                    label="OK" icon="pi pi-check" className={`p-button-info`} autoFocus
                    onClick={() => {
                        closeDialog();
                    }}
                />
            </>
        );
    }

    const Summary = () => {
        const { Account, Preference } = user.Summary;
        return (
            <>

                <Fieldset className="p-mb-2" legend="Accounts" toggleable>
                    {Account && Array.isArray(Account) ?
                        <div className="p-d-flex p-fluid p-formgrid p-grid">
                            <div className="p-col-4">
                                <div className="p-mb-2">
                                    <span style={{ verticalAlign: 'middle', fontSize: 18, fontWeight: "bold" }}>Email</span>
                                </div>
                            </div>
                            <div className="p-col-8" >
                                <div className="p-mb-2">
                                    <span style={{ verticalAlign: 'middle', fontSize: 18, fontWeight: "bold" }}>Payment Method</span>
                                </div>
                            </div>
                            {Account.map((item) =>
                                <>
                                    <div className="p-col-4 p-mb-2" key={item.ds_email}>
                                        <p>{item.ds_email}</p>
                                    </div>
                                    <div className="p-col-8 p-mb-2" key={item.ds_name}>
                                        <p>{item.ds_name}</p>
                                    </div>
                                </>
                            )}
                        </div>
                        :
                        <h3>No accounts found</h3>
                    }
                </Fieldset>
                <Fieldset legend="Preferences" toggleable>
                    {Preference && Array.isArray(Preference) ?
                        <div className="p-d-flex p-fluid p-formgrid p-grid">
                            {Preference.map((item) =>
                                <div className="p-col" key={item.gender_name}>
                                    <p>{item.gender_name}</p>
                                </div>
                            )}
                        </div>
                        :
                        <h3>No preferences found</h3>
                    }
                </Fieldset>
            </>
        )
    };
    const Statistics = () => {
        const { international, local } = user.Statistic;
        return (
            <>
                <Fieldset className="p-mb-2" legend={`Local (${local.ds_currency_code})`} toggleable>
                    {local ?
                        <div className="p-d-flex p-jc-around p-fluid p-formgrid p-grid">
                        <div className="p-d-flex p-flex-column p-col-12 p-md-5 p-jc-center p-ai-center p-col p-shadow-1 p-m-2 p-p-2">
                            <b className="p-text-center">Quantity of packs purchased </b>
                            <p>{local.vl_count_purchase_pack}</p>
                        </div>
                        <div className="p-d-flex p-flex-column p-col-12 p-md-5 p-jc-center p-ai-center p-col p-shadow-1 p-m-2 p-p-2">
                            <b className="p-text-center">Amount spent on packs </b>
                            <p>{local.ds_symbol}{local.vl_ammount_spent_pack}</p>
                        </div>
                        <div className="p-d-flex p-flex-column p-col-12 p-md-5 p-jc-center p-ai-center p-col p-shadow-1 p-m-2 p-p-2">
                            <b className="p-text-center">Quantity of subscritpions purchased </b>
                            <p>{local.vl_count_purchase_subscription}</p>
                        </div>
                        <div className="p-d-flex p-flex-column p-col-12 p-md-5 p-jc-center p-ai-center p-col p-shadow-1 p-m-2 p-p-2">
                            <b className="p-text-center">Amount spent on subscritpions </b>
                            <p>{local.ds_symbol}{local.vl_ammount_spent_subscription}</p>

                        </div>
                        <div className="p-d-flex p-flex-column p-col-12 p-md-5 p-jc-center p-ai-center p-col p-shadow-1 p-m-2 p-p-2">
                            <b className="p-text-center">Quantity of tips purchased </b>
                            <p>{local.vl_count_purchase_tip}</p>
                        </div>
                        <div className="p-d-flex p-flex-column p-col-12 p-md-5 p-jc-center p-ai-center p-col p-shadow-1 p-m-2 p-p-2">
                            <b className="p-text-center">Amount spent on tips </b>
                            <p>{local.ds_symbol}{local.vl_ammount_spent_tip}</p>
                        </div>
                    </div>
                        :
                        <h3>No statics local found</h3>
                    }
                </Fieldset>

                <Fieldset legend={`Internaciconal (${international.ds_currency_code})`} toggleable>
                    {international ?
                        <div className="p-d-flex p-jc-around p-fluid p-formgrid p-grid">
                            <div className="p-d-flex p-flex-column p-col-12 p-md-5 p-jc-center p-ai-center p-col p-shadow-1 p-m-2 p-p-2">
                                <b className="p-text-center">Quantity of packs purchased </b>
                                <p>{international.vl_count_purchase_pack}</p>
                            </div>
                            <div className="p-d-flex p-flex-column p-col-12 p-md-5 p-jc-center p-ai-center p-col p-shadow-1 p-m-2 p-p-2">
                                <b className="p-text-center">Amount spent on packs </b>
                                <p>{international.ds_symbol} {international.vl_ammount_spent_pack}</p>
                            </div>
                            <div className="p-d-flex p-flex-column p-col-12 p-md-5 p-jc-center p-ai-center p-col p-shadow-1 p-m-2 p-p-2">
                                <b className="p-text-center">Quantity of subscritpions purchased </b>
                                <p>{international.vl_count_purchase_subscription}</p>
                            </div>
                            <div className="p-d-flex p-flex-column p-col-12 p-md-5 p-jc-center p-ai-center p-col p-shadow-1 p-m-2 p-p-2">
                                <b className="p-text-center">Amount spent on subscritpions </b>
                                <p>{international.ds_symbol} {international.vl_ammount_spent_subscription}</p>

                            </div>
                            <div className="p-d-flex p-flex-column p-col-12 p-md-5 p-jc-center p-ai-center p-col p-shadow-1 p-m-2 p-p-2">
                                <b className="p-text-center">Quantity of tips purchased </b>
                                <p>{international.vl_count_purchase_tip}</p>
                            </div>
                            <div className="p-d-flex p-flex-column p-col-12 p-md-5 p-jc-center p-ai-center p-col p-shadow-1 p-m-2 p-p-2">
                                <b className="p-text-center">Amount spent on tips </b>
                                <p>{international.ds_symbol} {international.vl_ammount_spent_tip}</p>
                            </div>
                        </div>
                        :
                        <h3>No international local found</h3>
                    }
                </Fieldset>
            </>
        )
    };
    const items = [
        { label: 'Summary', icon: 'pi pi-info-circle', component: <Summary />, position: 0 },
        { label: 'Statistics', icon: 'pi pi-chart-bar', component: <Statistics />, position: 1 },
    ];
    const [activeItem, setActiveItem] = useState(0);
    //const {ds_country, email, dt_birth, dt_created_at, referral_code, profile_photo_path}=user.Resume;
    return (
        <>
            {user &&
                <Dialog
                    className="p-col-8"
                    closable={false}
                    visible={show} onHide={closeDialog}
                    position="center"
                    draggable={false} resizable={false} baseZIndex={1000}
                    footer={renderFooter}
                >
                    <Hedaer />
                    <div className="p-fluid p-formgrid p-grid" style={{ borderBottom: '1px solid #d0d0d0' }}>
                        <Fieldset legend="Profile" toggleable className="p-d-lg-none p-p-1 p-col-12">
                            <div className="p-d-flex p-flex-column p-ai-center p-mb-2">
                                <Avatar image={user.Resume.profile_photo_path} style={{ width: 60, height: 60 }} shape="circle" />
                            </div>
                            <div className="p-fluid p-formgrid p-grid p-text-center">
                                <span className="p-col p-mb-3"><b>Nationality:</b> {user.Resume.ds_country}</span>
                                <span className="p-col p-mb-3"><b>Mail:</b> {user.Resume.email}</span>
                                <span className="p-col p-mb-3"><b>Birth Date:</b> {moment(user.Resume.dt_birth).format("YYYY/MM/DD")}</span>
                                <span className="p-col p-mb-3"><b>Created Date:</b> {moment(user.Resume.dt_created_at).format("YYYY/MM/DD")}</span>
                                <span className="p-col p-mb-3"><b>Referral Code:</b> {user.Resume.referral_code}</span>
                            </div>

                        </Fieldset>
                        <div className="p-d-none p-d-lg-block p-p-3 p-col-3" style={{ borderRight: '1px solid #d0d0d0' }}>
                            <div className="p-d-flex p-flex-column p-ai-center p-mb-2">
                                <Avatar className="p-mb-5" image={user.Resume.profile_photo_path} style={{ width: 110, height: 110 }} shape="circle" />
                            </div>
                            <b>Nationality:</b>
                            <p>{user.Resume.ds_country}</p>
                            <b>Mail:</b>
                            <p>{user.Resume.email}</p>
                            <b>Bith Date:</b>
                            <p>{moment(user.Resume.dt_birth).format("YYYY/MM/DD")}</p>
                            <b>Created Date:</b>
                            <p>{moment(user.Resume.dt_created_at).format("YYYY/MM/DD")}</p>
                            {/*<b>Referral Code:</b>
                            <p>{user.Resume.referral_code}</p>*/}
                        </div>
                        <div className="p-p-3 p-col-12 p-lg-9">
                            <TabMenu model={items} activeItem={items[activeItem]} onTabChange={e => setActiveItem(e.value.position)} />
                            <div className="p-mt-3">{items[activeItem].component}</div>
                        </div>
                    </div>
                </Dialog>
            }
        </>
    )
}

export default DialogUserDeatil;