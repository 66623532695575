import React, { useState, useEffect, useRef } from 'react'
import * as actionsCoversList from './_redux/pCoversActions';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/components/breadcrumb/BreadCrumb';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import CoversInfo from './editComponents/ContentCovers';
/*import Stats from './editComponents/Stats';
import Identity from './editComponents/Identity';*/

const CoverEdit = (props) => {
    const toast = useRef(null);
    const dispatch = useDispatch();
    const { pCoversDetails, listLoading, actionsLoading, error, success } = useSelector(
        state => state.pCovers,
        shallowEqual
    );

    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    const breadcrumbItems = [
        { label: 'Content' },
        { label: 'Cover list', url: '/content/coverlist' },
        { label: 'Edit' },
    ];

    useEffect(() => {
        if (!pCoversDetails) {
            dispatch(actionsCoversList.fetchCoversDetails(props.match.params.id));
        };
    }, [pCoversDetails, dispatch]);

    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Covers detail', detail: error });
        };
    }, [error]);

    useEffect(() => {
        if (success) {
            toast.current.show({ severity: 'success', summary: 'Covers', detail: success });
        };
    }, [success]);

    useEffect(() => {
        return () => {
            dispatch(actionsCoversList.clear({}));
        };
    }, [dispatch]);

    const leftToolbarTemplate = () => {
        return (
            <>
                <Button label="Reload" icon="pi pi-refresh" className="p-button-warning p-ml-2"
                    onClick={() => {
                        dispatch(actionsCoversList.fetchCoversDetails(props.match.params.id));
                    }}
                    disabled={listLoading || actionsLoading}
                />
            </>
        )
    };

    const items = [
        { label: 'Info', icon: 'pi pi-info-circle', component: <CoversInfo id={props.match.params.id} />, position: 0 }
    ];
    
    const [activeItem, setActiveItem] = useState(0);
    return (
        <div className="card">
            <BreadCrumb className="p-mb-2" home={breadcrumbHome} model={breadcrumbItems} />
            <Toast ref={toast} position="bottom-right" />
            <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>
            <TabMenu model={items} activeItem={items[activeItem]} onTabChange={e => setActiveItem(e.value.position)} />
            {!listLoading ?
                pCoversDetails ?
                    <div className="p-m-3">{items[activeItem].component}</div>
                    :
                    <h3>Covers details not found</h3>
                :
                <div className="p-d-flex p-jc-center">
                    <ProgressSpinner />
                </div>
            }
        </div>
    )
}

export default CoverEdit;