import * as requestFromServer from './hotmesrListCrud'
import { hotmersListSlice, callTypes } from './hotmesrListSlice';

const { actions } = hotmersListSlice;

export const fetchHotmersList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getHotmersList(data)
        .then(response => {
            const hotmersList = response;
            dispatch(actions.hotmersListFetched({ hotmersList }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting hotmer list", callType: callTypes.list }));
        });
};

export const fetchHotmerDetail = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getHotmerDetail(id)
        .then(response => {
            const hotmerDetail = response;
            dispatch(actions.hotmerDetailFetched({ hotmerDetail }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting hotmer detail", callType: callTypes.list }));
        });
};

export const editHotmer = (id, data) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .hotmerEdit(id, data)
        .then(response => {
            if (response && response.status === "Success") {
                dispatch(actions.catchSuccess({ success: "Successful hotmer edit", callType: callTypes.action }));
                fetchHotmerDetail(id);
            } else {
                dispatch(actions.catchError({ error: "Failed to edit hotmer", callType: callTypes.action }));
            };
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Failed to edit hotmer", callType: callTypes.action }));
        });
};

export const deleteHotmer = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .hotmerDelete(id)
        .then(response => {
            if (response && response.status === "Success") {
                dispatch(actions.catchSuccess({ success: "Hotmer deleted", callType: callTypes.action }));
            } else {
                dispatch(actions.catchError({ error: "Failed to delete hotmer", callType: callTypes.action }));
            };
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Failed to delete hotmer", callType: callTypes.action }));
        });
};

export const validateHotmer = (id, data, status) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .hotmerValidate(id, data)
        .then(response => {
            if (response && response.status === "Success") {
                if (status) {
                    dispatch(actions.deleteHotmer({ success: "Validated Hotmer ", id, callType: callTypes.action }));
                } else {
                    dispatch(actions.catchError({ error: "Rejected hotmer", callType: callTypes.action }));
                };
                dispatch(actions.clear({}));
                fetchHotmerDetail(id);
            } else {
                dispatch(actions.catchError({ error: `Failed to ${status ? "validate" : "rejected"} hotmer`, callType: callTypes.action }));
            };
        })
        .catch(() => {
            dispatch(actions.catchError({ error: `Failed to ${status ? "validate" : "rejected"} hotmer`, callType: callTypes.action }));
        });
};

export const clear = id => dispatch => {
    dispatch(actions.clear());
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
