import React from 'react'
import { Redirect } from 'react-router-dom';
import FormLogin from './components/FormLogin';
import { AuthConsumer } from './provider/authProvieder';

const Login = () => {
    return (
        <AuthConsumer>
            {({ auth }) =>
                <>
                    {auth  ?
                        <Redirect from="/login" to="/" />
                        :
                        <FormLogin />
                    }
                </>
            }
        </AuthConsumer>
    )
}

export default Login;