import * as requestFromServer from './statsCrud'
import { statsListSlice, callTypes } from './statsSlice';

const { actions } = statsListSlice;

export const fetchStatsList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getStatsList(data)
        .then(response => {
            const statsList = response;
            dispatch(actions.statsListFetched({ statsList }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting stats list", callType: callTypes.list }));
        });
};

export const clear = id => dispatch => {
    dispatch(actions.clear());
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
