import React, { useEffect, useState, useRef, useContext } from "react";
// import StarRateIcon from '@mui/icons-material/StarRate';
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../../../auth/provider/authProvieder";
import * as actionsHotmersList from "./_redux/hotmesrListActions";
import { Link } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
// import { BreadCrumb } from 'primereact/components/breadcrumb/BreadCrumb';
import { ProgressSpinner } from "primereact/progressspinner";
import { Avatar } from "primereact/avatar";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import DialogCustom from "../../../components/dialogs/DialogCustom";
import DialogHotmerDetails from "../../../components/dialogs/dialogHotmer/DialogHotmerDetails";
import moment from "moment";
import "../../../styles/customers.scss";
import { Tag } from "primereact/tag";
import { status } from "../../../functions/hotmerFunctions";

const HotmerList = () => {
    const [selectedUser, setSelectedUser] = useState("");
    const [deleteUser, setDeleteUser] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [showDialogDetail, setshowDialogDetail] = useState(false);
    const [globalFilter, setGlobalFilter] = useState("");
    const [userStatus, setUserStatus] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const { isHotmerAuthorized } = useContext(AuthContext);
    // const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    // const breadcrumbItems = [
    //     { label: 'Users' },
    //     { label: 'Hotmers list' },
    // ];
    const dispatch = useDispatch();

    const { hotmersList, hotmerDetail, listLoading, actionsLoading, error, success } = useSelector((state) => state.hotmersList, shallowEqual);

    useEffect(() => {
        if (!hotmersList) {
            dispatch(actionsHotmersList.fetchHotmersList({}));
        }
    }, [hotmersList, dispatch]);

    useEffect(() => {
        return () => {
            setSelectedUser("");
            setshowDialogDetail(false);
            dispatch(actionsHotmersList.clearAll({}));
        };
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            toast.current.show({ severity: "error", summary: "Hotmers", detail: error });
        }
    }, [error]);
    useEffect(() => {
        if (success) {
            toast.current.show({ severity: "success", summary: "Hotmers", detail: success });
        }
    }, [success]);

    const leftToolbarTemplate = () => {
        return (
            <>
                <Button
                    label="Reload"
                    icon="pi pi-refresh"
                    className="p-button-warning p-ml-2"
                    onClick={() => {
                        setSelectedUser("");
                        dispatch(actionsHotmersList.fetchHotmersList({}));
                    }}
                    disabled={listLoading || actionsLoading}
                />
            </>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <>
                <Button label="Export" icon="pi pi-upload" className="p-button-help p-ml-2" disabled={listLoading || actionsLoading} /*onClick={exportCSV}*/ onClick={() => dt.current.exportCSV()} />
            </>
        );
    };
    const header = (
        // <div className="table-header">
        //     <h5 className="p-m-0">Manage Hotmers</h5>
        //     <span className="p-input-icon-left">
        //         <i className="pi pi-search" />
        //         <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        //     </span>
        // </div>
        <div className="table-header">
            <h5 className="p-m-0">Hotmer List</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    /* const nameBodyTemplate = (rowData) => {
         // return <Chip label={rowData.ds_full_name} image={rowData.ds_profile_photo_thumbnail_path}  />
         return (
             <div className="p-d-flex p-ai-center">
                 <Avatar image={rowData.ds_profile_photo_thumbnail_path} className="p-mr-2" size="small" shape="circle" />
                 <span className="image-text" >{rowData.ds_full_name}</span>
             </div>
         )
     };
 */
    const whatsAppTemplate = (rowData) => {
        // return <Chip label={rowData.ds_full_name} image={rowData.ds_profile_photo_thumbnail_path}  />
        return (
            <div className="p-d-flex p-ai-center">
                <a href={`https://wa.me/${rowData.ds_phone_number}`} target="_blank" without rel="noreferrer" style={{ color: "white" }}>
                    <Button tooltip={`${rowData.ds_phone_number}`} icon="pi pi-link" className="p-button-rounded  p-button-success p-mr-1" style={{ color: "white" }} />
                </a>
            </div>
        );
    };

    const nickNameTemplate = (rowData) => {
        // return <Chip label={rowData.ds_full_name} image={rowData.ds_profile_photo_thumbnail_path}  />
        return (
            <div className="p-d-flex p-ai-center">
                <Avatar image={rowData.ds_profile_photo_thumbnail_path} className="p-mr-2" size="small" shape="circle" />
                {/* <Link to={`https://hotmy.app/${rowData.ds_display_name}`} Target="_blank"/> */}
                <a href={`https://hotmy.app/${rowData.ds_display_name}`} target="_blank" without rel="noreferrer">
                    {rowData.ds_display_name}{" "}
                </a>
            </div>
        );
    };

    const instagramNameTemplate = (rowData) => {
        // return <Chip label={rowData.ds_full_name} image={rowData.ds_profile_photo_thumbnail_path}  />
        return (
            <div className="p-d-flex p-ai-center">
                <a href={`https://instagram.com/${rowData.ds_instagram}`} target="_blank" without rel="noreferrer" style={{ color: "white" }}>
                    <Button tooltip={`${rowData.ds_instagram}`} icon="pi pi-send" className="p-button-rounded p-mr-1" style={{ color: "white" }} />
                </a>
            </div>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return <span>{rowData.ds_full_name}</span>;
    };

    const bl_coverTemplate = (rowData) => {
        return (
            <span>
                {rowData.bl_have_cover_art} - {rowData.vl_cover_count}
            </span>
        );
    };

    const bl_star = (rowData) => {
        if (rowData.bl_star === "yes") {
            return <StarRoundedIcon style={{ color: "#f5d033" }} />;
        } else {
            return <span>{rowData.bl_star}</span>;
        }
    };

    const idBodyTemplate = (rowData) => {
        return <span>{rowData.id_user}</span>;
    };

    const scoreBodyTemplate = ({ vl_score }) => {
        let backgroundColor = "red";
        switch (true) {
            case vl_score >= 0 && vl_score <= 4:
                backgroundColor = "red";
                break;
            case vl_score > 4 && vl_score <= 7:
                backgroundColor = "orange";
                break;
            case vl_score > 7 && vl_score <= 10:
                backgroundColor = "green";
                break;
            default:
                backgroundColor = "red";
                break;
        }
        //<Rating value={vl_score / 2} readOnly cancel={false} stars={5} />
        return (
            <div className="p-d-flex p-jc-center p-ai-center">
                <b className="p-mr-1 p-text-center" style={{ width: "20%" }}>
                    {vl_score}
                </b>
                <Tag className="p-p-2" style={{ backgroundColor: backgroundColor }} rounded />
            </div>
        );
    };

    const DaysBodyTemplate = (rowData) => {
        let color = "black";
        // switch (true) {
        //     case rowData?.qty_subs_active_days >= 0 && rowData?.qty_subs_active_days <= 3:
        //         color = "green";
        //         break;
        //     case rowData?.qty_subs_active_days >= 4 && rowData?.qty_subs_active_days <= 6:
        //         color = "orange";
        //         break;
        //     case rowData?.qty_subs_active_days >= 7:
        //         color = "red";
        //         break;
        //     default:
        //         color = "black";
        //         break;
        // }

        return (
            <div className="p-d-flex p-jc-center p-ai-center">
                {/* <b className="p-mr-1 p-text-center" > */}
                {rowData.qty_subs_active ? (
                    <b className="p-mr-1 p-text-center" 
                    style={{ color: `${color}` }}>
                        Si-{rowData.qty_subs_active}
                    </b>
                ) : (
                    "No"
                )}
                {/* </b> */}
            </div>
        );
    };


    const DaysBodyTemplateColor = (rowData) => {
        let color = "black";
        switch (true) {
            case rowData?.qty_subs_active_days >= 0 && rowData?.qty_subs_active_days <= 3:
                color = "green";
                break;
            case rowData?.qty_subs_active_days >= 4 && rowData?.qty_subs_active_days <= 6:
                color = "orange";
                break;
            case rowData?.qty_subs_active_days >= 7:
                color = "red";
                break;
            default:
                color = "black";
                break;
        }
        return (
            <div className="p-d-flex p-jc-center p-ai-center">
                    <b className="p-mr-1 p-text-center" style={{ width: "20%", color: `${color}` }}>
                        {rowData.qty_subs_active_days}
                    </b>
            </div>
        );
    }

    const countryBodyTemplate = (rowData) => {
        return (
            <>
                <span className={`flag flag-${rowData.ds_flag_code.toLowerCase()}`} style={{ width: 30, height: 20 }} />
                <span style={{ marginLeft: ".5em", verticalAlign: "middle" }}>{rowData.ds_country}</span>
            </>
        );
    };

    const mailBodyTemplate = (rowData) => {
        return <span>{rowData.ds_mail}</span>;
    };

    // const dateBodyTemplate = (rowData) => {
    //     return <span>{moment(rowData.dt_created_at).format("DD/MM/YYYY")}</span>
    // };
    const datelastPostTemplate = (rowData) => {
        return <span>{moment(rowData.dt_last_post_content).format("DD/MM/YYYY")}</span>;
    };
    const dateLastPackTemplate = (rowData) => {
        return <span>{moment(rowData.dt_last_post_pack).format("DD/MM/YYYY")}</span>;
    };

    const statusBodyTemplate = (rowData) => {
        return <span>{status(rowData.id_status_hotmer)}</span>;
    };

    const actionBodyTemplate = (rowdata) => {
        return (
            <>
                {isHotmerAuthorized(1) && (
                    <Button
                        tooltip="Details"
                        icon="pi pi-eye"
                        className="p-button-rounded p-button-info p-mr-1"
                        onClick={() => {
                            dispatch(actionsHotmersList.fetchHotmerDetail(rowdata.id_user));
                            setshowDialogDetail(true);
                        }}
                    />
                )}
                {isHotmerAuthorized(2) && (
                    <Link to={`/users/hotmersList/edit/${rowdata.id_user}`}>
                        <Button tooltip="Edit" icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-1" />
                    </Link>
                )}
                {/*{isHotmerAuthorized(5) &&
                    <Button tooltip="Reset Pass" icon="pi pi-replay" className="p-button-rounded p-button-warning p-mr-1" style={{ color: 'white' }}
                        onClick={() => {
                            setSelectedUser(rowdata);
                            setResetPassword(true);
                        }}
                    />
                }
                {isHotmerAuthorized(3) &&
                    <Button tooltip="Delete" icon="pi pi-trash" className="p-button-rounded p-button-danger"
                        onClick={() => {
                            setSelectedUser(rowdata);
                            setDeleteUser(true);
                        }}
                    />
                }*/}
            </>
        );
    };

    //FILTERS CUSTOMS

    const userStatuses = [
        { label: "All", value: null },
        { label: "PENDING", value: 0 },
        { label: "QUALIFIED", value: 3 },
        { label: "UNQUALIFIED", value: 4 },
    ];
    const onStatusChange = (event) => {
        dt.current.filter(event.value, "id_status_hotmer", "equals");
        setUserStatus(event.value);
    };
    const statusItemTemplate = ({ label }) => {
        return <span className={`customer-badge status-${label.toLowerCase()}`}>{label}</span>;
    };
    const statusFilterCustom = <Dropdown className="ui-column-filter" style={{ width: "80%" }} value={userStatus} options={userStatuses} onChange={onStatusChange} itemTemplate={statusItemTemplate} />;

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12 card">
                <Toast ref={toast} position="bottom-right" />
                {/* <BreadCrumb className="p-mb-2" home={breadcrumbHome} model={breadcrumbItems} /> */}
                {listLoading && !hotmersList ? (
                    <div className="p-d-flex p-jc-center">
                        <ProgressSpinner />
                    </div>
                ) : (
                    <>
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                        <DataTable
                            ref={dt}
                            value={hotmersList}
                            selection={selectedUser}
                            onSelectionChange={(e) => setSelectedUser(e.value)}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 25, 50, 75, 100, 200]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                            loading={listLoading || actionsLoading}
                            globalFilter={globalFilter}
                            emptyMessage="Not users found."
                            header={header}
                        >
                            <Column headerStyle={{ width: "4%" }} className="p-text-center" field="id_user" header="Id" sortable body={idBodyTemplate} filter filterPlaceholder="id" />
                            <Column headerStyle={{ width: "10%" }} className="p-text-center" field="ds_display_name" header="Nick" sortable body={nickNameTemplate} filter filterPlaceholder="Nick" />
                            <Column headerStyle={{ width: "4%" }} className="p-text-center" field="ds_instagram" header="Insta" sortable body={instagramNameTemplate} filter filterPlaceholder="Insta" />
                            <Column headerStyle={{ width: "4%" }} className="p-text-center" field="ds_phone_number" header="wa" sortable body={whatsAppTemplate} filter filterPlaceholder="wa" />
                            <Column headerStyle={{ width: "10%" }} className="p-text-center" field="ds_full_name" header="Name" sortable body={nameBodyTemplate} filter filterPlaceholder="Name" />
                            <Column headerStyle={{ width: "5%" }} className="p-text-center" field="bl_have_cover_art" header="Cover" sortable body={bl_coverTemplate} filter filterPlaceholder="Cover" />
                            <Column headerStyle={{ width: "4%" }} className="p-text-center" field="bl_star" header="Star" sortable body={bl_star} filter filterPlaceholder="Star" />
                            <Column headerStyle={{ width: "5%" }} className="p-text-center" field="vl_score" header="Score" sortable body={scoreBodyTemplate} filter filterType="number" filterPlaceholder="Score" />
                            <Column headerStyle={{ width: "10%" }} className="p-text-center" field="ds_country" header="Country" sortable body={countryBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Country" />
                            <Column headerStyle={{ width: "18%" }} field="ds_mail" header="Mail" sortable body={mailBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Mail" />
                            <Column headerStyle={{ width: "7%" }} className="p-text-center" field="qty_subs_active" header="SubsActive" sortable body={DaysBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="qty" />
                            <Column headerStyle={{ width: "7%" }} className="p-text-center" field="qty_subs_active_days" header="DiffDays" sortable body={DaysBodyTemplateColor} filter filterMatchMode="contains" filterPlaceholder="Days" />
                            {/* <Column headerStyle={{ width: '7%' }} className="p-text-center" field="dt_created_at" header="Created" sortable body={dateBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Date" /> */}
                            <Column headerStyle={{ width: "7%" }} className="p-text-center" field="dt_last_post_content" header="LastPost" sortable body={datelastPostTemplate} filter filterMatchMode="contains" filterPlaceholder="Date" />
                            <Column headerStyle={{ width: "7%" }} className="p-text-center" field="dt_last_post_pack" header="LastPack" sortable body={dateLastPackTemplate} filter filterMatchMode="contains" filterPlaceholder="Date" />
                            <Column headerStyle={{ width: "10%" }} className="p-text-center" field="id_status_hotmer" header="Status" sortable body={statusBodyTemplate} filter filterElement={statusFilterCustom} />
                            <Column headerStyle={{ width: "6%" }} className="p-text-center" header="Actions" sortable body={actionBodyTemplate} />
                        </DataTable>
                    </>
                )}
            </div>
            <DialogCustom show={resetPassword} setShow={setResetPassword} type="warning" icon="pi-info-circle" position="right" header="Reset Password" description={`Do you want to reset the password of ${selectedUser.ds_full_name}?`} functionOk={() => alert("Add reset password")} />
            <DialogCustom
                show={deleteUser}
                setShow={setDeleteUser}
                type="danger"
                icon="pi-exclamation-triangle"
                position="right"
                header="Delete User"
                description={`You want to delete the user ${selectedUser.ds_full_name}?`}
                functionOk={() => dispatch(actionsHotmersList.deleteHotmer(selectedUser.id_user))}
            />
            <DialogHotmerDetails
                //user={hotmerDetail}
                show={!listLoading && hotmerDetail && showDialogDetail}
                setShow={setshowDialogDetail}
            />
        </div>
    );
};

export default HotmerList;
