import React from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actionsAuth from '../_redux/authActions';
import { Card } from 'primereact/card';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const Hedaer = () => {
    return (
        <div className="p-d-flex p-jc-center">
            <img src="/assets/images/Fuego-Blanco-1.png" alt="Hotmy" style={{ maxHeight: 60, width: "auto" }}></img>
            <h2 className="p-mb-2">Hotmy</h2>
        </div>
    )
};
//admin@hotmy.app
//test1234
const FormLogin = () => {
    const dispatch = useDispatch();
    const { listLoading } = useSelector(
        state => state.auth,
        shallowEqual
    );
    const initialValuesLogin = {
        email: '',
        password: ''
    };
    const validationSchemaLogin = Yup.object({
        email: Yup.string()
            .email('You must enter a valid email')
            .required('Email account is required'),
        password: Yup.string()
            .required('Password is required'),
    });


    return (
        <div className="p-d-flex p-jc-center" style={{ backgroundColor: '#1f0021d6', minHeight: '100vh' }}>
            <Card header={Hedaer} className="p-as-center p-col-8 p-md-4 p-lg-3 p-xl-3 p-shadow-16">
                <br />
                <br />
                <Formik
                    initialValues={initialValuesLogin}
                    validationSchema={validationSchemaLogin}
                    onSubmit={(values, { setSubmitting }) => {
                        dispatch(actionsAuth.fetchLogin(values));
                        setSubmitting(false);
                    }}
                >
                    {formik =>
                        <Form>
                            <div className="p-fluid p-grid p-jc-center">
                                <div className="p-field p-col-10 p-md-8">
                                    <span className="p-float-label  p-input-icon-left">
                                        <i className="pi pi-user" />
                                        <InputText id="email" value={formik.values.email} onChange={e => formik.setFieldValue('email', e.currentTarget.value)} />
                                        <label htmlFor="email">Email</label>

                                    </span>
                                    <small className="p-error p-d-block"><ErrorMessage name="email" /></small>
                                </div>
                                <div className="p-field p-col-10 p-md-8">
                                    <span className="p-float-label  p-input-icon-left">
                                        <i className="pi pi-lock" />
                                        <InputText id="password" type="password" value={formik.values.password} onChange={e => formik.setFieldValue('password', e.currentTarget.value)} />
                                        <label htmlFor="password">Password</label>
                                    </span>
                                    <small className="p-error p-d-block"><ErrorMessage name="password" /></small>
                                </div>
                            </div>
                            <br />
                            <div className="p-d-flex p-jc-center">
                                <Button className="p-button-secondary p-button-rounded p-pl-5 p-pr-5" type="submit" label="Login" icon={`pi ${listLoading ? `pi-spin pi-spinner` : `pi-check`}`} />
                            </div>
                        </Form>
                    }
                </Formik>
            </Card>
        </div>
    )
}

export default FormLogin;