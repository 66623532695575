import axios from 'axios';
import { URL_USERS_LIST } from './config';

export async function getUsersList() {
    const result = await axios.get(URL_USERS_LIST);
    return result.data.data;
};

export async function getUserDetail(id) {
    const result = await axios.get(`${URL_USERS_LIST}/${id}`);
    return result.data.data;
};

export async function userEdit(values) {
    const result = await axios.put(`${URL_USERS_LIST}/${values.id}`,values);
    return result.data.data;
};

export async function userDelete(id) {
    const result = await axios.delete(`${URL_USERS_LIST}/${id}`);
    return result.data;
};