import React, { useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import * as actionsUsersList from './_redux/usersListActions';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { BreadCrumb } from 'primereact/components/breadcrumb/BreadCrumb';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import moment from 'moment';

const UserEdit = () => {
    const today = new Date();
    const { id } = useParams();
    const toast = useRef(null);
    const dispatch = useDispatch();
    const { userDetail, listLoading, error,/*actionsLoading, success*/ } = useSelector(
        state => state.usersList,
        shallowEqual
    );
    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    const breadcrumbItems = [
        { label: 'Users' },
        { label: 'Users list', url: '/users/usersList/' },
        { label: 'Edit' },
    ];
    const initialValues = {
        profile_photo_path: userDetail && userDetail.profile_photo_path ? userDetail.profile_photo_path : '',
        full_name: userDetail && userDetail.full_name ? userDetail.full_name : '',
        referral_code: userDetail && userDetail.referral_code ? userDetail.referral_code : '',
        email: userDetail && userDetail.email ? userDetail.email : '',
        ds_country: userDetail && userDetail.ds_country ? userDetail.ds_country : '',
        dt_birth: userDetail && userDetail.dt_birth ? userDetail.dt_birth : '',
        created_at: userDetail && userDetail.created_at ? userDetail.created_at : '',
    };
    const validationSchema = Yup.object({
        profile_photo_path: Yup.string()
            .required('Profile photo user is required'),
        full_name: Yup.string()
            .required('Full name user is required'),
        referral_code: Yup.string()
            .required('Reference code is required'),
        email: Yup.string()
            .email('You must enter a valid email')
            .required('Email is required'),
        dt_birth: Yup.string()
            .required('Date of Birth is required'),
        created_at: Yup.string()
            .required('Date Created is required'),
        ds_country: Yup.string()
            .required('Country is required'),
    });
    useEffect(() => {
        if (id) {
            dispatch(actionsUsersList.fetchUsersDetail(id));
        };
    }, [id, dispatch]);
    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'User', detail: error });
        };
    }, [error]);
    useEffect(() => {
        return () => {
            dispatch(actionsUsersList.clear({}));
        };
    }, [dispatch]);

    return (
        <div className="p-grid ">
            <div className="p-col-12" >
                <div className="card">
                    <Toast ref={toast} position="bottom-right" />
                    <BreadCrumb className="p-mb-5" home={breadcrumbHome} model={breadcrumbItems} />
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            console.log(values);
                            // dispatch(actionsUsersList.editUser(values));
                            setSubmitting(false);
                        }}
                    >
                        {formik =>
                            <Form>
                                {listLoading ?
                                    <div className="p-d-flex p-jc-center">
                                        <ProgressSpinner />
                                    </div>
                                    :
                                    userDetail ?
                                        <>
                                            <div className="p-fluid p-formgrid p-grid">
                                                <div className="p-d-flex p-jc-center p-col-12 p-mb-5">
                                                    <img src={formik.values.profile_photo_path} alt="Profile" style={{ maxHeight: 150, width: "auto" }} />
                                                    <div className="p-as-center p-ml-2">
                                                        <div className="p-d-flex p-ai-center">
                                                            <FileUpload
                                                                name="Profile" mode="basic" url="./upload" accept="image/*"
                                                                onSelect={e => formik.setFieldValue('profile_photo_path', e.files[0].objectURL)}
                                                                emptyTemplate={<p className="p-m-0">Drag and drop files to here to upload.</p>}
                                                            />
                                                            <Button icon="pi pi-refresh" style={{ color: 'white' }} type="button"
                                                                className="p-button-warning"
                                                                onClick={() => formik.setFieldValue('profile_photo_path', initialValues.profile_photo_path)}
                                                            />
                                                        </div>
                                                        <small className="p-error p-d-block"><ErrorMessage name="profile_photo_path" /></small>
                                                    </div>
                                                </div>
                                                <div className="p-field p-col-12 p-md-6 p-mb-4">
                                                    <label htmlFor="full_name">User</label>
                                                    <div className="p-inputgroup">
                                                        <Button icon="pi pi-user" className="p-button-info" type="button" />
                                                        <InputText id="full_name" value={formik.values.full_name} onChange={e => formik.setFieldValue('full_name', e.currentTarget.value)} />
                                                        <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('full_name', initialValues.full_name)} type="button" />
                                                        <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('full_name', '')} type="button" />
                                                    </div>
                                                    <small className="p-error p-d-block"><ErrorMessage name="full_name" /></small>
                                                </div>
                                                <div className="p-field p-col-12 p-md-6 p-mb-4">
                                                    <label htmlFor="flag">Reference Code</label>
                                                    <div className="p-inputgroup">
                                                        <Button icon="pi pi-map" className="p-button-info" type="button" />
                                                        <InputText id="referral_code" value={formik.values.referral_code} onChange={e => formik.setFieldValue('referral_code', e.currentTarget.value)} />
                                                        <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('referral_code', initialValues.referral_code)} type="button" />
                                                        <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('referral_code', '')} type="button" />
                                                    </div>
                                                    <small className="p-error p-d-block"><ErrorMessage name="referral_code" /></small>
                                                </div>
                                                <div className="p-field p-col-12 p-md-6 p-mb-4">
                                                    <label htmlFor="email">Email</label>
                                                    <div className="p-inputgroup">
                                                        <Button icon="pi pi-envelope" className="p-button-info" type="button" />
                                                        <InputText id="email" value={formik.values.email} onChange={e => formik.setFieldValue('email', e.currentTarget.value)} />
                                                        <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('email', initialValues.email)} type="button" />
                                                        <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('email', '')} type="button" />
                                                    </div>
                                                    <small className="p-error p-d-block"><ErrorMessage name="email" /></small>
                                                </div>
                                                <div className="p-field p-col-12 p-md-6 p-mb-4">
                                                    <label htmlFor="flag">Country</label>
                                                    <div className="p-inputgroup">
                                                        <Button icon="pi pi-map" className="p-button-info" />
                                                        <InputText id="ds_country" value={formik.values.ds_country} onChange={e => formik.setFieldValue('ds_country', e.currentTarget.value)} />
                                                        <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('ds_country', initialValues.ds_country)} type="button" />
                                                        <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('ds_country', '')} type="button" />
                                                    </div>
                                                    <small className="p-error p-d-block"><ErrorMessage name="ds_country" /></small>
                                                </div>
                                                <div className="p-field p-col-12 p-md-6 p-mb-4">
                                                    <label htmlFor="dt_birth">Birth Date</label>
                                                    <div className="p-inputgroup">
                                                        <Button icon="pi pi-calendar" className="p-button-info" type="button" />
                                                        <Calendar id="dt_birth" monthNavigator yearNavigator readOnlyInput
                                                            yearRange={`1980:${today.getFullYear() - 18}`}
                                                            value={new Date(formik.values.dt_birth)}
                                                            onChange={e => formik.setFieldValue('dt_birth', moment(e.value).format("YYYY/MM/DD"))}
                                                        />
                                                        <Button
                                                            icon="pi pi-refresh" style={{ color: 'white' }}
                                                            className="p-button-warning p-t"
                                                            onClick={() => formik.setFieldValue('dt_birth', initialValues.dt_birth)} type="button"
                                                        />
                                                    </div>
                                                    <small className="p-error p-d-block"><ErrorMessage name="dt_birth" /></small>
                                                </div>
                                                <div className="p-field p-col-12 p-md-6 p-mb-4">
                                                    <label htmlFor="created_at">Created</label>
                                                    <div className="p-inputgroup">
                                                        <Button icon="pi pi-calendar" className="p-button-info" type="button" />
                                                        <Calendar id="created_at" monthNavigator yearNavigator readOnlyInput
                                                            yearRange={`2021:${today.getFullYear()}`}
                                                            value={new Date(formik.values.created_at)}
                                                            onChange={e => formik.setFieldValue('created_at', moment(e.value).format("YYYY/MM/DD"))}
                                                        />
                                                        <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" type="button"
                                                            onClick={() => formik.setFieldValue('created_at', userDetail.created_at)}
                                                        />
                                                    </div>
                                                    <small className="p-error p-d-block"><ErrorMessage name="created_at" /></small>
                                                </div>
                                            </div>
                                            <div className="p-d-flex p-jc-center p-col-12 p-mb-5">
                                                <Button icon="pi pi-check" label="Acept" className="p-button-info p-m-3" type="submit" />
                                                <Button icon="pi pi-times" label="Cancel" className="p-button-danger p-m-3" type="button" />
                                            </div>
                                        </>
                                        :
                                        <h1>User Not found</h1>
                                }
                            </Form>
                        }
                    </Formik>
                </div>
            </div>
        </div >
    )
}

export default UserEdit;