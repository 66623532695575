import * as requestFromServer from './commercialListCrud'
import { pCommercialListSlice, callTypes } from './commercialListSlice';

const { actions } = pCommercialListSlice;

export const fetchCommercialList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getCommercialList(data)
        .then(response => {
            const pAgreementCommercial = response;
            dispatch(actions.commercialListFetched({ pAgreementCommercial }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting Commercial list", callType: callTypes.list }));
        });
};
