import { createSlice } from '@reduxjs/toolkit';

const initialStateDocuments = {
    listLoading: false,
    actionsLoading: false,
    documentsList: undefined,
    success: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const documentsListSlice = createSlice({
    name: "documentsList",
    initialState: initialStateDocuments,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        catchSuccess: (state, action) => {
            state.error = null;
            state.success = action.payload.success;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        startCall: (state, action) => {
            state.error = null;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            };
        },
        // get List Documents
        documentsListFetched: (state, action) => {
            state.listLoading = false;
            state.documentsList = action.payload.documentsList;
            state.error = null;
        },
        clearAll: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.documentsList = undefined;
            state.error = null;
            state.success = null;
        }
    }
});