import * as requestFromServer from './pPaymentsCrud'
import { pPaymentsSlice, callTypes } from './pPaymentsSlice';

const { actions } = pPaymentsSlice;
//fetchpaymentList

export const fetchPaymentList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getPPaymentsList(data)
        .then(response => {
            const pPayments = response;
            dispatch(actions.pPaymentFetched({ pPayments }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting pending list", callType: callTypes.list }));
        });
};

export const fetchPaymentsDetails = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getPPaymentsDetails(id)
        .then(response => {
            const pPaymentsDetails = response;
            dispatch(actions.pPendingDetailsFetched({ pPaymentsDetails }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting pending detail", callType: callTypes.list }));
        });
};

export const clear = id => dispatch => {
    dispatch(actions.clear());
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
