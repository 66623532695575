import React from 'react';
import '../styles/customers.scss'
export const status = (id) => {
    switch (id) {
        case 0:
            return <span className="customer-badge status-negotiation">Pending</span>
        case 3:
            return <span className="customer-badge status-qualified">Approved</span>
        case 4:
            return <span className="customer-badge status-unqualified">Rejected</span>
        default:
            return <span className="customer-badge status-unqualified">Rejected</span>
    };
};