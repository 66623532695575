import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actionsPMade from './_redux/pMadeActions';
import { BreadCrumb } from 'primereact/components/breadcrumb/BreadCrumb';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import DialogPPendingDetail from '../../../components/dialogs/DialogPPendingDetail';
import { commonTemplate, dateTemplate, moneyTemplateGreen, moneyTemplate } from '../../../components/dialogs/dataTableTemplates';
import { filterOptions } from '../../../functions/arrayFunctions'

const PaymentsMade = () => {
    const [selectedUser, setSelectedUser] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedCurrenDestination, setselectedCurrenDestination] = useState(null);
    const [selectedCurrentOrigen, setSelectedCurrentOrigen] = useState(null);
    const [orderFilter, setorderFilter] = useState('');
    const [receiptFilter, setreceiptFilter] = useState('');
    const [receiptCodefilter, setReceiptCodefilter] = useState('');
    const [namefilter, setNamefilter] = useState('');
    const [accountMailfilter, setAccountMailfilter] = useState('');
    const [nickNamefilter, setNickNamefilter] = useState('');
    const [insertDateFilter, setInsertDateFilter] = useState('');
    const [withdrawfilter, setWithdrawfilter] = useState('');
    const [vlamountfilter, setvlamountfilter] = useState('');
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [showDialogDetail, setshowDialogDetail] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    const breadcrumbItems = [
        { label: 'Financial' },
        { label: 'Payments Made' },
    ];
    const dispatch = useDispatch();
    const { pMade, pMadeDetails, listLoading, actionsLoading, error, success } = useSelector(
        state => state.pMade,
        shallowEqual
    );

    useEffect(() => {
        if (!pMade) {
            dispatch(actionsPMade.fetchPMadeList({}));
        };
    }, [pMade, dispatch]);

    //TOAST DETAIL NOT FOUND
    useEffect(() => {
        if (!listLoading && !pMadeDetails && showDialogDetail) {
            toast.current.show({ severity: 'warn', summary: 'Payments', detail: 'Payment detaill not found' });
        }
    }, [listLoading, pMadeDetails, showDialogDetail]);

    //ERROR TOAST
    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Payments', detail: error });
        };
    }, [error]);

    //SUCCESS TOAST
    useEffect(() => {
        if (success) {
            toast.current.show({ severity: 'success', summary: 'Payments', detail: success });
        };
    }, [success]);

    useEffect(() => {
        return () => {
            setSelectedUser('');
            setshowDialogDetail(false);
            dispatch(actionsPMade.clearAll({}));
        }
    }, [dispatch]);

    const leftToolbarTemplate = () => {
        return (
            <>
                <Button label="Reload" icon="pi pi-refresh" className="p-button-warning p-ml-2"
                    onClick={() => {
                        dispatch(actionsPMade.fetchPMadeList({}));
                    }}
                    disabled={listLoading || actionsLoading}
                />
            </>
        )
    };
    const rightToolbarTemplate = () => {
        return (
            <>
                <Button label="Export" icon="pi pi-upload" className="p-button-help p-ml-2"
                    disabled={listLoading || actionsLoading} /*onClick={exportCSV}*/ 
                    onClick={()=>dt.current.exportCSV()}
                    />
            </>
        )
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Payments Made by Hotmy to Users</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const cDestinationTemplate = (rowData) => {
        return (
            <Button
                tooltip={`Conversion ${rowData.bl_conversion ? "aproved" : "rejected"}`}
                label={rowData.ds_currency_destination}
                //icon={`pi ${rowData.bl_conversion ? "pi-check" : "pi-times"}`}
                className={`p-button-sm p-button-${rowData.bl_conversion ? "success" : "secondary"}`}
            />
        )
    };

    //FILTERS
    const orderFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'id_extraction_order', 'startsWith');
        setorderFilter(e.currentTarget.value);
    };
    const orderFilterCustom =
        <InputText
            value={orderFilter}
            placeholder="Nº"
            onChange={orderFilterChange}
            style={{ width: "100%" }}
        />;

    const id_receiptFilterChange = (e) => {
            dt.current.filter(e.currentTarget.value, 'id_receipt', 'startsWith');
            setreceiptFilter(e.currentTarget.value);
    };
    const idreceiptFilterCustom =
            <InputText
                value={receiptFilter}
                placeholder="Nº"
                onChange={id_receiptFilterChange}
                style={{ width: "100%" }}
            />;

        

    const withdrawFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'vl_withdraw', 'startsWith');
        setWithdrawfilter(e.currentTarget.value);
    };
    const withdrawFilterCustom =
        <InputText
            value={withdrawfilter}
            placeholder="0.00"
            onChange={withdrawFilterChange}
            style={{ width: "100%" }}
        />;

        const vlamountFilterChange = (e) => {
            dt.current.filter(e.currentTarget.value, 'vl_amount', 'startsWith');
            setvlamountfilter(e.currentTarget.value);
        };
        const vlamountFilterCustom =
            <InputText
                value={vlamountfilter}
                placeholder="0.00"
                onChange={vlamountFilterChange}
                style={{ width: "100%" }}
            />;



        const nameFilterChange = (e) => {
            dt.current.filter(e.currentTarget.value, 'full_name', 'startsWith');
            setNamefilter(e.currentTarget.value);
        };
        const nameFilterCustom =
        <InputText
            value={namefilter}
            placeholder="Name"
            onChange={nameFilterChange}
            style={{ width: "100%" }}
        />;

        const nickNameFilterChange = (e) => {
            dt.current.filter(e.currentTarget.value, 'ds_nick_name', 'startsWith');
            setNickNamefilter(e.currentTarget.value);
        };
        const nikeNameFilterCustom =
            <InputText
                value={nickNamefilter}
                placeholder="Nick"
                onChange={nickNameFilterChange}
                style={{ width: "100%" }}
            />;


            const ReceiptCodeFilterChange = (e) => {
                dt.current.filter(e.currentTarget.value, 'ds_receipt_code', 'startsWith');
                setReceiptCodefilter(e.currentTarget.value);
            };
            const receiptCodeFilterCustom =
                <InputText
                    value={receiptCodefilter}
                    placeholder="Receipt"
                    onChange={ReceiptCodeFilterChange}
                    style={{ width: "100%" }}
                />;       


            const mailAccountFilterChange = (e) => {
                dt.current.filter(e.currentTarget.value, 'ds_email_account', 'startsWith');
                setAccountMailfilter(e.currentTarget.value);
            };
            const accountMailFilterCustom =
                <InputText
                    value={accountMailfilter}
                    placeholder="Account Mail / User Mail"
                    onChange={mailAccountFilterChange}
                    style={{ width: "100%" }}
                />;       

                    const insertDateFilterChange = (e) => {
                        dt.current.filter(e.currentTarget.value, 'dt_insert', 'contains');
                        setInsertDateFilter(e.currentTarget.value);
                    };
                    const insertDateFilterCustom =
                        <InputText
                            value={insertDateFilter}
                            placeholder="Date"
                            onChange={insertDateFilterChange}
                            style={{ width: "100%" }}
                        />;


    const cDestinationFilterChange = ({ value }) => {
        dt.current.filter(value, 'ds_currency_destination', 'in');
        setselectedCurrenDestination(value)
    };
    const cDestinationFilter =
        <MultiSelect style={{ width: "100%" }} className="p-column-filter" value={selectedCurrenDestination} options={filterOptions(pMade, "ds_currency_destination")}
            onChange={cDestinationFilterChange} placeholder="All"
        />;

    const cOrigenFilterChange = ({ value }) => {
        dt.current.filter(value, 'ds_currency_origen', 'in');
        setSelectedCurrentOrigen(value)
    };
    const cOrigenFilter =
        <MultiSelect style={{ width: "100%" }} className="p-column-filter" value={selectedCurrentOrigen} options={filterOptions(pMade, "ds_currency_origen")}
            onChange={cOrigenFilterChange} placeholder="All"
        />;

    const methodFilterChange = ({ value }) => {
        dt.current.filter(value, 'ds_type_of_payment_method', 'in');
        setSelectedMethod(value)
    };
    const methodFilter =
        <MultiSelect style={{ width: "100%" }} className="p-column-filter" value={selectedMethod} options={filterOptions(pMade, "ds_type_of_payment_method")}
            onChange={methodFilterChange} placeholder="All"
        />;
    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12 card">
                <Toast ref={toast} position="bottom-right" onClose={() => setshowDialogDetail(false)} />
                <BreadCrumb className="p-mb-2" home={breadcrumbHome} model={breadcrumbItems} />
                {listLoading && !pMade ?
                    <div className="p-d-flex p-jc-center">
                        <ProgressSpinner />
                    </div>
                    :
                    <>
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
                        <DataTable ref={dt}
                            value={pMade}
                            selection={selectedUser} onSelectionChange={(e) => setSelectedUser(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                            className="p-datatable-customers p-datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} payments pendings"
                            loading={listLoading || actionsLoading}
                            globalFilter={globalFilter} emptyMessage="Not payments pendings found." header={header}>
                            <Column headerStyle={{ width: '5%' }} className="p-text-center" field="id_extraction_order" header="Order" sortable body={e => commonTemplate(e.id_extraction_order)} filter filterElement={orderFilterCustom} />
                            <Column headerStyle={{ width: '5%' }} className="p-text-center" field="id_hotmer" header="id_hotmer" sortable body={e => commonTemplate(e.id_user_hotmer)} filter filterElement={nameFilterCustom} />
                            <Column className="p-text-center" field="ds_nick_name" header="nick" sortable body={e => commonTemplate(e.ds_nick_name)} filter filterElement={nikeNameFilterCustom} />
                            <Column headerStyle={{ width: '7%' }} className="p-text-center" field="vl_withdraw" header="Withdraw" sortable body={e => moneyTemplateGreen(e.vl_withdraw)} filter filterElement={withdrawFilterCustom} />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="ds_currency_destination" header="C. Destination" sortable body={cDestinationTemplate} filter filterElement={cDestinationFilter} />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="ds_currency_origen" header="C. Origen" sortable body={e => commonTemplate(e.ds_currency_origen)} filter filterElement={cOrigenFilter} />
                            <Column headerStyle={{ width: '7%' }} className="p-text-center" field="ds_type_of_payment_method" header="Method" sortable body={e => commonTemplate(e.ds_type_of_payment_method)} filter filterElement={methodFilter} />
                            <Column headerStyle={{ width: '25%' }} className="p-text-center" field="Mail de Cuenta" header="Account Mail" sortable body={e => commonTemplate(e.ds_email_account)} filter filterElement={accountMailFilterCustom} />
                            <Column headerStyle={{ width: '7%' }} className="p-text-center" field="vl_amount" header="Withdraw" sortable body={e => moneyTemplate(e.vl_amount)} filter filterElement={vlamountFilterCustom} />
                            {/*  <Column className="p-text-center" field="Mail de Usuario" header="User Mail" sortable body={e => commonTemplate(e.ds_email_user_app)} filter filterElement={userMailFilterCustom} />*/}
                            <Column headerStyle={{ width: '8%' }} className="p-text-center" field="dt_insert" header="Insert" sortable body={e => dateTemplate(e.dt_insert)} filter filterElement={insertDateFilterCustom} />
                            <Column headerStyle={{ width: '4%' }} className="p-text-center" field="id_receipt" header="id Receipt" sortable body={e => commonTemplate(e.id_receipt)} filter filterElement={idreceiptFilterCustom} />
                            <Column headerStyle={{ width: '8%' }} className="p-text-center" field="Receipt Number" header="Receipt Number" sortable body={e => commonTemplate(e.ds_receipt_code)} filter filterElement={receiptCodeFilterCustom} />
                            {/*<Column headerStyle={{ width: '5%' }} field="action" header="Actions" sortable body={actionBodyTemplate} />/>*/}
                        </DataTable>
                    </>
                }
            </div>
            <DialogPPendingDetail
                payment={pMadeDetails}
                show={!listLoading && pMadeDetails && showDialogDetail}
                setShow={setshowDialogDetail}
            />
        </div>
    )
}

export default PaymentsMade;