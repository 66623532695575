import * as requestFromServer from './pPendingsCrud'
import { pPendingsSlice, callTypes } from './pPendingsSlice';

const { actions } = pPendingsSlice;

export const fetchPendingsList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getPPendingsList(data)
        .then(response => {
            const pPendings = response;
            dispatch(actions.pPendingsFetched({ pPendings }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting pending list", callType: callTypes.list }));
        });
};

export const fetchPendingDetails = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getPPendingDetails(id)
        .then(response => {
            const pPendingDetails = response;
            dispatch(actions.pPendingDetailsFetched({ pPendingDetails }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting pending detail", callType: callTypes.list }));
        });
};

export const fetchMakePayment = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .postMakePayment(data)
        .then(response => {
            if (response && response.status === "Success") {
                dispatch(actions.catchSuccess({ success: "Make payment", callType: callTypes.action }));
                dispatch(fetchPendingsList({}))
            } else {
                dispatch(actions.catchError({ error: "Failed to make payment", callType: callTypes.action }));
            };
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error to make payment", callType: callTypes.action }));
        });
};

export const clear = id => dispatch => {
    dispatch(actions.clear());
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
