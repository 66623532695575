import * as requestFromServer from './bHotmerCrud'
import { bHotmerSlice, callTypes } from './bHotmerSlice';

const { actions } = bHotmerSlice;

export const fetchBalancesList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getbHotmerList(data)
        .then(response => {
            const bHotmer = response;
            dispatch(actions.bHotmerFetched({ bHotmer }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting balance list", callType: callTypes.list }));
        });
};

export const fetchBalanceDetails = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getbHotmerDetails(id)
        .then(response => {
            const bHotmerDetails = response;
            dispatch(actions.bHotmerDetailsFetched({ bHotmerDetails }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting balance detail", callType: callTypes.list }));
        });
};

export const clear = id => dispatch => {
    dispatch(actions.clear());
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
