import * as requestFromServer from './dashboardCrud'
import { dashboardSlice, callTypes } from './dashboardSlice';

const { actions } = dashboardSlice;

export const fetchDashboard = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getDashboard(data)
        .then(response => {
            const dashboardInfo = response;
            dispatch(actions.dashboardFetched({ dashboardInfo }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting dashboard", callType: callTypes.list }));
        });
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
