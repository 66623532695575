import axios from 'axios';
import { URL_PAYMENT } from './config';

export async function getPPaymentsList() {
    const result = await axios.get(URL_PAYMENT);
    return result.data.data;
    
};

export async function getPPaymentsDetails(id) {
    const result = await axios.get(`${URL_PAYMENT}/${id}`);
    return result.data.data;
};

