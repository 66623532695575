import React from 'react'
import moment from 'moment';

export const dateTemplate = (date) => {
    return <span>{moment(date).format("YYYY/MM/DD")}</span>
};

export const commonTemplate = (data) => {
    return <span>{data}</span>
};

export const moneyTemplate = (data) => {
    return <span>${data}</span>
}; 

export const moneyTemplateGreen = (data)=> {
    //return <span>${data}</span>
    return <p style={{ color: "green", fontWeight: 500 }}>${data}</p>
}; 

export const commonTemplateGreen = (data)=> {
    //return <span>${data}</span>
    return <p style={{ color: "green", fontWeight: 500 }}>{data}</p>
}; 

export const moneyPositiveNegative = (data) => {
    let colorText = "green";
    switch (true) {
        case data < 0:
            colorText = "red";
            break;
        default:
            colorText = "green";
            break;
    }
    //<Rating value={vl_score / 2} readOnly cancel={false} stars={5} />
    return (
        <p style={{ color: colorText, fontWeight: 500 }}>{data}</p>
    )
};

