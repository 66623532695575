import React, { useEffect, useState, useRef, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AuthContext } from '../../../../auth/provider/authProvieder';
import * as actionsAgencyList from './_redux/agencyListActions';
import { Link } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { BreadCrumb } from 'primereact/components/breadcrumb/BreadCrumb';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import '../../../styles/customers.scss'

const AgencyList = () => {
    const [selectedUser, setSelectedUser] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');
    const toast = useRef(null);
    const dt = useRef(null);
    const { isAgencyAuthorized } = useContext(AuthContext);
    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    const breadcrumbItems = [
        { label: 'Users' },
        { label: 'Agency List' },
    ];
    const dispatch = useDispatch();
    const { pAgencyList, listLoading, actionsLoading, error, success } = useSelector(
        state => state.pAgencyList,
        shallowEqual
    );

    useEffect(() => {
        if (!pAgencyList) {
            dispatch(actionsAgencyList.fetchAgencyList({}));
        };
    }, [pAgencyList, dispatch]);

    useEffect(() => {
        return () => {
            setSelectedUser('');
            //setshowDialogDetail(false);
        }
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Agency', detail: error });
        };
    }, [error]);
    useEffect(() => {
        if (success) {
            toast.current.show({ severity: 'success', summary: 'Agency', detail: success });
        };
    }, [success]);

    const leftToolbarTemplate = () => {
        return (
            <>
                <Button label="Reload" icon="pi pi-refresh" className="p-button-warning p-ml-2"
                    onClick={() => {
                        setSelectedUser('');
                        dispatch(actionsAgencyList.fetchAgencyList({}));
                    }}
                    disabled={listLoading || actionsLoading}
                />
            </>
        )
    };

    const rightToolbarTemplate = () => {
        return (
            <>
                <Button label="Export" icon="pi pi-upload" className="p-button-help p-ml-2"
                    disabled={listLoading || actionsLoading} /*onClick={exportCSV}*/ 
                    onClick={()=>dt.current.exportCSV()}
                    />
            </>
        )
    };
    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Hotmers</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    
        
    const nickNameTemplate = (rowData) => {
        // return <Chip label={rowData.ds_full_name} image={rowData.ds_profile_photo_thumbnail_path}  />
        return (
            <div className="p-d-flex p-ai-center">
                <a href={`https://hotmy.app/${rowData.ds_display_name}`} target='_blank' without rel="noreferrer">{rowData.ds_display_name} </a>
            </div>
        )
    };

    const nameBodyTemplate = (rowData) => {
        return <span>{rowData.ds_full_name}</span>
    };

       const idBodyTemplate = (rowData) => {
        return <span>{rowData.id_user}</span>
    };

    const TypeRolBodyTemplate = (rowData) => {
        return (
            <>
                {/*<span className={`flag flag-${rowData.ds_flag_code.toLowerCase()}`} style={{ width: 30, height: 20 }} />*/}
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }}>{rowData.ds_type_of_role}</span>
            </>
        );
    };

    const qtyCommercialActivedBodyTemplate = (rowData) => {
        return (
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }}>{rowData.qty_commercial_active}</span>
        );
    };

    const qtyCommercialCreatedBodyTemplate = (rowData) => {
        return (
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }}>{rowData.qty_commercial_created}</span>
        );
    };
    

    
    const qtyCommercialInactiveBodyTemplate = (rowData) => {
        return (
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }}>{rowData.qty_commercial_inactive}</span>
        );
    };

    const qtyReferralBodyTemplate = (rowData) => {
        return (
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }}>{rowData.qty_referral}</span>
        );
    };

    const qtyCollaborationCreatedBodyTemplate = (rowData) => {
        return <span>{rowData.qty_collaboration_created}</span>
    };

    const qtyCollaborationAcceptedBodyTemplate = (rowData) => {
        return <span>{rowData.qty_collaboration_accepted}</span>
    };
    
    const qtyCollaborationRefusedBodyTemplate = (rowData) => {
        return <span>{rowData.qty_collaboration_refused}</span>
    };


    const actionBodyTemplate = (rowdata) => {
        return (
            <>
                {isAgencyAuthorized(1) &&
                    <Button tooltip="Details" icon="pi pi-eye" className="p-button-rounded p-button-info p-mr-1"
                        onClick={() => {
                            dispatch(actionsAgencyList.fetchAgencyList(rowdata.id_user));
                            //setshowDialogDetail(true);
                        }}
                    />
                }
                {isAgencyAuthorized(2) &&
                    <Link to={`/users/agencyList/edit/${rowdata.id_user}`}>
                        <Button tooltip="Edit" icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-1"
                        />
                    </Link>
                }
            </>
        );
    };

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12 card">
                <Toast ref={toast} position="bottom-right" />
                <BreadCrumb className="p-mb-2" home={breadcrumbHome} model={breadcrumbItems} />
                {listLoading && !pAgencyList ?
                    <div className="p-d-flex p-jc-center">
                        <ProgressSpinner />
                    </div>
                    :
                    <>
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                        <DataTable ref={dt}
                            value={pAgencyList} selection={selectedUser} onSelectionChange={(e) => setSelectedUser(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[ 10, 25, 50, 75, 100, 200]}
                            className="p-datatable-customers p-datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                            loading={listLoading || actionsLoading}
                            globalFilter={globalFilter} emptyMessage="Not users found." header={header}>
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="id_user" header="Id" sortable body={idBodyTemplate} filter filterPlaceholder="id" />
                            <Column headerStyle={{ width: '12%' }}className="p-text-center" field="ds_display_name" header="Nick" sortable body={nickNameTemplate} filter filterPlaceholder="Nick" />
                            <Column headerStyle={{ width: '12%' }}className="p-text-center" field="ds_full_name" header="Name" sortable body={nameBodyTemplate} filter filterPlaceholder="Name" />
                            <Column className="p-text-center" field="ds_type_of_role" header="Role" sortable body={TypeRolBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Role" />
                            <Column headerStyle={{ width: '6%' }}className="p-text-center" field="qty_collaboration_created" header="Coll Created" sortable body={qtyCollaborationCreatedBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Qty.Coll.Created" />
                            <Column headerStyle={{ width: '6%' }}className="p-text-center" field="qty_collaboration_accepted" header="Coll Refuse" sortable body={qtyCollaborationAcceptedBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Qty.Coll.Accepted" />
                            <Column headerStyle={{ width: '6%' }}className="p-text-center" field="qty_collaboration_refuse" header="Coll Refuse" sortable body={qtyCollaborationRefusedBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Qty.Coll.Refused" />
                            <Column headerStyle={{ width: '6%' }}className="p-text-center" field="qty_commercial_created" header="Com Created Refuse" sortable body={qtyCommercialCreatedBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Qty.Comm.Created" />
                            <Column headerStyle={{ width: '6%' }}className="p-text-center" field="qty_commercial_active" header="Com Active" sortable body={qtyCommercialActivedBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Qty.Comm.Active" />
                            <Column headerStyle={{ width: '6%' }}className="p-text-center" field="qty_commercial_inactive" header="Com Inactive" sortable body={qtyCommercialInactiveBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Qty.Comm.Inactive" />
                            <Column headerStyle={{ width: '6%' }}className="p-text-center" field="qty_referral" header="Qty Referral" sortable body={qtyReferralBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Qty.referral" />
                            <Column headerStyle={{ width: '10%' }}className="p-text-center" header="Actions" sortable body={actionBodyTemplate} />
                        </DataTable>
                    </>
                }
            </div>
        </div>
    )
}

export default AgencyList;