import React from 'react'

const DashboardCard2 = ({ title, description, number, color, backgroundColor }) => {
    return (
        <div className="p-col-12 p-lg-4">
            <div className="card summary">
                <span className="title">{title}</span>
                <span className="detail">{description}</span>
                <span className="count" style={{ backgroundColor: backgroundColor, color: color }}>{number}</span>
            </div>
        </div>
    )
}

export default DashboardCard2;