import React, { useEffect, useRef, useState, useContext } from 'react';
import { AuthContext } from '../../../../auth/provider/authProvieder';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actionsBHotmer from './_redux/bHotmerActions';
import { BreadCrumb } from 'primereact/components/breadcrumb/BreadCrumb';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import { Toast } from 'primereact/toast';
import DialogPPendingDetail from '../../../components/dialogs/DialogPPendingDetail';
import { balanceFunction } from '../../../functions/stringFunctions';

const Balancehotmer = () => {
    const [selectedUser, setSelectedUser] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');
    const [showDialogDetail, setshowDialogDetail] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const { isBHotmerAuthorized } = useContext(AuthContext);
    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    const breadcrumbItems = [
        { label: 'Financial' },
        { label: 'Balance Hotmer' },
    ];
    const dispatch = useDispatch();
    const { bHotmer, bHotmerDetails, listLoading, actionsLoading, error, success } = useSelector(
        state => state.bHotmer,
        shallowEqual
    );
    useEffect(() => {
        if (!bHotmer) {
            dispatch(actionsBHotmer.fetchBalancesList({}));
        };
    }, [bHotmer, dispatch]);

    //TOAST DETAIL NOT FOUND
    useEffect(() => {
        if (!listLoading && !bHotmerDetails && showDialogDetail) {
            toast.current.show({ severity: 'warn', summary: 'Payments', detail: 'Balance hotmer detaill not found' });
        }
    }, [listLoading, bHotmerDetails, showDialogDetail]);

    //ERROR TOAST
    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Payments', detail: error });
        };
    }, [error]);

    //SUCCESS TOAST
    useEffect(() => {
        if (success) {
            toast.current.show({ severity: 'success', summary: 'Payments', detail: success });
        };
    }, [success]);

    useEffect(() => {
        return () => {
            setSelectedUser('');
            setshowDialogDetail(false);
            dispatch(actionsBHotmer.clearAll({}));
        }
    }, [dispatch]);

    const leftToolbarTemplate = () => {
        return (
            <>
                <Button label="Reload" icon="pi pi-refresh" className="p-button-warning p-ml-2"
                    onClick={() => {
                        dispatch(actionsBHotmer.fetchBalancesList({}));
                    }}
                    disabled={listLoading || actionsLoading}
                />
            </>
        )
    };
    const rightToolbarTemplate = () => {
        return (
            <>
                <Button label="Export" icon="pi pi-upload" className="p-button-help p-ml-2"
                    disabled={listLoading || actionsLoading} /*onClick={exportCSV}*/
                    onClick={()=>dt.current.exportCSV()}
                    />
            </>
        )
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Financial Made</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const nameTemplate = (rowData) => {
        return (
            <div className="p-d-flex p-ai-center">
                <Avatar image={rowData.ds_profile_photo_thumbnail_path} className="p-mr-2" size="small" shape="circle" />
                <span className="image-text" >{rowData.full_name}</span>
            </div>
        )
    };
    const countryTemplate = (rowData) => {
        return (
            <>
                <span className={`flag flag-${rowData.ds_flag_code.toLowerCase()}`} style={{ width: 30, height: 20 }} />
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }}>{rowData.ds_nationality}</span>
            </>
        )
    };

    const InternationalTemplate = ({ international_income_extraction }) => {
        const { positive, negative } = balanceFunction(international_income_extraction);
        return <p style={{ fontWeight: 500 }}><span style={{ color: "green" }}>{positive}</span> / <span style={{ color: "red" }}>{negative}</span></p>
    };
    const LocalTemplate = ({ local_income_extraction }) => {
        const { positive, negative } = balanceFunction(local_income_extraction);
        return <p style={{ fontWeight: 500 }} p><span style={{ color: "green" }}>{positive}</span> / <span style={{ color: "red" }}>{negative}</span></p>
    };
    const hotmyTemplate = ({ hotmy_income }) => {
        return <p style={{ color: "green", fontWeight: 500 }}>{hotmy_income}</p>
    };
    
    const actionBodyTemplate = (rowdata) => {
        return (
            <>
                {isBHotmerAuthorized(1) &&
                    <Button tooltip="Details" icon="pi pi-eye" className="p-button-rounded p-button-info p-mr-2"
                        onClick={() => {
                            dispatch(actionsBHotmer.fetchBalanceDetails(rowdata.id_hotmer));
                            setshowDialogDetail(true);
                        }}
                    />
                }
            </>
        );
    };

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12 card">
                <Toast ref={toast} position="bottom-right" onClose={() => setshowDialogDetail(false)} />
                <BreadCrumb className="p-mb-2" home={breadcrumbHome} model={breadcrumbItems} />
                {listLoading && !bHotmer ?
                    <div className="p-d-flex p-jc-center">
                        <ProgressSpinner />
                    </div>
                    :
                    <>
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
                        <DataTable ref={dt}
                            value={bHotmer}
                            selection={selectedUser} onSelectionChange={(e) => setSelectedUser(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[ 10, 25, 50, 75, 100, 200]}
                            className="p-datatable-customers p-datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} payments pendings"
                            loading={listLoading || actionsLoading}
                            globalFilter={globalFilter} emptyMessage="Not payments pendings found." header={header}>
                            <Column className="p-text-center" field="full_name" header="Name" sortable body={nameTemplate} filter filterPlaceholder="Name" />
                            <Column className="p-text-center" field="ds_nationality" header="Country" sortable body={countryTemplate} filter filterPlaceholder="Country" />
                            <Column className="p-text-center" field="international_income_extraction" header="Interternational" sortable body={InternationalTemplate} filter filterPlaceholder="$0.00" filterMatchMode="contains" />
                            <Column className="p-text-center" field="local_income_extraction" header="Local" sortable body={LocalTemplate} filter filterPlaceholder="$0.00" filterMatchMode="contains" />
                            <Column className="p-text-center" field="hotmy_income" header="Hotmy" sortable body={hotmyTemplate} filter filterPlaceholder="$0.00" filterMatchMode="contains" />
                            <Column field="action" header="Actions" sortable body={actionBodyTemplate} />
                        </DataTable>
                    </>
                }
            </div>
            <DialogPPendingDetail
                payment={bHotmerDetails}
                show={!listLoading && bHotmerDetails && showDialogDetail}
                setShow={setshowDialogDetail}
            />
        </div>
    )
}

export default Balancehotmer;