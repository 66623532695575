import React, { useEffect, useState, useRef, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AuthContext } from '../../../../auth/provider/authProvieder';
import * as actionsPPayments from './_redux/pPaymentsActions';
//import { Link } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Avatar } from 'primereact/avatar';
//import { Calendar1 } from 'primereact/calendar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { ProgressSpinner } from 'primereact/progressspinner';
import { MultiSelect } from 'primereact/multiselect';
import { filterOptions } from '../../../functions/arrayFunctions'
//import { Avatar } from 'primereact/avatar';
//import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
//import DialogUserDeatil from '../../../components/dialogs/DialogUserDeatil';
import { commonTemplate, dateTemplate, moneyTemplate, moneyPositiveNegative } from '../../../components/dialogs/dataTableTemplates';
import '../../../styles/customers.scss'


const PaymentList = () => {
    const [selectedUser, setSelectedUser] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');
    const [showDialogDetail, setshowDialogDetail] = useState(false);
    const [showDialogMake, setShowDialogMake] = useState(false);
    const [orderFilter, setOrderFilter] = useState('');
    const [referralByFilter, setreferralByFilter] = useState('');

    const [idprofitFilter, setidprofitFilter] = useState('');

    const [vlhotmerprofitFilter, setvlhotmerProfitFilter] = useState('');
    const [vlhotmyprofitFilter, setvlhotmyProfitFilter] = useState('');
    const [insertDateFilter, setInsertDateFilter] = useState('');
    const [vl_feeprofitFilter, setvl_feeProfitFilter] = useState('');
    const [referenceCodeFilter, setReferenceCodeFilter] = useState('');
    const [vl_manager_profitprofitFilter, setVl_manager_profitprofitFilter] = useState('');
    const [vl_photographer_profitFilter, setVl_photographer_profitFilter] = useState('');
    const [vl_referalprofitFilter, setvl_referalProfitFilter] = useState('');
    const [iduserdestinyfilter, setiduserdestinyfilter] = useState('');
    const [iduseroriginFilter, setiduseroriginFilter] = useState('');
    const [withdrawFilter, setWithdrawFilter] = useState('');
    //iduseroriginFilterChange
    //const [insertDateFilter, setInsertDateFilter] = useState('');
    // const [selectedCurrenDestination, setselectedCurrenDestination] = useState(null);
    //const [selectedCurrentOrigen, setSelectedCurrentOrigen] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [selectedMoneyMethod, setSelectedMoneyMethod] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const { isPPaymentAuthorized } = useContext(AuthContext); // autorizacion
    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    const breadcrumbItems = [
        { label: 'Financial' },
        { label: 'Payments List' },
    ];
    const dispatch = useDispatch();
    const { pPayments, pPaymentsDetails, listLoading, actionsLoading, error, success } = useSelector(
        state => state.pPayments,
        shallowEqual
    );

    useEffect(() => {
        if (!pPayments) {
            dispatch(actionsPPayments.fetchPaymentList({}));
        };
    }, [pPayments, dispatch]);

    //TOAST DETAIL NOT FOUND
    useEffect(() => {
        if (!listLoading && !pPaymentsDetails && showDialogDetail) {
            toast.current.show({ severity: 'warn', summary: 'Payments', detail: 'Payment detaill not found' });
        }
    }, [listLoading, pPaymentsDetails, showDialogDetail]);

    //ERROR TOAST
    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Payments', detail: error });
        };
    }, [error]);

    //SUCCESS TOAST
    useEffect(() => {
        if (success) {
            toast.current.show({ severity: 'success', summary: 'Payments', detail: success });
        };
    }, [success]);

    useEffect(() => {
        return () => {
            setSelectedUser('');
            setshowDialogDetail(false);
            dispatch(actionsPPayments.clear({}));
        }
    }, [dispatch]);

    const leftToolbarTemplate = () => {
        return (
            <>
                <Button label="Reload" icon="pi pi-refresh" className="p-button-warning p-ml-2"
                    onClick={() => {
                        dispatch(actionsPPayments.fetchPaymentList({}));
                    }}
                    disabled={listLoading || actionsLoading}
                />
            </>
        )
    };

    const rightToolbarTemplate = () => {
        return (
            <>
                <Button label="Export" icon="pi pi-upload" className="p-button-help p-ml-2"
                    disabled={listLoading || actionsLoading} /*onClick={exportCSV}*/
                    onClick={() => dt.current.exportCSV()}
                />
            </>
        )
    };
    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Payments Inn</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    /*const cDestinationTemplate = (rowData) => {
        return (
            <Button
                tooltip={`Conversion ${rowData.bl_conversion ? "aproved" : "rejected"}`}
                label={rowData.ds_currency_destination}
                //icon={`pi ${rowData.bl_conversion ? "pi-check" : "pi-times"}`}
                className={`p-button-sm p-button-${rowData.bl_conversion ? "success" : "secondary"}`}
            />
        )
    };*/

    //FILTERS
    const orderFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'id_payment', 'contains');
        setOrderFilter(e.currentTarget.value);
    };
    const orderFilterCustom =
        <InputText
            value={orderFilter}
            placeholder="Nº"
            onChange={orderFilterChange}
            style={{ width: "100%" }}
        />;

    const referralByFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'referral_by', 'contains');
        setreferralByFilter(e.currentTarget.value);
    };
    const ReferralByFilterCustom =
        <InputText
            value={referralByFilter}
            placeholder="Nº"
            onChange={referralByFilterChange}
            style={{ width: "100%" }}
        />;


    //id_user_of_destination
    const iduserdestinyFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'id_user_of_destination', 'contains');
        setiduserdestinyfilter(e.currentTarget.value);
    };
    const iduserdestinyFilterCustom =
        <InputText
            value={iduserdestinyfilter}
            placeholder="hotmer"
            onChange={iduserdestinyFilterChange}
            style={{ width: "100%" }}
        />;



    ///id_user_of_origin
    const iduseroriginFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'id_user_of_origin', 'contains');
        setiduseroriginFilter(e.currentTarget.value);
    };
    const iduseroriginFilterCustom =
        <InputText
            value={iduseroriginFilter}
            placeholder="user"
            onChange={iduseroriginFilterChange}
            style={{ width: "100%" }}
        />;





    const idprofitFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'id_profit_account', 'contains');
        setidprofitFilter(e.currentTarget.value);
    };
    const idprofitFilterCustom =
        <InputText
            value={idprofitFilter}
            placeholder="Nº"
            onChange={idprofitFilterChange}
            style={{ width: "100%" }}
        />;

    const withdrawFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'vl_withdraw', 'startsWith');
        setWithdrawFilter(e.currentTarget.value);
    };
    const withdrawFilterCustom =
        <InputText
            value={withdrawFilter}
            placeholder="Withdraw"
            onChange={withdrawFilterChange}
            style={{ width: "100%" }}
        />;

    ///FILTRO NORMAL PARA vl_hotmer_profit
    const vlhotmerprofitFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'vl_hotmer_profit', 'contains');
        setvlhotmerProfitFilter(e.currentTarget.value);
    };
    const vlhotmerprofitFilterCustom =
        <InputText
            value={vlhotmerprofitFilter}
            placeholder="$"
            onChange={vlhotmerprofitFilterChange}
            style={{ width: "100%" }}
        />;


    ///FILTRO NORMAL PARA vl_accum_company_profit


    const vlhotmyprofitFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'vl_accum_company_profit', 'contains');
        setvlhotmyProfitFilter(e.currentTarget.value);
    };
    const vlhotmyprofitFilterCustom =
        <InputText
            value={vlhotmyprofitFilter}
            placeholder="$"
            onChange={vlhotmyprofitFilterChange}
            style={{ width: "100%" }}
        />;

    ///FILTRO NORMAL PARA VL_FEE
    const vl_feeprofitFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'vl_fee', 'contains');
        setvl_feeProfitFilter(e.currentTarget.value);
    };
    const vl_feeprofitFilterCustom =
        <InputText
            value={vl_feeprofitFilter}
            placeholder="$"
            onChange={vl_feeprofitFilterChange}
            style={{ width: "100%" }}
        />;

    ///FILTRO NORMAL PARA vl_manager_profit
    const vl_photographer_profitFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'vl_photographer_profit', 'contains');
        setVl_photographer_profitFilter(e.currentTarget.value);
    };
    const vl_photographer_profitprofitFilterCustom =
        <InputText
            value={vl_photographer_profitFilter}
            placeholder="$"
            onChange={vl_photographer_profitFilterChange}
            style={{ width: "100%" }}
        />;

    ///FILTRO NORMAL PARA vl_manager_profit
    const vl_manager_profitFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'vl_manager_profit', 'contains');
        setVl_manager_profitprofitFilter(e.currentTarget.value);
    };
    const vl_manager_profitprofitFilterCustom =
        <InputText
            value={vl_manager_profitprofitFilter}
            placeholder="$"
            onChange={vl_manager_profitFilterChange}
            style={{ width: "100%" }}
        />;

    ///FILTRO NORMAL PARA VL_FEE
    const vl_referalprofitFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'vl_user_referral_hotmer_profit', 'contains');
        setvl_referalProfitFilter(e.currentTarget.value);
    };
    const vl_referalprofitFilterCustom =
        <InputText
            value={vl_referalprofitFilter}
            placeholder="$"
            onChange={vl_referalprofitFilterChange}
            style={{ width: "100%" }}
        />;

    ///FILTRO PARA dt_insert
    const insertDateFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'dt_insert', 'contains');
        setInsertDateFilter(e.currentTarget.value);
    };
    const insertDateFilterCustom =
        <InputText
            value={insertDateFilter}
            placeholder="No Filtra"
            onChange={insertDateFilterChange}
            style={{ width: "100%" }}
        />;
    {/* 
            <Calendar1 
                id="icon"
                value={insertDateFilter}
                placeholder="Date"
                onChange={insertDateFilterChange}
                showIcon
            />;
            */}


    ///FILTRO PARA TIPO DE PROFIT
    const methodFilterChange = ({ value }) => {
        dt.current.filter(value, 'ds_name', 'in');
        setSelectedMethod(value)
    };
    const methodFilter =
        <MultiSelect style={{ width: "100%" }} className="p-column-filter" value={selectedMethod} options={filterOptions(pPayments, "ds_name")}
            onChange={methodFilterChange} placeholder="All"
        />;

    ///FILTRO PARA MONEDA PAIS
    const currencycodeFilterChange = ({ value }) => {
        dt.current.filter(value, 'ds_currency_code', 'in');
        setSelectedMoneyMethod(value)
    };
    const currencycodeFilter =
        <MultiSelect style={{ width: "100%" }} className="p-column-filter" value={selectedMoneyMethod} options={filterOptions(pPayments, "ds_currency_code")}
            onChange={currencycodeFilterChange} placeholder="All"
        />;

    ///FILTRO NORMAL PARA VL_FEE
    const referenceCodeFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'reference_code', 'contains');
        setReferenceCodeFilter(e.currentTarget.value);
    };

    const referenceCodefitFilterCustom =
        <InputText
            value={referenceCodeFilter}
            placeholder="Reference code"
            onChange={referenceCodeFilterChange}
            style={{ width: "100%" }}
        />;


    const nickNameTemplate = (rowData) => {
        // return <Chip label={rowData.ds_full_name} image={rowData.ds_profile_photo_thumbnail_path}  />
        return (
            <div className="p-d-flex p-ai-center">
                <Avatar image={rowData.ds_profile_photo_thumbnail_path} className="p-mr-2" size="small" shape="circle" />
                <a href={`https://hotmy.app/${rowData.ds_nick_name}`} target='_blank' without rel="noreferrer">{rowData.ds_nick_name} </a>
            </div>
        )
    };


    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12 card">
                <Toast ref={toast} position="bottom-right" onClose={() => setshowDialogDetail(false)} />
                <BreadCrumb className="p-mb-2" home={breadcrumbHome} model={breadcrumbItems} />
                {listLoading && !pPayments ?
                    <div className="p-d-flex p-jc-center">
                        <ProgressSpinner />
                    </div>
                    :
                    <>
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
                        <DataTable ref={dt}
                            value={pPayments}
                            selection={selectedUser} onSelectionChange={(e) => setSelectedUser(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[10, 25, 50, 75, 100, 200]}
                            className="p-datatable-customers p-datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} payments pendings"
                            loading={listLoading || actionsLoading}
                            globalFilter={globalFilter} emptyMessage="Not payments." header={header}>
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="dt_insert" header="Fecha" sortable body={e => dateTemplate(e.dt_insert)} filter filterElement={insertDateFilterCustom} />
                            <Column headerStyle={{ width: '5%' }} className="p-text-center" field="id_user_of_origin" header="id U" sortable body={e => commonTemplate(e.id_user_of_origin)} filter filterElement={iduseroriginFilterCustom} />
                            <Column headerStyle={{ width: '5%' }} className="p-text-center" field="id_user_of_destination" header="id H" sortable body={e => commonTemplate(e.id_user_of_destination)} filter filterElement={iduserdestinyFilterCustom} />
                            <Column headerStyle={{ width: '10%' }} className="p-text-center" field="ds_display_name" header="Nick" sortable body={nickNameTemplate} filter filterPlaceholder="Nick" />
                            <Column className="p-text-center" field="reference_code" header="reference" sortable body={e => commonTemplate(e.reference_code)} filter filterElement={referenceCodefitFilterCustom} />
                            <Column className="p-text-center" field="ds_name" header="Tipo" sortable body={e => commonTemplate(e.ds_name)} filter filterElement={methodFilter} />
                            <Column headerStyle={{ width: '4%' }} className="p-text-center" field="ds_currency_code" header="Moneda" sortable body={e => commonTemplate(e.ds_currency_code)} filter filterElement={currencycodeFilter} />
                            <Column headerStyle={{ width: '8%' }} className="p-text-center" field="vl_accum_total" header="Total" sortable body={e => moneyTemplate(e.vl_accum_total)} filter filterElement={withdrawFilterCustom} />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="vl_hotmer_profit" header="+ Hotmer" sortable body={e => moneyPositiveNegative(e.vl_hotmer_profit)} filter filterElement={vlhotmerprofitFilterCustom} />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="vl_hotmer_profit" header="+ Hotmy" sortable body={e => moneyTemplate(e.vl_accum_company_profit)} filter filterElement={vlhotmyprofitFilterCustom} />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="vl_user_referral_hotmer_profit" header="Referido" sortable body={e => moneyTemplate(e.vl_user_referral_hotmer_profit)} filter filterElement={vl_referalprofitFilterCustom} />
                            {/* <Column headerStyle={{ width: '6%' }} className="p-text-center" field="vl_user_referral_hotmer_profit" header="Referido" sortable body={e => moneyTemplate(e.vl_user_referral_hotmer_profit)} filter filterElement={vl_referalprofitFilterCustom} />*/}
                            <Column headerStyle={{ width: '4%' }} className="p-text-center" field="vl_fee" header="Tax" sortable body={e => moneyTemplate(e.vl_fee)} filter filterElement={vl_feeprofitFilterCustom} />
                            <Column headerStyle={{ width: '4%' }} className="p-text-center" field="referral_by" header="id Referido" sortable body={e => commonTemplate(e.referral_by)} filter filterElement={ReferralByFilterCustom} />
                            <Column headerStyle={{ width: '4%' }} className="p-text-center" field="vl_manager_profit" header="Agency" sortable body={e => moneyTemplate(e.vl_manager_profit)} filter filterElement={vl_manager_profitprofitFilterCustom} />
                            <Column headerStyle={{ width: '4%' }} className="p-text-center" field="vl_photographer_profit" header="Ph" sortable body={e => moneyTemplate(e.vl_photographer_profit)} filter filterElement={vl_photographer_profitprofitFilterCustom} />
                            <Column headerStyle={{ width: '4%' }} className="p-text-center" field="id_payment" header="id Pago" sortable body={e => commonTemplate(e.id_payment)} filter filterElement={orderFilterCustom} />
                            <Column headerStyle={{ width: '4%' }} className="p-text-center" field="id_profit_account" header="id Profit" sortable body={e => commonTemplate(e.id_profit_account)} filter filterElement={idprofitFilterCustom} />
                            {/* <Column field="action" header="Actions" sortable body={actionBodyTemplate} />*/}
                        </DataTable>
                    </>
                }
            </div>
            {/* <DialogPPendingDetail
                payment={pPaymentsDetails}
                show={!listLoading && pPaymentsDetails && showDialogDetail}
                setShow={setshowDialogDetail}
            />*/}
        </div>
    )
}
export default PaymentList;