import React, { useEffect, useRef, useState, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AuthContext } from '../../../../auth/provider/authProvieder';
import * as actionspCovers from './_redux/pCoversActions';
import { Dialog } from 'primereact/dialog';
// import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Avatar } from 'primereact/avatar';
import { Tag } from 'primereact/tag';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
// import DialogPcoversDetails from '../../../components/dialogs/DialogPcoversDetails';

const CoverList = () => {
    const [selectedItem, setSelectedItem] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');
    const [showUrl, setShowUrl] = useState();
    const [showDialogDetail, setshowDialogDetail] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const { ispCoversAuthorized } = useContext(AuthContext);
    const dispatch = useDispatch();
    const [displayBasic, setDisplayBasic] = useState(false);
    const { pCovers, pCoversDetails, listLoading, actionsLoading, error, success } = useSelector(
        state => state?.pCovers,
        shallowEqual
    );

    useEffect(() => {
        if (!pCovers) {
            dispatch(actionspCovers.fetchCoversList({}));
        };
    }, [pCovers, dispatch]);

    //TOAST DETAIL NOT FOUND

    useEffect(() => {
        if (!listLoading && !pCoversDetails && showDialogDetail) {
            toast.current.show({ severity: 'warn', summary: 'Covers', detail: 'Covers detaill not found' });
        }
    }, [listLoading, pCoversDetails, showDialogDetail]);

    //ERROR TOAST
    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Covers', detail: error });
        };
    }, [error]);

    //SUCCESS TOAST
    useEffect(() => {
        if (success) {
            toast.current.show({ severity: 'success', summary: 'Covers', detail: success });
        };
    }, [success]);

    useEffect(() => {
        return () => {
            setSelectedItem('');
            setshowDialogDetail(false);
            dispatch(actionspCovers.clearAll({}));
        }
    }, [dispatch]);

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Cover List</h5>
            <Button label="Reload" icon="pi pi-refresh" className="p-button-warning p-ml-2"
                onClick={() => {
                    dispatch(actionspCovers.fetchCoversList({}));
                }}
                disabled={listLoading || actionsLoading}
            />
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    ///  DEFINIMOS LOS BOTONES DE ACCION.

    // const actionBodyTemplate = (rowData) => {

    //     console.log("rowData",rowData)

    //     const listItems1 = rowData?.coversUser?.map((avatars) =>
    //         <span>
    //             {avatars?.ds_url_img_cover} {}
    //         </span>
    //     );

    //     setShowUrl(listItems1)

    //     return (
    //         <>
    //             <Button
    //                 type="button"
    //                 label="See"
    //                 onClick={() => setDisplayBasic(true)}
    //                 // icon="pi pi-check"
    //                 className="p-button-secondary"
    //             />
    //             {displayBasic
    //                 && listItems1
    //                 &&
    //                 <Dialog
    //                     header="Dialog"
    //                     visible={displayBasic}
    //                     style={{ width: '80vw' }}
    //                     onHide={() => setDisplayBasic(false)}>
    //                     {listItems1}
    //                 </Dialog>
    //             }
    //         </>
    //     );
    // }

    const idBodyTemplate = (rowData) => {
        return <span>{rowData.id_user}</span>
    };

    const nickNameTemplate = (rowData) => {
        // return <Chip label={rowData.ds_full_name} image={rowData.ds_profile_photo_thumbnail_path}  />
        return (
            <div className="p-d-flex p-ai-center">
                <Avatar image={rowData.ds_profile_photo_thumbnail_path} className="p-mr-2" size="small" shape="circle" />
                <a href={`https://hotmy.app/${rowData.ds_display_name}`} target='_blank' without rel="noreferrer">{rowData.ds_display_name} </a>
            </div>
        )
    };

    const nickCoverPhoto = (rowData) => {
        return (
            <div className="p-d-flex p-ai-center">
                <Avatar image={rowData.ds_profile_cover_thumbnail_active} className="p-mr-2" style={{ width: 50, height: 50 }} />
            </div>
        )
    };

    const nickCoverPhoto2 = (rowData) => {

        const listItems = rowData?.coversUser?.map((avatars) =>
            // Wrong! The key should have been specified here:
            <Avatar 
            image={avatars?.ds_url_img_cover} 
            className="p-mr-2" style={{ width: 50, height: 50 }}
            />
        );

        return (
            < div className="p-d-flex p-ai-center" >
                {listItems}
            </div >
        )
    };

    const nameBodyTemplate = (rowData) => {
        return <span>{rowData.ds_full_name}</span>
    };

    const bl_coverTemplate = (rowData) => {
        return <span>{rowData.bl_have_cover_art} - {rowData.vl_cover_count}</span>

    };

    const scoreBodyTemplate = ({ vl_score }) => {
        let backgroundColor = "red";
        switch (true) {
            case vl_score >= 0 && vl_score <= 4:
                backgroundColor = "red";
                break;
            case vl_score > 4 && vl_score <= 7:
                backgroundColor = "orange";
                break;
            case vl_score > 7 && vl_score <= 10:
                backgroundColor = "green";
                break;
            default:
                backgroundColor = "red";
                break;
        }
        //<Rating value={vl_score / 2} readOnly cancel={false} stars={5} />
        return (
            <div className="p-d-flex p-jc-center p-ai-center">
                <b className="p-mr-1 p-text-center" style={{ width: '20%' }}>{vl_score}</b>
                <Tag className="p-p-2" style={{ backgroundColor: backgroundColor }} rounded />
            </div>
        )
    }

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12 card">
                <Toast ref={toast} position="bottom-right" onClose={() => setshowDialogDetail(false)} />
                {/* <BreadCrumb className="p-mb-2" home={breadcrumbHome} model={breadcrumbItems} /> */}
                {listLoading && !pCovers ?
                    <div className="p-d-flex p-jc-center">
                        <ProgressSpinner />
                    </div>
                    :
                    <>
                        {/* <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} /> */}
                        <DataTable ref={dt}
                            value={pCovers}
                            selection={selectedItem} onSelectionChange={(e) => setSelectedItem(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[10, 25, 50, 75, 100, 200]}
                            className="p-datatable-customers p-datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Covers"
                            loading={listLoading || actionsLoading}
                            globalFilter={globalFilter} emptyMessage="Not Covers found." header={header}>
                            <Column headerStyle={{ width: '4%' }} className="p-text-center" field="id_user" header="Id" sortable body={idBodyTemplate} filter filterPlaceholder="id" />
                            <Column headerStyle={{ width: '10%' }} className="p-text-center" field="ds_display_name" header="Nick" sortable body={nickNameTemplate} filter filterPlaceholder="Nick" />
                            <Column headerStyle={{ width: '10%' }} className="p-text-center" field="ds_full_name" header="Name" sortable body={nameBodyTemplate} filter filterPlaceholder="Name" />
                            <Column headerStyle={{ width: '5%' }} className="p-text-center" field="bl_have_cover_art" header="Cover" sortable body={bl_coverTemplate} filter filterPlaceholder="Have Cover" />
                            <Column headerStyle={{ width: '10%' }} className="p-text-center" field="vl_score" header="Score" sortable body={scoreBodyTemplate} filter filterType="number" filterPlaceholder="Score" />
                            <Column headerStyle={{ width: '10%' }} className="p-text-center" field="" header="CoverActive" sortable body={nickCoverPhoto} filter filterPlaceholder="CoverA" />
                            <Column headerStyle={{ width: '40%' }} className="p-text-center" field="" header="Covers" sortable body={nickCoverPhoto2} filter filterPlaceholder="Covers" />
                            {/* <Column headerStyle={{ width: '10%' }} className="p-text-center" field="action" header="Actions" sortable body={actionBodyTemplate} /> */}
                        </DataTable>
                    </>
                }
            </div>
        </div>
    )
}

export default CoverList;