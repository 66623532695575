import * as requestFromServer from './authCrud'
import { authSlice, callTypes } from './authSlice';

const { actions } = authSlice;

export const fetchLogin = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getSession(data)
        .then(response => {
            //setTimeout(() => {
            const auth = response;
            dispatch(actions.authFetched({ auth }));
            //}, 1000);
        })
        .catch(error => {
            error = "Failed to login";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchLogout = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .closeSession()
        .then(() => {
            //setTimeout(() => {
            dispatch(actions.clearAll());
            //}, 1000);
        })
        .catch(error => {
            error = "Failed to log out";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const reloadAuth = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    dispatch(actions.reloadAuth())
};