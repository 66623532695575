import React from 'react';
import { AuthConsumer } from './auth/provider/authProvieder';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import App from './App';
//import * as serviceWorker from './serviceWorker';
import ScrollToTop from './ScrollToTop';

import Login from './auth/Login';

const PrivatesRoutes = ({ ...rest }) => {
    return (
        <AuthConsumer>
            {({ auth }) =>
                <Route
                    render={({ location }) =>
                        auth ? (
                            <BrowserRouter>
                                <ScrollToTop>
                                    <App></App>
                                </ScrollToTop>
                            </BrowserRouter>
                        ) :
                            <Redirect to={{
                                pathname: "/login",
                                state: { from: location }
                            }} />
                    }
                />
            }
        </AuthConsumer>
    )
}

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={Login} />
                {/* <Route path="/logout" component={Login} /> */}
                <PrivatesRoutes path="/" />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;