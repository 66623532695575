import React, { useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as dashboardActions from './_redux/dashboardActions';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
// import { BreadCrumb } from 'primereact/components/breadcrumb/BreadCrumb';
import DashboardCard2 from '../../components/DashboardCard2'

const Dashboard = () => {
    const toast = useRef(null);
    // const breadcrumbHome = { icon: 'pi pi-home', to: '/' };
    const dispatch = useDispatch();
    const { dashboardInfo, listLoading, actionsLoading, /*error, success*/ } = useSelector(
        state => state.dashboard,
        shallowEqual
    );

    useEffect(() => {
        if (!dashboardInfo) {
            dispatch(dashboardActions.fetchDashboard({}));
        };
    }, [dashboardInfo, dispatch]);
    
    useEffect(() => {
        return () => {
            dispatch(dashboardActions.clearAll({}));
        }
    }, [dispatch]);

    const leftToolbarTemplate = () => {
        return (
            <>
                <Button label="Reload" icon="pi pi-refresh" className="p-button-warning p-ml-2"
                    onClick={() => dispatch(dashboardActions.fetchDashboard({}))}
                    disabled={listLoading || actionsLoading}
                />
            </>
        )
    };

    console.log("DASH INFO ", dashboardInfo);
    return (
        <div>

            <Toast ref={toast} position="bottom-right" />
            {/* <BreadCrumb className="p-mb-2" home={breadcrumbHome} /> */}
            <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>
            {listLoading ?
                <div className="p-d-flex p-jc-center">
                    <ProgressSpinner />
                </div>
                :
                dashboardInfo ?
                    <div className="p-grid p-fluid dashboard">
                        <DashboardCard2
                            title="Users"
                            description="Cantidad de Usuarios Activos"
                            number={dashboardInfo.count_users}
                            backgroundColor="#D2B4DE"
                            color="#17202A"
                        />          
                        <DashboardCard2
                            title="Hotmers"
                            description="Cantidad de Hotmers Activos"
                            number={dashboardInfo.count_hotmers}
                            backgroundColor="#C39BD3"
                            color="#17202A"
                        />
                        <DashboardCard2
                            title="Usuarios Registrados hoy"
                            description={dashboardInfo.count_day}
                            number={dashboardInfo.count_user_today}
                            backgroundColor="#C39BD3"
                            color="#17202A"
                        />
                                                <DashboardCard2
                            title="Usuarios Activos Hoy"
                            description={dashboardInfo.count_day}
                            number={dashboardInfo.count_user_active_today}
                            backgroundColor="#C39BD3"
                            color="#17202A"
                        />
                        <DashboardCard2
                            title="Hotmers Pending validation"
                            description="Cantidad de Hotmers pendiente de Validación"
                            number={dashboardInfo.count_hotmer_pending_validation}
                            backgroundColor="#6d295b"
                            color="#e894d1"
                        />
                        <DashboardCard2
                            title="Hotmers Rejected"
                            description="Cantidad de Hotmers Rechazados"
                            number={dashboardInfo.count_hotmer_rejected}
                            backgroundColor="#6d295b"
                            color="#e894d1"
                        />
                        <DashboardCard2
                            title="Payment Pendings"
                            description="Pagos solicitados por el Hotmer que aun no se pagaron"
                            number={dashboardInfo.count_payment_pending}
                            backgroundColor="#ff3300"
                            color="#0000ff"
                        />
                        <DashboardCard2
                            title="Payment Make"
                            description="Pagos realizados de Hotmy al Hotmer"
                            number={dashboardInfo.count_payment_make}
                            backgroundColor="#0a236b"
                            color="#91adff"
                        />
                        <DashboardCard2
                            title="Subscriptions"
                            description="Cantidad de Suscripciones Vendidas"
                            number={dashboardInfo.count_subscription_sold}
                            backgroundColor="#0e619c"
                            color="#a1d6fb"
                        />
                        <DashboardCard2
                            title="Packs"
                            description="Cantidad de Packs Vendidas"
                            number={dashboardInfo.count_pack_sold}
                            backgroundColor="#0e619c"
                            color="#a1d6fb"
                        />
                        <DashboardCard2
                            title="Tips"
                            description="Cantidad de Tips dados por los usuarios"
                            number={dashboardInfo.count_tips_sended}
                            backgroundColor="#0e619c"
                            color="#a1d6fb"
                        />
                        <DashboardCard2
                            title="Profit Local Ars" //TODO LO QUE SEA PROFIT RESPETAR COLORIMETRIA
                            description="Sumatoria de Plata en Pesos Argentinos"
                            number={dashboardInfo.count_local_profit_ars}
                            backgroundColor="#ECF0F1"
                            color="#0E6251"
                        />

                        <DashboardCard2
                            title="Profit Local Subscriptions" //TODO LO QUE SEA PROFIT RESPETAR COLORIMETRIA
                            description="Plata ARS de Venta de Suscripciones"
                            number={dashboardInfo.count_local_profit_ars_subscriptions}
                            backgroundColor="#ECF0F1"
                            color="#0E6251"
                        />
                        <DashboardCard2
                            title="Profit Local Pack" //TODO LO QUE SEA PROFIT RESPETAR COLORIMETRIA
                            description="Plata ARS de Venta de Packs"
                            number={dashboardInfo.count_local_profit_ars_packs}
                            backgroundColor="#ECF0F1"
                            color="#0E6251"
                        />
                        <DashboardCard2
                            title="Profit Local Tip" //TODO LO QUE SEA PROFIT RESPETAR COLORIMETRIA
                            description="Plata ARS de Venta de Tips"
                            number={dashboardInfo.count_local_profit_ars_tips}
                            backgroundColor="#ECF0F1"
                            color="#0E6251"
                        />
                        <DashboardCard2
                            title="Profit International" //TODO LO QUE SEA PROFIT RESPETAR COLORIMETRIA
                            description="Total International Solds"
                            number={dashboardInfo.count_international_profit}
                            backgroundColor="#D5F5E3"
                            color="#0E6251"
                        />
                        <DashboardCard2
                            title="Profit International Subscriptions" //TODO LO QUE SEA PROFIT RESPETAR COLORIMETRIA
                            description="Total International Solds for Subscriptions"
                            number={dashboardInfo.count_International_profit_ars_subscriptions}
                            backgroundColor="#D5F5E3"
                            color="#0E6251"
                        />
                        <DashboardCard2
                            title="Profit International Pack" //TODO LO QUE SEA PROFIT RESPETAR COLORIMETRIA
                            description="Total International Solds for Packs"
                            number={dashboardInfo.count_International_profit_ars_packs}
                            backgroundColor="#D5F5E3"
                            color="#0E6251"
                        />
                        <DashboardCard2
                            title="Profit International Pack" //TODO LO QUE SEA PROFIT RESPETAR COLORIMETRIA
                            description="Total de Packs vendidos en Moneda Dolar USD"
                            number={dashboardInfo.count_International_profit_ars_tips}
                            backgroundColor="#D5F5E3"
                            color="#0E6251"
                        />

                    </div>
                    :
                    <h1 className="p-text-center">Dashboard Not found</h1>
            }
        </div>
    )
}

export default Dashboard;