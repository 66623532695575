import React from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';


const DialogPPendingDetail = ({ payment, show, setShow }) => {
    
    const closeDialog = () => {
        setShow(false);
    };
    
    const renderFooter = () => {
        return <Button
            label="OK" icon="pi pi-check" className={`p-button-info`} autoFocus
            onClick={() => {
                closeDialog();
            }}
        />
    }

    return (
        <>

            <Dialog
                className="p-col-12 p-md-8"
                visible={show} onHide={closeDialog}
                position="center"
                draggable={false} resizable={false} baseZIndex={1000}
                footer={renderFooter}
            >
                {payment?
                    <h1>PUTO ACA NO HAY NADA QUE BUSCAS?</h1>
                    :
                    <h1 className="p-text-center">Payment not found</h1>
                }

            </Dialog>
        </>
    )
};

export default DialogPPendingDetail;