import React, { useEffect, useRef } from 'react';
import * as actionsStatsList from '../../../../components/dropdowns/stats/_redux/statsActions';
import * as  actionsHotmersList from '../_redux/hotmesrListActions'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { difference } from '../../../../functions/objectFunctions';
import DropdownHair from '../../../../components/dropdowns/stats/DropdownHair';
import DropdownChest from '../../../../components/dropdowns/stats/DropdownChest';
import DropdownHeight from '../../../../components/dropdowns/stats/DropdownHeight';
import DropdownWeight from '../../../../components/dropdowns/stats/DropdownWeight';
import DropdownEyes from '../../../../components/dropdowns/stats/DropdownEyes';
import DropdownSkin from '../../../../components/dropdowns/stats/DropdownSkin';

const Stats = ({ id }) => {
    const toast = useRef(null);
    const dispatch = useDispatch();
    const { statsList, error } = useSelector(
        state => state.stats,
        shallowEqual
    );
    const { hotmerDetail, actionsLoading } = useSelector(
        state => state.hotmersList,
        shallowEqual
    );
    useEffect(() => {
        if (!statsList) {
            dispatch(actionsStatsList.fetchStatsList({}));
        };
    }, [statsList, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(actionsStatsList.clear({}));
        }
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Hotmers', detail: error });
        };
    }, [error]);

    const initialValues = {
        bl_tatoo: hotmerDetail.stat && hotmerDetail.stat.bl_tatoo ? hotmerDetail.stat.bl_tatoo : false,
        bl_piercing: hotmerDetail.stat && hotmerDetail.stat.bl_piercing ? hotmerDetail.stat.bl_piercing : false,
        id_type_of_chest: 0,
        id_type_of_height: 0,
        id_type_of_eye_color: 0,
        id_type_of_skin_tone: 0,
        id_type_of_hair: 0,
        id_type_of_weight: 0,
    };
    const validationSchema = Yup.object({});
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                const result = difference(initialValues, values);
                const result2 = [];
                for (const property in result) {
                    result2.push({ key: property, value: result[property] })
                };
                // console.log(result2)
                if (result2.length > 0) {
                    dispatch(actionsHotmersList.editHotmer(id, { stat: result2 }));
                };
                setSubmitting(false);
            }}
        >
            {formik =>
                <Form>
                    <Toast ref={toast} position="bottom-right" />
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-d-flex p-flex-row p-ai-center p-mb-5 p-col-12 p-md-6">
                            <div className="p-d-flex p-flex-row p-ai-center">
                                <h3 htmlFor="bl_tatoo" className="p-pt-3 p-pr-2">Tatoo </h3>
                                <InputSwitch checked={formik.values.bl_tatoo} onChange={e => formik.setFieldValue('bl_tatoo', !formik.values.bl_tatoo)} />
                            </div>
                            <small className="p-error p-d-block"><ErrorMessage name="bl_tatoo" /></small>
                        </div>
                        <div className="p-field p-d-flex p-flex-row p-ai-center p-mb-5 p-col-12 p-md-6">
                            <div className="p-d-flex p-flex-row p-ai-center">
                                <h3 htmlFor="bl_piercing" className="p-pt-3 p-pr-2">Piercing </h3>
                                <InputSwitch checked={formik.values.bl_piercing} onChange={e => formik.setFieldValue('bl_piercing', !formik.values.bl_piercing)} />
                            </div>
                            <small className="p-error p-d-block"><ErrorMessage name="bl_piercing" /></small>
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-mb-4">
                            <label htmlFor="id_type_of_chest">Chest</label>
                            <DropdownChest
                                select={hotmerDetail.stat ? hotmerDetail.stat.ds_name_type_of_chest : undefined}
                                selected={formik.values.id_type_of_chest}
                                setSelected={value => formik.setFieldValue('id_type_of_chest', value)}
                            />
                            <small className="p-error p-d-block"><ErrorMessage name="id_type_of_chest" /></small>
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-mb-4">
                            <label htmlFor="id_type_of_height">Height</label>
                            <DropdownHeight
                                select={hotmerDetail.stat ? hotmerDetail.stat.ds_name_type_of_height : undefined}
                                selected={formik.values.id_type_of_height}
                                setSelected={value => formik.setFieldValue('id_type_of_height', value)}
                            />
                            <small className="p-error p-d-block"><ErrorMessage name="id_type_of_height" /></small>
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-mb-4">
                            <label htmlFor="id_type_of_eye_color">Eyes</label>
                            <DropdownEyes
                                select={hotmerDetail.stat ? hotmerDetail.stat.ds_name_type_of_eye_color : undefined}
                                selected={formik.values.id_type_of_eye_color}
                                setSelected={value => formik.setFieldValue('id_type_of_eye_color', value)}
                            />
                            <small className="p-error p-d-block"><ErrorMessage name="id_type_of_eye_color" /></small>
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-mb-4">
                            <label htmlFor="id_type_of_skin_tone">Skin</label>
                            <DropdownSkin
                                select={hotmerDetail.stat ? hotmerDetail.stat.ds_name_type_of_skin_tone : undefined}
                                selected={formik.values.id_type_of_skin_tone}
                                setSelected={value => formik.setFieldValue('id_type_of_skin_tone', value)}
                            />
                            <small className="p-error p-d-block"><ErrorMessage name="id_type_of_skin_tone" /></small>
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-mb-4">
                            <label htmlFor="id_type_of_hair">Hair</label>
                            <DropdownHair
                                select={hotmerDetail.stat ? hotmerDetail.stat.ds_name_type_of_hair : undefined}
                                selected={formik.values.id_type_of_hair}
                                setSelected={value => formik.setFieldValue('id_type_of_hair', value)}
                            />
                            <small className="p-error p-d-block"><ErrorMessage name="id_type_of_hair" /></small>
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-mb-4">
                            <label htmlFor="id_type_of_weight">Weight</label>
                            <DropdownWeight
                                select={hotmerDetail.stat ? hotmerDetail.stat.ds_name_type_of_weight : undefined}
                                selected={formik.values.id_type_of_weight}
                                setSelected={value => formik.setFieldValue('id_type_of_weight', value)}
                            />
                            <small className="p-error p-d-block"><ErrorMessage name="id_type_of_weight" /></small>
                        </div>
                    </div>
                    <div className="p-d-flex p-jc-end p-mb-2">
                        <div>
                            <Button icon={`pi ${actionsLoading ? `pi-spin pi-spinner` : `pi-check`}`}
                                label="Save" className="p-button-info p-m-3" type="submit"
                                disabled={!formik.dirty || actionsLoading}
                            />
                        </div>
                    </div>
                </Form>
            }
        </Formik >
    )
};

export default Stats
