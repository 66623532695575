import axios from 'axios';
import { URL_BALANCE } from './config';

export async function getbHotmerList() {
    const result = await axios.get(URL_BALANCE);
    return result.data.data;
};


export async function getbHotmerDetails(id) {
    const result = await axios.get(`${URL_BALANCE}/${id}`);
    return result.data.data;
};
