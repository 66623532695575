import axios from 'axios';
import { URL_LOGIN,URL_LOGOUT } from './config';

export async function getSession(data) {
    const result = await axios.post(URL_LOGIN, data);
    return result.data;
};

export async function closeSession() {
    const result = await axios.post(URL_LOGOUT);
    return result.data;
};