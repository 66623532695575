import axios from 'axios';
import { URL_PACK_STATUS_LIST } from './config';

/*export async function getCountriesList() {
    const result = await axios.get(URL_COUNTRIES_LIST);
    return result.data.data;
};*/

export async function getPackStatusList() {
    const result = await axios.get(URL_PACK_STATUS_LIST);
    return result.data.data;
};

