import * as requestFromServer from './usersListCrud'
import { usersListSlice, callTypes } from './usersListSlice';

const { actions } = usersListSlice;

export const fetchUsersList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getUsersList(data)
        .then(response => {
            const usersList = response;
            dispatch(actions.usersListFetched({ usersList }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting user list", callType: callTypes.list }));
        });
};

export const fetchUsersDetail = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getUserDetail(id)
        .then(response => {
            const userDetail = response;
            dispatch(actions.userDetailFetched({ userDetail }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting user detail", callType: callTypes.list }));
        });
};

export const editUser = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .userEdit(data)
        .then(response => {
            if (response && response.status === "Success") {
                dispatch(actions.catchSuccess({ success: "Successful user edit", callType: callTypes.action }));
                dispatch(actions.usersListFetched({}));
            } else {
                dispatch(actions.catchError({ error: "Failed to edit user", callType: callTypes.action }));
            };
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Failed to edit user", callType: callTypes.action }));
        });
};

export const deleteUser = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .userDelete(id)
        .then(response => {
            if (response && response.status === "Success") {
                dispatch(actions.deleteUSer({ success: "User deleted", id, callType: callTypes.action }));
            } else {
                dispatch(actions.catchError({ error: "Failed to delete user", callType: callTypes.action }));
            };
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Failed to delete user", callType: callTypes.action }));
        });
};

export const clear = id => dispatch => {
    dispatch(actions.clear());
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
