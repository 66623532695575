import * as requestFromServer from './PacksStatusCrud'
import { packsStatusListSlice, callTypes } from './PacksStatusSlice';

const { actions } = packsStatusListSlice;

export const fetchPackStatusList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getPackStatusList(data)
        .then(response => {
            const packStatusList = response;
            dispatch(actions.packStatusListFetched({ packStatusList }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting Status Pack", callType: callTypes.list }));
        });
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
