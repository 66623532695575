import * as requestFromServer from './documentsCrud'
import { documentsListSlice, callTypes } from './documentsSlice';

const { actions } = documentsListSlice;

export const fetchDocumentsList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getDocumentsList(data)
        .then(response => {
            const documentsList = response;
            dispatch(actions.documentsListFetched({ documentsList }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting documents list", callType: callTypes.list }));
        });
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
