import React, { useEffect, useRef, useState, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AuthContext } from '../../../../auth/provider/authProvieder';
import * as actionspPacks from './_redux/pPacksActions';
import { BreadCrumb } from 'primereact/components/breadcrumb/BreadCrumb';
import { Link } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import DialogPpacksDetails from '../../../components/dialogs/DialogPpacksDetails';

//import DialogPCancelSubscription from '../../../components/dialogs/DialogPCancelSubscriptionPreview';  /// esto lo 
import { commonTemplate, dateTemplate, moneyTemplate, commonTemplateGreen } from '../../../components/dialogs/dataTableTemplates';
import { filterOptions } from '../../../functions/arrayFunctions'

const PacksList = () => {
    const [selectedItem, setSelectedItem] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');
    const [showDialogDetail, setshowDialogDetail] = useState(false);
    const [countVideofilter, setcountVideofilter] = useState('');
    const [countFollowfilter, setcountFollowfilter] = useState('');
    const [packSellfilter, setpackSellfilter] = useState('');
    const [iduserdestinyfilter, setiduserdestinyfilter] = useState('');
    const [iduserfilter, setiduserfilter] = useState('');
    const [idprofitFilter, setidprofitFilter] = useState('');
    const [vl_scoreFilter, setvl_scoreFilter] = useState('');
    const [vl_feeprofitFilter, setvl_feeProfitFilter] = useState('');
    const [insertDateFilter, setInsertDateFilter] = useState('');
    const [packsTitlefilter, setPacksTitlefilter] = useState('');
    const [agremmentitlefilter, setAgreementTitlefilter] = useState('');
    const [selectedStatus, setselectedStatus] = useState(null);
    const [selectedActiveInSearch, setselectedActiveInSearch] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const { ispPacksAuthorized } = useContext(AuthContext);
    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    const breadcrumbItems = [
        { label: 'Content' },
        { label: 'Pack List' },
    ];
    const dispatch = useDispatch();
    const { pPacks, pPacksDetails, listLoading, actionsLoading, error, success } = useSelector(
        state => state.pPacks,
        shallowEqual
    );

    useEffect(() => {
        if (!pPacks) {
            dispatch(actionspPacks.fetchPacksList({}));
        };
    }, [pPacks, dispatch]);

    //TOAST DETAIL NOT FOUND
    useEffect(() => {
        if (!listLoading && !pPacksDetails && showDialogDetail) {
            toast.current.show({ severity: 'warn', summary: 'Packs', detail: 'Packs detaill not found' });
        }
    }, [listLoading, pPacksDetails, showDialogDetail]);

    //ERROR TOAST
    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Packs', detail: error });
        };
    }, [error]);

    //SUCCESS TOAST
    useEffect(() => {
        if (success) {
            toast.current.show({ severity: 'success', summary: 'Packs', detail: success });
        };
    }, [success]);

    useEffect(() => {
        return () => {
            setSelectedItem('');
            setshowDialogDetail(false);
            dispatch(actionspPacks.clearAll({}));
        }
    }, [dispatch]);

    const leftToolbarTemplate = () => {
        return (
            <>
                <Button label="Reload" icon="pi pi-refresh" className="p-button-warning p-ml-2"
                    onClick={() => {
                        dispatch(actionspPacks.fetchPacksList({}));
                    }}
                    disabled={listLoading || actionsLoading}
                />
            </>
        )
    };

    const rightToolbarTemplate = () => {
        return (
            <>
                <Button label="Export" icon="pi pi-upload" className="p-button-help p-ml-2"
                    disabled={listLoading || actionsLoading} /*onClick={exportCSV}*/
                    onClick={() => dt.current.exportCSV()}
                />
            </>
        )
    };
    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Pack List</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    ///  DEFINIMOS LOS BOTONES DE ACCION.
    const actionBodyTemplate = (rowdata) => {
        return (
            <>
                {ispPacksAuthorized(1) &&
                    <Button tooltip="Details" icon="pi pi-eye" className="p-button-rounded p-button-info p-mr-2"
                        onClick={() => {
                            dispatch(actionspPacks.fetchPacksDetails(rowdata.id_pack));  /// ESTO NO FUNCIONA
                            setshowDialogDetail(true);
                        }}
                    />
                }
                {/*ispPacksAuthorized(2) &&  //PERMISO 2
                    <Button tooltip="Edit Pack" icon="pi pi-exclamation-circle" className="p-button-rounded p-button-danger p-mr-2"
                        onClick={() => {
                            setSelectedItem(rowdata);
                            setShowDialogMake(true);  /// CAMBIAR EL DIALOG POR OTRA COSA SI QUIERN EDITAR PACK.
                        }}
                    />
                    */}
                {ispPacksAuthorized(2) &&
                    <Link to={`/content/packsList/edit/${rowdata.id_pack}`}>
                        <Button tooltip="Edit Pack" icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-1"
                        />
                    </Link>
                }

            </>
        );
    };
    //FILTERS


    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <img 
                src={rowData.ds_url_thumbnail} 
                alt={rowData.image} 
                className="product-image"
                width= '130px'
                height='130px'
                />
            </>
        )
    }

    ///FILTRO NORMAL PARA VL_FEE
    const vl_feeprofitFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'vl_price', 'contains');
        setvl_feeProfitFilter(e.currentTarget.value);
    };
    const vl_feeprofitFilterCustom =
        <InputText
            value={vl_feeprofitFilter}
            placeholder="$"
            onChange={vl_feeprofitFilterChange}
            style={{ width: "100%" }}
        />;

    ///FILTRO NORMAL PARA VL_FEE
    const vl_scoreFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'vl_score', 'contains');
        setvl_scoreFilter(e.currentTarget.value);
    };
    const vl_scoreFilterCustom =
        <InputText
            value={vl_scoreFilter}
            onChange={vl_scoreFilterChange}
            style={{ width: "100%" }}
        />;

    const insertDateFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'dt_insert', 'contains');
        setInsertDateFilter(e.currentTarget.value);
    };
    const insertDateFilterCustom =
        <InputText
            value={insertDateFilter}
            placeholder="Date"
            onChange={insertDateFilterChange}
            style={{ width: "100%" }}
        />;

    const packTitleFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'ds_title', 'contains');
        setPacksTitlefilter(e.currentTarget.value);
    };


    const agremmentTitleFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'agreement_commercial_data', 'contains');
        setAgreementTitlefilter(e.currentTarget.value);
    };

    const packLink = (rowData) => {
        return (
            <div className="p-d-flex p-ai-center">
                <a href={`https://hotmy.app/packView/${rowData.id_hotmer}/${rowData.id_pack}`}
                    target='_blank'
                    without rel="noreferrer"
                    style={{ color: 'white' }}
                >
                    <Button
                        tooltip={`https://hotmy.app/packView/${rowData.id_hotmer}/${rowData.id_pack}`}
                        icon="pi pi-send"
                        className="p-button-rounded p-mr-1"
                        style={{ color: 'white' }}
                    />
                </a>
            </div>
        )
    };


    const packTitleFilterCustom =
        <InputText
            value={packsTitlefilter}
            placeholder="Title"
            onChange={packTitleFilterChange}
            style={{ width: "100%" }}
        />;

        const agrementTitleFilterCustom =
        <InputText
            value={agremmentitlefilter}
            placeholder="Acuerdo"
            onChange={agremmentTitleFilterChange}
            style={{ width: "100%" }}
        />;

    //count_image
    const iduserdestinyFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'count_image', 'contains');
        setiduserdestinyfilter(e.currentTarget.value);
    };
    const iduserdestinyFilterCustom =
        <InputText
            value={iduserdestinyfilter}
            placeholder="Quantity"
            onChange={iduserdestinyFilterChange}
            style={{ width: "100%" }}
        />;

    const iduserFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'id_hotmer', 'startsWith');
        setiduserfilter(e.currentTarget.value);
    };
    const iduserFilterCustom =
        <InputText
            value={iduserfilter}
            placeholder="Hotmer"
            onChange={iduserFilterChange}
            style={{ width: "100%" }}
        />;

    const countVideoFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'count_video', 'contains');
        setcountVideofilter(e.currentTarget.value);
    };
    const countVideoFilterCustom =
        <InputText
            value={countVideofilter}
            placeholder="Quantity"
            onChange={countVideoFilterChange}
            style={{ width: "100%" }}
        />;

    const countFollowFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'follow_pack', 'contains');
        setcountFollowfilter(e.currentTarget.value);
    };
    const countFollowFilterCustom =
        <InputText
            value={countFollowfilter}
            placeholder="Quantity"
            onChange={countFollowFilterChange}
            style={{ width: "100%" }}
        />;

    const packSellFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'count_seller_of_pack', 'contains');
        setpackSellfilter(e.currentTarget.value);
    };
    const packSellFilterCustom =
        <InputText
            value={packSellfilter}
            placeholder="Ventas"
            onChange={packSellFilterChange}
            style={{ width: "100%" }}
        />;

    const idprofitFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'count_image', 'contains');
        setidprofitFilter(e.currentTarget.value);
    };

    //id_profit
    const idprofitFilterCustom =
        <InputText
            value={idprofitFilter}
            placeholder="Nº"
            onChange={idprofitFilterChange}
            style={{ width: "100%" }}
        />;


    /*
     const [selectedStatus, setselectedStatus] = useState(null);
        const [selectedPayed, setselectedPayed] = useState(null);
        const [selectedUserCancel, setselectedUserCancel] = useState(null);
    */

    // PAGADO 
    const ActiveInSearchFilterChange = ({ value }) => {
        dt.current.filter(value, 'bl_active_in_search', 'in');
        setselectedActiveInSearch(value)
    };
    const ActiveInSearchFilterCustom =
        <MultiSelect style={{ width: "100%" }} className="p-column-filter" value={selectedActiveInSearch} options={filterOptions(pPacks, "bl_active_in_search")}
            onChange={ActiveInSearchFilterChange} placeholder="All"
        />;

    const nameFilterChange = ({ value }) => {
        dt.current.filter(value, 'ds_name_status', 'in');
        setselectedStatus(value)
    };
    const nameFilterCustom =
        <MultiSelect style={{ width: "100%" }} className="p-column-filter" value={selectedStatus} options={filterOptions(pPacks, "ds_name_status")}
            onChange={nameFilterChange} placeholder="All"
        />;

    const nickNameTemplate = (rowData) => {
        // return <Chip label={rowData.ds_full_name} image={rowData.ds_profile_photo_thumbnail_path}  />
        return (
            <div className="p-d-flex p-ai-center">
                <Avatar image={rowData.ds_profile_photo_thumbnail_path} className="p-mr-2" size="small" shape="circle" />
                <a href={`https://hotmy.app/${rowData.ds_nick_name}`} target='_blank' without rel="noreferrer">{rowData.ds_nick_name} </a>
            </div>
        )
    };


    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12 card">
                <Toast ref={toast} position="bottom-right" onClose={() => setshowDialogDetail(false)} />
                <BreadCrumb className="p-mb-2" home={breadcrumbHome} model={breadcrumbItems} />
                {listLoading && !pPacks ?
                    <div className="p-d-flex p-jc-center">
                        <ProgressSpinner />
                    </div>
                    :
                    <>
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
                        <DataTable ref={dt}
                            value={pPacks}
                            selection={selectedItem} onSelectionChange={(e) => setSelectedItem(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[ 10, 25, 50, 75, 100, 200]}
                            className="p-datatable-customers p-datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Packs"
                            loading={listLoading || actionsLoading}
                            globalFilter={globalFilter} emptyMessage="Not packs found." header={header}>
                            {/*<Column headerStyle={{ width: '8%' }} className="p-text-center" field="id_transaction_subscription_profit_detail" header="Order" sortable body={e => commonTemplate(e.id_transaction_subscription_profit_detail)} filter filterElement={orderFilterCustom} />*/}
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="id_pack" header="id_pack" sortable body={e => commonTemplate(e.id_pack)} filter filterElement={idprofitFilterCustom} />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="id_hotmer" header="id Hotmer" sortable body={e => commonTemplate(e.id_hotmer)} filter filterElement={iduserFilterCustom} />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="Link" header="PackLink" sortable body={packLink} filter filterPlaceholder="PackLink" />
                            <Column headerStyle={{ width: '10%' }} className="p-text-center" field="ds_display_name" header="Nick" sortable body={nickNameTemplate} filter filterPlaceholder="Nick" />
                            <Column headerStyle={{ width: '20%' }} className="p-text-center" field="ds_url_thumbnail" header="Portada" sortable body={imageBodyTemplate} filter filterPlaceholder="Portada" />
                            <Column headerStyle={{ width: '12%' }} className="p-text-center" field="agremment" header="Acuerdo" sortable body={e => commonTemplate(e.agreement_commercial_data)}  filter filterElement={agrementTitleFilterCustom} />
                            <Column headerStyle={{ width: '10%' }} className="p-text-center" field="ds_title" header="Title" sortable body={e => commonTemplate(e.ds_title)} filter filterElement={packTitleFilterCustom} />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="vl_price" header="Price" sortable body={e => moneyTemplate(e.vl_price)} filter filterElement={vl_feeprofitFilterCustom} />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="vl_score" header="Score" sortable body={e => commonTemplate(e.vl_score)} filter filterElement={vl_scoreFilterCustom} />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="bl_active_in_search" header="Active in Search" sortable body={e => commonTemplate(e.bl_active_in_search)} filter filterElement={ActiveInSearchFilterCustom} />
                            <Column headerStyle={{ width: '4%' }} className="p-text-center" field="count_image" header="Qty Images" sortable body={e => commonTemplate(e.count_image)} filter filterElement={iduserdestinyFilterCustom} />
                            <Column headerStyle={{ width: '4%' }} className="p-text-center" field="count_video" header="Qty Videos" sortable body={e => commonTemplate(e.count_video)} filter filterElement={countVideoFilterCustom} />
                            <Column headerStyle={{ width: '4%' }} className="p-text-center" field="Follow" header="Follow" sortable body={e => commonTemplate(e.follow_pack)} filter filterElement={countFollowFilterCustom} />
                            <Column headerStyle={{ width: '4%' }} className="p-text-center" field="count_seller_of_pack" header="Sells" sortable body={e => commonTemplateGreen(e.count_seller_of_pack)} filter filterElement={packSellFilterCustom} />
                            <Column headerStyle={{ width: '6%' }}  className="p-text-center" field="ds_name_status" header="Status" sortable body={e => commonTemplate(e.ds_name_status)} filter filterElement={nameFilterCustom} />
                            {/*<Column headerStyle={{ width: '6%' }} className="p-text-center" field="id_user_of_origin" header="id User" sortable body={e => commonTemplate(e.id_user_of_origin)} filter filterElement={iduseroriginFilterCustom} />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="id_user_of_destination" header="id Hotmer" sortable body={e => commonTemplate(e.id_user_of_destination)} filter filterElement={iduserdestinyFilterCustom} />
                            <Column className="p-text-center" field="dt_month_start" header="Date Start" sortable body={e => dateTemplate(e.dt_month_start)} filter filterElement={DesdeDateFilterCustom} />
                            <Column className="p-text-center" field="dt_month_finish" header="Date Finish" sortable body={e => dateTemplate(e.dt_month_finish)} filter filterElement={HastaDateFilterCustom} />
                            <Column className="p-text-center" field="vl_month" header="Mes Actual/Mes Total" sortable body={e => commonTemplate(e.vl_month)} filter filterElement={methodFilter} />
                            <Column className="p-text-center" field="ds_name_status" header="Status" sortable body={e => commonTemplate(e.ds_name_status)} filter filterElement={nameFilterCustom} /> 
                            <Column className="p-text-center" field="bl_payed" header="Mes Pagado a Hotmer" sortable body={e => commonTemplate(e.bl_payed)} filter filterElement={PayedFilterCustom} />
                            <Column className="p-text-center" field="bl_user_cancel" header="Usuario Cancelo" sortable body={e => commonTemplate(e.bl_user_cancel)} filter filterElement={UserCancelFilterCustom} />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="id_profit_account" header="id Profit" sortable body={e => commonTemplate(e.id_profit_account)} filter filterElement={idprofitFilterCustom} />*/}
                            <Column headerStyle={{ width: '8%' }} className="p-text-center" field="dt_insert" header="Insert" sortable body={e => dateTemplate(e.dt_insert)} filter filterElement={insertDateFilterCustom} />
                            <Column headerStyle={{ width: '5%' }} field="action" header="Actions" sortable body={actionBodyTemplate} />
                        </DataTable>
                    </>
                }
            </div>
            <DialogPpacksDetails
                pack={pPacksDetails}
                show={!listLoading && pPacksDetails && showDialogDetail}
                setShow={setshowDialogDetail}
            />
        </div>
    )
}

export default PacksList;