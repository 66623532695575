import React, { useState, useEffect, useRef } from 'react'
import * as actionsHotmersList from './_redux/hotmesrListActions';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/components/breadcrumb/BreadCrumb';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import Profile from './editComponents/Profile';
import Stats from './editComponents/Stats';
import Identity from './editComponents/Identity';



const HotmerEdit = (props) => {
    const toast = useRef(null);
    const dispatch = useDispatch();
    const { hotmerDetail, listLoading, actionsLoading, error, success } = useSelector(
        state => state.hotmersList,
        shallowEqual
    );

    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    const breadcrumbItems = [
        { label: 'Users' },
        { label: 'Hotmer list', url: '/users/hotmerslist' },
        { label: 'Edit' },
    ];

    useEffect(() => {
        if (!hotmerDetail) {
            dispatch(actionsHotmersList.fetchHotmerDetail(props.match.params.id));
        };
    }, [hotmerDetail, dispatch, props.match.params.id]);

    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'User detail', detail: error });
        };
    }, [error]);

    useEffect(() => {
        if (success) {
            toast.current.show({ severity: 'success', summary: 'Hotmers', detail: success });
        };
    }, [success]);

    useEffect(() => {
        return () => {
            dispatch(actionsHotmersList.clear({}));
        };
    }, [dispatch]);

    const leftToolbarTemplate = () => {
        return (
            <>
                <Button label="Reload" icon="pi pi-refresh" className="p-button-warning p-ml-2"
                    onClick={() => {
                        dispatch(actionsHotmersList.fetchHotmerDetail(props.match.params.id));
                    }}
                    disabled={listLoading || actionsLoading}
                />
            </>
        )
    };

    const items = [
        { label: 'Profile', icon: 'pi pi-info-circle', component: <Profile id={props.match.params.id} />, position: 0 },
        { label: 'Stat', icon: 'pi pi-tag', component: <Stats id={props.match.params.id} />, position: 1 },
        { label: 'Identity', icon: 'pi pi-id-card', component: <Identity id={props.match.params.id} />, position: 2 },
    ];
    const [activeItem, setActiveItem] = useState(0);
    return (
        <div className="card">
            <BreadCrumb className="p-mb-2" home={breadcrumbHome} model={breadcrumbItems} />
            <Toast ref={toast} position="bottom-right" />
            <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>
            <TabMenu model={items} activeItem={items[activeItem]} onTabChange={e => setActiveItem(e.value.position)} />
            {!listLoading ?
                hotmerDetail ?
                    <div className="p-m-3">{items[activeItem].component}</div>
                    :
                    <h3>User detail not found</h3>
                :
                <div className="p-d-flex p-jc-center">
                    <ProgressSpinner />
                </div>
            }
        </div>
    )
}

export default HotmerEdit;