export const userActions = [
    "user.index",//0
    "user.show",//1
    "user.update",//2
    "user.delete",//3
    "user.reset-password",//4
];

export const hotmerActions = [
    "hotmer.index",//0
    "hotmer.show",//1
    "hotmer.update",//2
    "hotmer.delete",//3
    "hotmer.validate",//4
    "hotmer.reset-password",//5
];

export const agencyActions = [
    "agency.agencyList",//0
    "agency.show",//0
];

export const agreementActions = [
    "agreement.collaborationList",
    "agreement.commercialList",//1
];

export const packActions = [
    "content.pack.packList",//0
    "content.pack.packListDetails",//1
    "content.pack.packUpdate",//2
    //"content.pack.delete",//3
];

export const coverActions = [
    "content.cover.coverList",//0
    "content.cover.coverListDetails",//1
    "content.cover.coverUpdate",//2
    //"content.pack.delete",//3
];

export const paymentsPendingActions = [
    "financial.payments.list",//0
    "financial.payments.pending.details",//1
    "financial.payments.make",//2
    "financial.payments.made",//3
];

export const paymentsSubscriptionsActions = [
    "financial.payments.subscriptions",//0
    "financial.payments.subscriptions.details",//1
    "financial.payments.cancel",//2
];

export const paymentListActions = [
    "financial.payments.list",//0
    "financial.payments.details",//1
];

export const paymentsMadeActions = [
    "financial.payments.made",//0
];

export const balanceHotmerActions = [
    "financial.balance.hotmer",//0
    "financial.balance.hotmer.details",//1
];

export const dashboardActions = [
    "dashboard.index",//0
    "dashboard.count-users",//1
    "dashboard.count-hotmers",//2
    "dashboard.count-pack-sold",//3
    "dashboard.count-subscription-sold",//4
    "dashboard.count-tips-sended",//5
    "dashboard.count-hotmer-pending-validation",//6
    "dashboard.count-hotmer-rejected",//7
    "dashboard.count-payment-pending",//8
]