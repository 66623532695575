import React, { useEffect, useRef, useState, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AuthContext } from '../../../../auth/provider/authProvieder';
import * as actionsPPendings from './_redux/pPendingsActions';
import { BreadCrumb } from 'primereact/components/breadcrumb/BreadCrumb';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Avatar } from 'primereact/avatar';
import DialogPPendingDetail from '../../../components/dialogs/DialogPPendingDetail';
import DialogPPendingMake from '../../../components/dialogs/DialogPPendingMake';
import { commonTemplate, dateTemplate, moneyTemplateGreen, moneyTemplate } from '../../../components/dialogs/dataTableTemplates';
import { filterOptions } from '../../../functions/arrayFunctions'

const PaymentsPending = () => {
    const [selectedUser, setSelectedUser] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');
    const [showDialogDetail, setshowDialogDetail] = useState(false);
    const [showDialogMake, setShowDialogMake] = useState(false);
    const [namefilter, setNamefilter] = useState('');
    const [accountMailfilter, setAccountMailfilter] = useState('');
    const [userMailfilter, setUserMailfilter] = useState('');
    const [nickNamefilter, setNickNamefilter] = useState('');
    const [orderFilter, setOrderFilter] = useState('');
    const [withdrawFilter, setWithdrawFilter] = useState('');
    const [vl_feeprofitFilter, setvl_feeProfitFilter] = useState('');
    const [insertDateFilter, setInsertDateFilter] = useState('');
    const [expirationDateFilter, setExpirationDateFilter] = useState('');
    const [selectedCurrenDestination, setselectedCurrenDestination] = useState(null);
    const [selectedCurrentOrigen, setSelectedCurrentOrigen] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const { isPPendingAuthorized } = useContext(AuthContext);
    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    const breadcrumbItems = [
        { label: 'Financial' },
        { label: 'Payments Pending' },
    ];
    const dispatch = useDispatch();
    const { pPendings, pPendingDetails, listLoading, actionsLoading, error, success } = useSelector(
        state => state.pPendings,
        shallowEqual
    );

    useEffect(() => {
        if (!pPendings) {
            dispatch(actionsPPendings.fetchPendingsList({}));
        };
    }, [pPendings, dispatch]);

    //TOAST DETAIL NOT FOUND
    useEffect(() => {
        if (!listLoading && !pPendingDetails && showDialogDetail) {
            toast.current.show({ severity: 'warn', summary: 'Payments', detail: 'Payment detaill not found' });
        }
    }, [listLoading, pPendingDetails, showDialogDetail]);

    //ERROR TOAST
    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Payments', detail: error });
        };
    }, [error]);

    //SUCCESS TOAST
    useEffect(() => {
        if (success) {
            toast.current.show({ severity: 'success', summary: 'Payments', detail: success });
        };
    }, [success]);

    useEffect(() => {
        return () => {
            setSelectedUser('');
            setshowDialogDetail(false);
            dispatch(actionsPPendings.clearAll({}));
        }
    }, [dispatch]);

    const leftToolbarTemplate = () => {
        return (
            <>
                <Button label="Reload" icon="pi pi-refresh" className="p-button-warning p-ml-2"
                    onClick={() => {
                        dispatch(actionsPPendings.fetchPendingsList({}));
                    }}
                    disabled={listLoading || actionsLoading}
                />
            </>
        )
    };

    const rightToolbarTemplate = () => {
        return (
            <>
                <Button label="Export" icon="pi pi-upload" className="p-button-help p-ml-2"
                    disabled={listLoading || actionsLoading} /*onClick={exportCSV}*/
                    onClick={() => dt.current.exportCSV()}
                />
            </>
        )
    };
    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Financial Pendings</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const cDestinationTemplate = (rowData) => {
        return (
            <Button
                tooltip={`Conversion ${rowData.bl_conversion ? "aproved" : "rejected"}`}
                label={rowData.ds_currency_destination}
                //icon={`pi ${rowData.bl_conversion ? "pi-check" : "pi-times"}`}
                className={`p-button-sm p-button-${rowData.bl_conversion ? "success" : "secondary"}`}
            />
        )
    };
    const actionBodyTemplate = (rowdata) => {
        return (
            <>
                {isPPendingAuthorized(1) &&
                    <Button tooltip="Details" icon="pi pi-eye" className="p-button-rounded p-button-info p-mr-2"
                        onClick={() => {
                            dispatch(actionsPPendings.fetchPendingDetails(rowdata.id_extraction_order));
                            setshowDialogDetail(true);
                        }}
                    />
                }
                {isPPendingAuthorized(2) &&
                    <Button tooltip="Make" icon="pi pi-dollar" className="p-button-rounded p-button-success p-mr-2"
                        onClick={() => {
                            setSelectedUser(rowdata);
                            setShowDialogMake(true);
                        }}
                    />
                }
            </>
        );
    };
    //FILTERS
    const cDestinationFilterChange = ({ value }) => {
        dt.current.filter(value, 'ds_currency_destination', 'in');
        setselectedCurrenDestination(value)
    };
    const cDestinationFilter =
        <MultiSelect style={{ width: "100%" }} className="p-column-filter" value={selectedCurrenDestination} options={filterOptions(pPendings, "ds_currency_destination")}
            onChange={cDestinationFilterChange} placeholder="All"
        />;

    const cOrigenFilterChange = ({ value }) => {
        dt.current.filter(value, 'ds_currency_origen', 'in');
        setSelectedCurrentOrigen(value)
    };
    const cOrigenFilter =
        <MultiSelect style={{ width: "100%" }} className="p-column-filter" value={selectedCurrentOrigen} options={filterOptions(pPendings, "ds_currency_origen")}
            onChange={cOrigenFilterChange} placeholder="All"
        />;

    const methodFilterChange = ({ value }) => {
        dt.current.filter(value, 'ds_type_of_payment_method', 'in');
        setSelectedMethod(value)
    };
    const methodFilter =
        <MultiSelect style={{ width: "100%" }} className="p-column-filter" value={selectedMethod} options={filterOptions(pPendings, "ds_type_of_payment_method")}
            onChange={methodFilterChange} placeholder="All"
        />;
    const nameFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'full_name', 'startsWith');
        setNamefilter(e.currentTarget.value);
    };
    const nameFilterCustom =
        <InputText
            value={namefilter}
            placeholder="Name"
            onChange={nameFilterChange}
            style={{ width: "100%" }}
        />;


    ///FILTRO NORMAL PARA VL_FEE
    const vl_feeprofitFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'vl_amount', 'contains');
        setvl_feeProfitFilter(e.currentTarget.value);
    };
    const vl_feeprofitFilterCustom =
        <InputText
            value={vl_feeprofitFilter}
            placeholder="$"
            onChange={vl_feeprofitFilterChange}
            style={{ width: "100%" }}
        />;


    const mailAccountFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'ds_email_account', 'startsWith');
        setAccountMailfilter(e.currentTarget.value);
    };
    const accountMailFilterCustom =
        <InputText
            value={accountMailfilter}
            placeholder="Account Mail / User Mail"
            onChange={mailAccountFilterChange}
            style={{ width: "100%" }}
        />;

    const nickNameTemplate = (rowData) => {
        // return <Chip label={rowData.ds_full_name} image={rowData.ds_profile_photo_thumbnail_path}  />
        return (
            <div className="p-d-flex p-ai-center">
                <Avatar image={rowData.ds_profile_photo_thumbnail_path} className="p-mr-2" size="small" shape="circle" />
                <a href={`https://hotmy.app/${rowData.ds_nick_name}`} target='_blank' without rel="noreferrer">{rowData.ds_nick_name} </a>
            </div>
        )
    };

    const orderFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'id_extraction_order', 'contains');
        setOrderFilter(e.currentTarget.value);
    };
    const orderFilterCustom =
        <InputText
            value={orderFilter}
            placeholder="Nº"
            onChange={orderFilterChange}
            style={{ width: "100%" }}
        />;
    const withdrawFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'vl_withdraw', 'startsWith');
        setWithdrawFilter(e.currentTarget.value);
    };
    const withdrawFilterCustom =
        <InputText
            value={withdrawFilter}
            placeholder="Withdraw"
            onChange={withdrawFilterChange}
            style={{ width: "100%" }}
        />;

    const insertDateFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'dt_insert', 'contains');
        setInsertDateFilter(e.currentTarget.value);
    };
    const insertDateFilterCustom =
        <InputText
            value={insertDateFilter}
            placeholder="Date"
            onChange={insertDateFilterChange}
            style={{ width: "100%" }}
        />;
    const expirationDateFilterChange = (e) => {
        dt.current.filter(e.currentTarget.value, 'dt_expiration_payment', 'contains');
        setExpirationDateFilter(e.currentTarget.value);
    };
    const expirationDateFilterCustom =
        <InputText
            value={expirationDateFilter}
            placeholder="Date"
            onChange={expirationDateFilterChange}
            style={{ width: "100%" }}
        />;
    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12 card">
                <Toast ref={toast} position="bottom-right" onClose={() => setshowDialogDetail(false)} />
                <BreadCrumb className="p-mb-2" home={breadcrumbHome} model={breadcrumbItems} />
                {listLoading && !pPendings ?
                    <div className="p-d-flex p-jc-center">
                        <ProgressSpinner />
                    </div>
                    :
                    <>
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
                        <DataTable ref={dt}
                            value={pPendings}
                            selection={selectedUser} onSelectionChange={(e) => setSelectedUser(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                            className="p-datatable-customers p-datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} payments pendings"
                            loading={listLoading || actionsLoading}
                            globalFilter={globalFilter} emptyMessage="Not payments pendings found." header={header}>
                            <Column headerStyle={{ width: '5%' }} className="p-text-center" field="id_extraction_order" header="Order" sortable body={e => commonTemplate(e.id_extraction_order)} filter filterElement={orderFilterCustom} />
                            <Column headerStyle={{ width: '7%' }} className="p-text-center" field="id_hotmer" header="id_hotmer" sortable body={e => commonTemplate(e.id_user)} filter filterElement={nameFilterCustom} />
                            <Column headerStyle={{ width: '10%' }} className="p-text-center" field="ds_display_name" header="Nick" sortable body={nickNameTemplate} filter filterPlaceholder="Nick" />
                            {/* <Column className="p-text-center" field="ds_nick_name" header="nick" sortable body={e => commonTemplate(e.ds_nick_name)} filter filterElement={nikeNameFilterCustom} /> */}
                            <Column headerStyle={{ width: '7%' }} className="p-text-center" field="vl_withdraw" header="Withdraw" sortable body={e => moneyTemplateGreen(e.vl_withdraw)} filter filterElement={withdrawFilterCustom} />
                            <Column headerStyle={{ width: '7%' }} className="p-text-center" field="ds_currency_destination" header="C. Destination" sortable body={cDestinationTemplate} filter filterElement={cDestinationFilter} />
                            <Column headerStyle={{ width: '7%' }} className="p-text-center" field="ds_currency_origen" header="C. Origen" sortable body={e => commonTemplate(e.ds_currency_origen)} filter filterElement={cOrigenFilter} />
                            <Column headerStyle={{ width: '7%' }} className="p-text-center" field="ds_type_of_payment_method" header="Method" sortable body={e => commonTemplate(e.ds_type_of_payment_method)} filter filterElement={methodFilter} />
                            <Column headerStyle={{ width: '25%' }} className="p-text-center" field="Mail de Cuenta" header="Account Mail" sortable body={e => commonTemplate(e.ds_email_account)} filter filterElement={accountMailFilterCustom} />
                            <Column headerStyle={{ width: '6%' }} className="p-text-center" field="vl_amount" header="Amount Ask" sortable body={e => moneyTemplate(e.vl_amount)} filter filterElement={vl_feeprofitFilterCustom} />
                            {/*  <Column className="p-text-center" field="Mail de Usuario" header="User Mail" sortable body={e => commonTemplate(e.ds_email_user_app)} filter filterElement={userMailFilterCustom} />*/}
                            <Column headerStyle={{ width: '8%' }} className="p-text-center" field="dt_insert" header="Insert" sortable body={e => dateTemplate(e.dt_insert)} filter filterElement={insertDateFilterCustom} />
                            <Column headerStyle={{ width: '8%' }} className="p-text-center" field="dt_expiration_payment" header="Expiration" sortable body={e => dateTemplate(e.dt_expiration_payment)} filter filterElement={expirationDateFilterCustom} />
                            <Column headerStyle={{ width: '5%' }} field="action" header="Actions" sortable body={actionBodyTemplate} />
                        </DataTable>
                    </>
                }
            </div>
            <DialogPPendingMake
                user={selectedUser}
                show={showDialogMake}
                setShow={setShowDialogMake}
            />
            <DialogPPendingDetail
                payment={pPendingDetails}
                show={!listLoading && pPendingDetails && showDialogDetail}
                setShow={setshowDialogDetail}
            />
        </div>
    )
}

export default PaymentsPending;