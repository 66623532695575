import React, { useState, useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actionsCountriesList from './_redux/countriesActions';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

const DropdownCountrys = ({ select = undefined, selected, setSelected }) => {
    const [change, setChange] = useState(false);
    const toast = useRef(null);
    const dispatch = useDispatch();
    const { countriesList, listLoading, error } = useSelector(
        state => state.countries,
        shallowEqual
    );
    useEffect(() => {
        if (!countriesList && !error) {
            dispatch(actionsCountriesList.fetchCountriesList({}));
        };
    }, [countriesList, error, dispatch]);
    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Countries', detail: error });
        };
    }, [error]);
    return (
        <div>
            <Toast ref={toast} position="bottom-right" />
            {select && !change ?
                <div className="p-inputgroup">
                    <InputText value={select} disabled />
                    <Button icon="pi pi-pencil" style={{ color: 'white' }} className="p-button-warning" onClick={() => setChange(true)} type="button" />
                </div>
                :
                !countriesList && listLoading ?
                    <div className="p-d-flex p-jc-center">
                        <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="8" animationDuration="3s" />
                    </div>
                    :
                    <div className="p-d-flex p-flex-row">
                        <Dropdown className={select ? "p-col-10" : "p-col-12"} optionLabel="ds_country" optionValue="id_country" value={selected}
                            options={countriesList}
                            onChange={(e) => setSelected(e.value)}
                            placeholder="Select a country"
                        />
                        {select &&
                            <Button icon="pi pi-times" className="p-button-danger"
                                onClick={() => {
                                    setSelected(0);
                                    setChange(false);
                                }}
                                type="button"
                                tooltip="Cancel"
                            />
                        }
                    </div>
            }
        </div>
    )
}

export default DropdownCountrys
