import { createSlice } from '@reduxjs/toolkit';

const initialStateCollaborationList = {
    listLoading: false,
    actionsLoading: false,
    collaborationList: undefined,
    collaborationDetail: undefined,
    success: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const pCollaborationListSlice = createSlice({
    name: "collaborationList",
    initialState: initialStateCollaborationList,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        catchSuccess: (state, action) => {
            state.error = null;
            state.success = action.payload.success;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        startCall: (state, action) => {
            state.error = null;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            };
        },
        // get List agency
        collaborationListFetched: (state, action) => {
            state.listLoading = false;
            state.agencyList = action.payload.agencyList;
            state.error = null;
        },
        // get agency detail
        collaborationDetailFetched: (state, action) => {
            state.listLoading = false;
            state.collaborationDetail = action.payload.collaborationDetail;
            state.error = null;
        },
        clear: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.collaborationDetail = undefined;
            state.error = null;
            state.success = null;
        },
        clearAll: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.collaborationList = undefined;
            state.collaborationDetail = undefined;
            state.error = null;
            state.success = null;
        }
    }
});