import * as requestFromServer from './pPacksCrud'
import { pPacksSlice, callTypes } from './pPacksSlice';

const { actions } = pPacksSlice;

export const fetchPacksList = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getPPacksList(data)
        .then(response => {
            const pPacks = response;
            dispatch(actions.pPacksFetched({ pPacks }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting Packs list", callType: callTypes.list }));
        });
};

export const fetchPacksDetails = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getPPacksDetails(id)
        .then(response => {
            const pPacksDetails = response;
            dispatch(actions.pPacksDetailsFetched({ pPacksDetails }));
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error getting Packs detail", callType: callTypes.list }));
        });
};


export const editPack = (id, data) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .PacksEdit(id, data)
        //.PacksEdit(id, data)
        .then(response => {
            if (response && response.status === "Success") {
                dispatch(actions.catchSuccess({ success: "Pack edit sucess", callType: callTypes.action }));
                fetchPacksDetails(id);
            } else {
                dispatch(actions.catchError({ error: "Failed to edit Pack", callType: callTypes.action }));
            };
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Failed to edit Pack", callType: callTypes.action }));
        });
};



///////////// POR SI HAY QUE HACER OTRO POST.
/*export const fetchMakeCancel = data => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .postMakeCancel(data)
        .then(response => {
            if (response && response.status === "Success") {
                dispatch(actions.catchSuccess({ success: "Make Cancel", callType: callTypes.action }));
                dispatch(fetchSubscriptionsList({}))
            } else {
                dispatch(actions.catchError({ error: "Failed to make cancel subscription", callType: callTypes.action }));
            };
        })
        .catch(() => {
            dispatch(actions.catchError({ error: "Error to make cancel subscription", callType: callTypes.action }));
        });
};
*/
export const clear = id => dispatch => {
    dispatch(actions.clear());
};

export const clearAll = id => dispatch => {
    dispatch(actions.clearAll());
};
