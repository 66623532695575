import React, { useState, useEffect, useRef, useContext } from 'react';
import { AuthContext } from './auth/provider/authProvieder';
import classNames from 'classnames';
//import { Route } from 'react-router-dom';

import { Route, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppProfile } from './AppProfile';
import { AppConfig } from './AppConfig';

import Dashboard from './modules/pages/dashboard/Dashboard';

//USERS
import UserList from './modules/pages/users/usersList/UserList';
import UserEdit from './modules/pages/users/usersList/UserEdit';
//HOTMERS
import HotmerList from './modules/pages/users/hotmersList/HotmerList';
import HotmerEdit from './modules/pages/users/hotmersList/HotmerEdit';
//AGENCY/PH
import AgencyList from './modules/pages/users/agencyList/AgencyList';

//AGREEMENT
import CollaborationList from './modules/pages/agreement/collaborationList/CollaborationList';
import CommercialList from './modules/pages/agreement/commercialList/CommercialList';

//CONTENT
import PackList from './modules/pages/content/packList/PackList';
import PackEdit from './modules/pages/content/packList/PackEdit';

import CoverList from './modules/pages/content/coverList/CoverList';
import CoverEdit from './modules/pages/content/coverList/CoverEdit';

//FINANCIAL
import PaymentsPending from './modules/pages/financial/paymentsPending/PaymentsPending';
import PaymentsMade from './modules/pages/financial/paymentsMade/PaymentsMade';
import PaymentsList from './modules/pages/financial/paymentsList/PaymentsList';
//import PaymentList from './modules/pages/financial/paymentsMade/PaymentsMade';

//SUBSCRIPTION LIST
import PaymentsSubscriptions from './modules/pages/financial/subscriptionList/SubscriptionList';
//import PaymentsCancel from './modules/pages/financial/paymentsMade/PaymentsMade';
//MAKE CANCEL

//BALANCE HOTMER
import Balancehotmer from './modules/pages/financial/balanceHotmer/Balancehotmer';


// import { ButtonDemo } from './components/ButtonDemo';
// import { ChartDemo } from './components/ChartDemo';
// import { Documentation } from './components/Documentation';
// import { FileDemo } from './components/FileDemo';
// import { FloatLabelDemo } from './components/FloatLabelDemo';
// import { FormLayoutDemo } from './components/FormLayoutDemo';
// import { InputDemo } from './components/InputDemo';
// import { ListDemo } from './components/ListDemo';
// import { MenuDemo } from './components/MenuDemo';
// import { MessagesDemo } from './components/MessagesDemo';
// import { MiscDemo } from './components/MiscDemo';
// import { OverlayDemo } from './components/OverlayDemo';
// import { PanelDemo } from './components/PanelDemo';
// import { TableDemo } from './components/TableDemo';
// import { TreeDemo } from './components/TreeDemo';
// import { InvalidStateDemo } from './components/InvalidStateDemo';

// import { Calendar } from './pages/Calendar';
// import { Crud } from './pages/Crud';
// import { EmptyPage } from './pages/EmptyPage';

// import { DisplayDemo } from './utilities/DisplayDemo';
// import { ElevationDemo } from './utilities/ElevationDemo';
// import { FlexBoxDemo } from './utilities/FlexBoxDemo';
// import { GridDemo } from './utilities/GridDemo';
// import { IconsDemo } from './utilities/IconsDemo';
// import { SpacingDemo } from './utilities/SpacingDemo';
// import { TextDemo } from './utilities/TextDemo';
// import { TypographyDemo } from './utilities/TypographyDemo';
// import { TimelineDemo } from './utilities/TimelineDemo';

import PrimeReact from 'primereact/api';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/flags/flags.css';
import './layout/layout.scss';
import './App.scss';

const App = () => {

    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('dark')
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(true);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const sidebar = useRef();
    let menuClick = false;
    
    const history = useHistory();

    //CONTEXTS
    const { 
        isUserAuthorized,
        isHotmerAuthorized,
        isAgencyAuthorized,
        isAgreementAuthorized,
        isPPendingAuthorized,
        isPPaymentAuthorized,
        isPSubscriptionsAuthorized, //nuevo campo
        isPMadeAuthorized,
        isBHotmerAuthorized,
        isDashboardAuthorized,
        ispPacksAuthorized,
        ispCoversAuthorized,
        ispAgreementAuthorized,
        } = useContext(AuthContext);

    /*useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, 'body-overflow-hidden');
        }
        else {
            removeClass(document.body, 'body-overflow-hidden');
        }
    }, [mobileMenuActive]);
*/
    useEffect(() => {
        if (sidebarActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [sidebarActive]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    
    const onWrapperClick = (event) => {
        if (!menuClick && layoutMode === "overlay") {
            setSidebarActive(false);
        }
        menuClick = false;
    }

    const onToggleMenu = (event) => {
        menuClick = true;

        setSidebarActive((prevState) => !prevState);

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items && layoutMode === "overlay") {
            setSidebarActive(false);
        }
    }

    /*
    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        menuClick = false;
    }

    const onToggleMenu = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                setOverlayMenuActive(prevState => !prevState);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive(prevState => !prevState);
            }
        }
        else {
            setMobileMenuActive(prevState => !prevState);
        }
        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }*/

    /*const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    const menu = [
        // { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
        // {
        //     label: 'UI Kit', icon: 'pi pi-fw pi-sitemap',
        //     items: [
        //         { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout' },
        //         { label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input' },
        //         { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', to: '/floatlabel' },
        //         { label: "Invalid State", icon: "pi pi-exclamation-circle", to: "/invalidstate" },
        //         { label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button' },
        //         { label: 'Table', icon: 'pi pi-fw pi-table', to: '/table' },
        //         { label: 'List', icon: 'pi pi-fw pi-list', to: '/list' },
        //         { label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree' },
        //         { label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel' },
        //         { label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay' },
        //         { label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu' },
        //         { label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages' },
        //         { label: 'File', icon: 'pi pi-fw pi-file', to: '/file' },
        //         { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart' },
        //         { label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc' },
        //     ]
        // },
        // {
        //     label: 'Utilities', icon: 'pi pi-fw pi-globe',
        //     items: [
        //         { label: 'Display', icon: 'pi pi-fw pi-desktop', to: '/display' },
        //         { label: 'Elevation', icon: 'pi pi-fw pi-external-link', to: '/elevation' },
        //         { label: 'Flexbox', icon: 'pi pi-fw pi-directions', to: '/flexbox' },
        //         { label: 'Icons', icon: 'pi pi-fw pi-search', to: '/icons' },
        //         { label: 'Grid System', icon: 'pi pi-fw pi-th-large', to: '/grid' },
        //         { label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', to: '/spacing' },
        //         { label: 'Typography', icon: 'pi pi-fw pi-align-center', to: '/typography' },
        //         { label: 'Text', icon: 'pi pi-fw pi-pencil', to: '/text' },
        //     ]
        // },
        // {
        //     label: 'Pages', icon: 'pi pi-fw pi-clone',
        //     items: [
        //         { label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud' },
        //         { label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', to: '/calendar' },
        //         { label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline' },
        //         { label: 'Empty Page', icon: 'pi pi-fw pi-circle-off', to: '/empty' }
        //     ]
        // },
        // {
        //     label: 'Menu Hierarchy', icon: 'pi pi-fw pi-search',
        //     items: [
        //         {
        //             label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
        //             items: [
        //                 {
        //                     label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
        //                     items: [
        //                         { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
        //                         { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
        //                         { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' },
        //                     ]
        //                 },
        //                 {
        //                     label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
        //                     items: [
        //                         { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' },
        //                         { label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-bookmark' }
        //                     ]
        //                 },
        //             ]
        //         },
        //         {
        //             label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
        //             items: [
        //                 {
        //                     label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
        //                     items: [
        //                         { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
        //                         { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' },
        //                         { label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-bookmark' },
        //                     ]
        //                 },
        //                 {
        //                     label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
        //                     items: [
        //                         { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' },
        //                         { label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-bookmark' }
        //                     ]
        //                 }
        //             ]
        //         }
        //     ]
        // },
        // { label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => { window.location = "#/documentation" } },
        // { label: 'Usuarios', icon: 'pi pi-fw pi-user-edit', to: '/crud' },
    ];*/
    const menu = [];
    if (isDashboardAuthorized()) {
        const dashBoard = { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' };
        menu.push(dashBoard);
    };
    if (isUserAuthorized() || isHotmerAuthorized() || isAgencyAuthorized()){
        const users = { label: 'Users', icon: 'pi pi-fw pi-users', items: [] };
        if (isUserAuthorized()) {
            users.items.push({ label: 'Users List', icon: 'pi pi-fw pi-user', to: '/users/usersList' })
        };
        if (isHotmerAuthorized()) {
            users.items.push({ label: 'Hotmers List', icon: 'pi pi-fw pi-star-o', to: '/users/hotmersList' })
        };
        if (isAgencyAuthorized()) {
            users.items.push({ label: 'Agency/Ph List', icon: 'pi pi-fw pi-star-o', to: '/users/agencyList' })
        };
        menu.push(users);
    };

    // MENUN DE CONTENIDO
    if (ispPacksAuthorized() || ispCoversAuthorized()) {
        const users = { label: 'Content', icon: 'pi pi-fw pi-users', items: [] };
        if (ispPacksAuthorized()) {
            users.items.push({ label: 'Pack List', icon: 'pi pi-fw pi-user', to: '/content/packslist' })
        };
        if (ispCoversAuthorized()) {
            users.items.push({ label: 'Cover List', icon: 'pi pi-fw pi-user', to: '/content/coverlist' })
        };

        menu.push(users);
    };

    if (isAgreementAuthorized()) {
        const users = { label: 'Agreement', icon: 'pi pi-fw pi-users', items: [] };
        if (isAgreementAuthorized()) {
            users.items.push({ label: 'Collaboration List', icon: 'pi pi-fw pi-user', to: '/agreement/collaborationlist' })
        };
        if (isAgreementAuthorized()) {
            users.items.push({ label: 'Commercial List', icon: 'pi pi-fw pi-user', to: '/agreement/commerciallist' })
        };
        menu.push(users);
    };
    

    if (isPPendingAuthorized() || isPMadeAuthorized() || isBHotmerAuthorized() || isPPaymentAuthorized() || isPSubscriptionsAuthorized()){
        const users = { label: 'Financial', icon: 'pi pi-fw pi-users', items: [] };
        if (isPMadeAuthorized()) {
            users.items.push({ label: 'Payments Made', icon: 'pi pi-fw pi-check', to: '/financial/paymentsmade' })
        };
        if (isPPendingAuthorized()) {
            users.items.push({ label: 'Pending Payments', icon: 'pi pi-fw pi-exclamation-circle', to: '/financial/paymentspending' })
        };
        if (isBHotmerAuthorized()) {
            users.items.push({ label: 'Balance Hotmers', icon: 'pi pi-fw pi-chart-line', to: '/financial/balanceHotmer' })
        };
        if (isPPaymentAuthorized()) {
            users.items.push({ label: 'List Payment', icon: 'pi pi-fw pi-money-bill', to: '/financial/paymentlist' })
        };
        if (isPSubscriptionsAuthorized()) {
            users.items.push({ label: 'List Subscription', icon: 'pi pi-fw pi-ticket', to: '/financial/paymentsubscriptions' })
        };
      
        menu.push(users);
    };

    
    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const isSidebarVisible = () => {
        return sidebarActive;
    };

    /*const isSidebarVisible = () => {
        if (isDesktop()) {
            if (layoutMode === 'static')
                return !staticMenuInactive;
            else if (layoutMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        }

        return true;
    }*/

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-active': sidebarActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false
    });

    const sidebarClassName = classNames('layout-sidebar', {
        'layout-sidebar-dark': layoutColorMode === 'dark',
        'layout-sidebar-light': layoutColorMode === 'light'
    });

    /*const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false
    });*/

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <AppTopbar onToggleMenu={onToggleMenu} />

            <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
                <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>
                    <div className="layout-logo">
                        <img alt="Logo" src="/assets/images/Fuego-Blanco-1.png" style={{ maxHeight: 60, width: "auto" }} />
                    </div>
                    <AppProfile />
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
                </div>
            </CSSTransition>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <div className="layout-main">

                {isDashboardAuthorized() && <Route path="/" exact component={Dashboard} />}

                {isUserAuthorized() && <Route exact path="/users/userslist" component={UserList} />}
                {isUserAuthorized(2) && <Route exact path="/users/userslist/edit/:id" component={UserEdit} />}

                {isHotmerAuthorized() && <Route exact path="/users/hotmerslist" component={HotmerList} />}
                {isHotmerAuthorized(2) && <Route exact path="/users/hotmerslist/edit/:id" component={HotmerEdit} />}

                {isAgencyAuthorized() && <Route exact path="/users/agencylist" component={AgencyList} />}

                {isPPendingAuthorized() && <Route exact path="/financial/paymentsmade" component={PaymentsMade} />}

                {isPPendingAuthorized() && <Route exact path="/financial/paymentspending" component={PaymentsPending} />}

                {isBHotmerAuthorized() && <Route exact path="/financial/balanceHotmer" component={Balancehotmer} />}

                {isPPaymentAuthorized() && <Route exact path="/financial/paymentlist" component={PaymentsList} />}

                {isPSubscriptionsAuthorized() && <Route exact path="/financial/paymentsubscriptions" component={PaymentsSubscriptions} />}

                {ispPacksAuthorized() && <Route exact path="/content/packslist" component={PackList} />}
                {ispPacksAuthorized(2) && <Route exact path="/content/packslist/edit/:id" component={PackEdit} />}

                {ispCoversAuthorized() && <Route exact path="/content/coverlist" component={CoverList} />}
                {ispCoversAuthorized(2) && <Route exact path="/content/coverlist/edit/:id" component={CoverEdit} />}

                {isAgreementAuthorized() && <Route exact path="/agreement/collaborationlist" component={CollaborationList} />}
                {isAgreementAuthorized() && <Route exact path="/agreement/commerciallist" component={CommercialList} />}
                

                {/* <Route path="/crud" component={Crud} />
                <Route path="/formlayout" component={FormLayoutDemo} />
                <Route path="/input" component={InputDemo} />
                <Route path="/floatlabel" component={FloatLabelDemo} />
                <Route path="/invalidstate" component={InvalidStateDemo} />
                <Route path="/button" component={ButtonDemo} />
                <Route path="/table" component={TableDemo} />
                <Route path="/list" component={ListDemo} />
                <Route path="/tree" component={TreeDemo} />
                <Route path="/panel" component={PanelDemo} />
                <Route path="/overlay" component={OverlayDemo} />
                <Route path="/menu" component={MenuDemo} />
                <Route path="/messages" component={MessagesDemo} />
                <Route path="/file" component={FileDemo} />
                <Route path="/chart" component={ChartDemo} />
                <Route path="/misc" component={MiscDemo} />
                <Route path="/display" component={DisplayDemo} />
                <Route path="/elevation" component={ElevationDemo} />
                <Route path="/flexbox" component={FlexBoxDemo} />
                <Route path="/icons" component={IconsDemo} />
                <Route path="/grid" component={GridDemo} />
                <Route path="/spacing" component={SpacingDemo} />
                <Route path="/typography" component={TypographyDemo} />
                <Route path="/text" component={TextDemo} />
                <Route path="/calendar" component={Calendar} />
                <Route path="/timeline" component={TimelineDemo} />
                <Route path="/empty" component={EmptyPage} />
                <Route path="/documentation" component={Documentation} /> */}
            </div>

            {/* <AppFooter /> */}

        </div>
    );

}

export default App;
