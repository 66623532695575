import React, { createContext } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import { balanceHotmerActions, paymentsMadeActions, paymentsPendingActions, userActions, hotmerActions, agencyActions, agreementActions, dashboardActions, paymentListActions, paymentsSubscriptionsActions, packActions, coverActions } from './actions';
export const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
    const authStorage = sessionStorage.getItem('auth');
    const { auth } = useSelector(
        state => state.auth,
        shallowEqual
    );
    let authParse;
    let permissions = [];
    if (authStorage) {
        authParse = JSON.parse(authStorage);
    };
    if (authParse && authParse.permissions) {
        permissions = authParse.permissions;
    };

    //SEARCH ACTION
    const searhAction = (action) => {
        if (permissions && Array.isArray(permissions) && permissions.length > 0) {
            return permissions.find(element => element.name === action);
        } else {
            return undefined;
        };
    }
    //ACTIONS FOR USERS
    const isUserAuthorized = (action = 0) => {
        const userAction = userActions[action];
        return searhAction(userAction);
    };

    //ACTIONS FOR HOTMERS
    const isHotmerAuthorized = (action = 0) => {
        const hotmerAction = hotmerActions[action];
        return searhAction(hotmerAction);
    };

    //ACTIONS FOR AGENCY
    const isAgencyAuthorized = (action = 0) => {
        const agencyAction = agencyActions[action];
        return searhAction(agencyAction);
    };

    //ACTIONS FOR AGENCY
    const isAgreementAuthorized = (action = 0) => {
        const agreementAction = agreementActions[action];
        return searhAction(agreementAction);
    };

    //ACTIONS FOR CONTENT/PACKS
    const ispPacksAuthorized = (action = 0) => {
        const packsAction = packActions[action];
        return searhAction(packsAction);
    };

    //ACTIONS FOR CONTENT/COVERS
    const ispCoversAuthorized = (action = 0) => {
        const coversAction = coverActions[action];
        return searhAction(coversAction);
    };

    //ACTIONS FOR Payments PENDING
    const isPPendingAuthorized = (action = 0) => {
        const pendingAction = paymentsPendingActions[action];
        return searhAction(pendingAction);
    };

    const isPPaymentAuthorized = (action = 0) => {
        const paymentAction = paymentListActions[action];
        return searhAction(paymentAction);
    };

    //ACTIONS FOR Payments Subscriptions
    const isPSubscriptionsAuthorized = (action = 0) => {
        const subscriptionAction = paymentsSubscriptionsActions[action];
        return searhAction(subscriptionAction);
    };

    //ACTIONS FOR Payments MADE
    const isPMadeAuthorized = (action = 0) => {
        const madeAction = paymentsMadeActions[action];
        return searhAction(madeAction);
    };

    //ACTIONS FOR BALANCE HOTMER
    const isBHotmerAuthorized = (action = 0) => {
        const bHotmerAction = balanceHotmerActions[action];
        return searhAction(bHotmerAction);
    };

    //ACTIONS FOR DASHBOARD
    const isDashboardAuthorized = (action = 0) => {
        const dashboardAction = dashboardActions[action];
        return searhAction(dashboardAction);
    };

    return (
        <AuthContext.Provider
            value={{
                auth,
                isUserAuthorized,
                isHotmerAuthorized,
                isAgencyAuthorized,
                isAgreementAuthorized,
                isPPendingAuthorized,
                isPPaymentAuthorized,
                isPMadeAuthorized,
                isBHotmerAuthorized,
                isDashboardAuthorized,
                isPSubscriptionsAuthorized,
                ispPacksAuthorized,
                ispCoversAuthorized,
            }}>
            {children}
        </AuthContext.Provider>
    )
};

export const AuthConsumer = ({ children }) => {
    return (
        <AuthContext.Consumer>
            {children}
        </AuthContext.Consumer>
    )
}