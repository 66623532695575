import React from 'react';
import * as actionsPacksList from './../_redux/pPacksActions';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
//import { Button } from 'primereact/button';
/*import DropdownGenders from '../../../../components/dropdowns/genders/DropdownGenders'
import DropdownDocuments from '../../../../components/dropdowns/documents/DropdownDocuments'
import DropdownCountrys from '../../../../components/dropdowns/countries/DropdownCountrys';
import DropdownRoles from '../../../../components/dropdowns/roles/DropdownRoles';*/
import DropdownPackStatus from '../../../../components/dropdowns/packStatus/DropdownPacksStatus';
import { difference } from '../../../../functions/objectFunctions';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import moment from 'moment';
import { status } from '../../../../functions/hotmerFunctions';
import { Fieldset } from 'primereact/fieldset';

const ContentPacks = ({ id }) => {
    const dispatch = useDispatch();
    const today = new Date();
    const { pPacksDetails, actionsLoading } = useSelector(
        state => state.pPacks,
        shallowEqual
    );

    const initialValues = {
        ds_title: pPacksDetails && pPacksDetails.ds_title ? pPacksDetails.ds_title : '',
        ds_description: pPacksDetails && pPacksDetails.ds_description ? pPacksDetails.ds_description : '',
        id_pack: 0,
        id_status: 0,
        count_seller_of_pack: 0,
        count_image: 0,
        count_video: 0,
        id_hotmer: 0,
        ds_url_thumbnail: pPacksDetails && pPacksDetails.ds_url_thumbnail ? pPacksDetails.ds_url_thumbnail : '',
        dt_insert: pPacksDetails && pPacksDetails.dt_insert ? pPacksDetails.dt_insert : '',
        bl_active_in_search: pPacksDetails && pPacksDetails.bl_active_in_search ? pPacksDetails.bl_active_in_search : false,
        vl_price: pPacksDetails && pPacksDetails.vl_price ? parseInt(pPacksDetails.vl_price) : 0,
        vl_score: pPacksDetails && pPacksDetails.vl_score ? parseInt(pPacksDetails.vl_score) : 0,
    };

    const validationSchema = Yup.object({
            vl_score: Yup.number()
            .min(0, 'Min score is 0')
            .max(10, 'Max score is 10')
            .required('Score is required'),
            ds_url_thumbnail: Yup.string()
            .required('url de Landscape is required'),
            ds_title: Yup.string()
            .required('url de ds_title is required'),
            ds_description: Yup.string()
            .required('url de ds_description is required'),
         });
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                const result = difference(initialValues, values);
                // console.log(result) //revisar Profile
                dispatch(actionsPacksList.editPack(id, { pack: result }));
                setSubmitting(false);
            }}
        >
            {formik =>
                <Form>
                    <div className="p-d-flex p-ai-top p-jc-between">
                        <div className="p-fluid p-formgrid p-grid p-col-12 p-md-6">
                            <div className="p-field p-col-12 p-mb-4">
                            <div className="p-d-none p-d-md-block p-fluid p-formgrid p-grid p-col-12 p-md-6">
                            <div className="p-field p-d-flex p-flex-column p-mb-5">
                                <label htmlFor="bl_active_in_search">Active in Packs Search (Lupa de Packs)</label>
                                <InputSwitch checked={formik.values.bl_active_in_search} onChange={e => formik.setFieldValue('bl_active_in_search', !formik.values.bl_active_in_search)} />
                                <small className="p-error p-d-block"><ErrorMessage name="bl_active_in_search" /></small>
                            </div>
                        </div>
                                <label htmlFor="ds_title">Title</label>
                                <div className="p-inputgroup">
                                    <InputText id="ds_title" value={formik.values.ds_title} onChange={e => formik.setFieldValue('ds_title', e.currentTarget.value)} />
                                    <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('ds_title', initialValues.ds_title)} type="button" />
                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('ds_title', '')} type="button" />
                                </div>
                                <small className="p-error p-d-block"><ErrorMessage name="ds_title" /></small>
                            </div>
                            <div className="p-field p-col-12 p-mb-4">
                                <label htmlFor="ds_description">Description</label>
                                <div className="p-inputgroup">
                                    <InputText id="ds_description" value={formik.values.ds_description} onChange={e => formik.setFieldValue('ds_description', e.currentTarget.value)} />
                                    <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('ds_description', initialValues.ds_description)} type="button" />
                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('ds_description', '')} type="button" />
                                </div>
                                <small className="p-error p-d-block"><ErrorMessage name="ds_description" /></small>
                            </div>

                            <div className="p-field p-col-12 p-mb-4">
                                <label htmlFor="id_country">Status</label>
                                <DropdownPackStatus
                                    select={pPacksDetails.ds_status_name}
                                    selected={formik.values.id_status}
                                    setSelected={value => formik.setFieldValue('id_status', value)}
                                />
                                <small className="p-error p-d-block"><ErrorMessage name="id_status" /></small>
                            </div>
                           
                            <div className="p-field p-md-5 p-mb-4 p-pt-1">
                                <label htmlFor="vl_price">Price</label>
                                <div className="p-inputgroup">
                                    <InputNumber id="vl_price"
                                        max={100} min={0} value={formik.values.vl_price}
                                        onChange={e => formik.setFieldValue('vl_price', e.value ? e.value : 0)}
                                        showButtons buttonLayout="horizontal"
                                        mode="decimal" minFractionDigits={0} maxFracionDigits={1} step={0.25}
                                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                    />
                                    <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('vl_price', initialValues.vl_price)} type="button" />
                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('vl_price', 0)} type="button" />
                                </div>
                                <small className="p-error p-d-block"><ErrorMessage name="vl_price" /></small>
                            </div>

                            <div className="p-field p-md-5 p-mb-4 p-pt-1">
                                <label htmlFor="vl_score">Score</label>
                                <div className="p-inputgroup">
                                    <InputNumber id="vl_score"
                                        max={10} min={0} value={formik.values.vl_score}
                                        onChange={e => formik.setFieldValue('vl_score', e.value ? e.value : 0)}
                                        showButtons buttonLayout="horizontal"
                                        mode="decimal" minFractionDigits={0} maxFracionDigits={1} step={0.25}
                                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                    />
                                    <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('vl_score', initialValues.vl_score)} type="button" />
                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('vl_score', 0)} type="button" />
                                </div>
                                <small className="p-error p-d-block"><ErrorMessage name="vl_score" /></small>
                            </div>

                            <div className="p-field p-col-12 p-md-6 p-mb-4">
                                                    <label htmlFor="dt_insert">Date Pack Created</label>
                                                    <div className="p-inputgroup">
                                                    {/*<Button icon="pi pi-calendar" className="p-button-info" />*/}
                                                        <Calendar id="dt_insert" monthNavigator yearNavigator readOnlyInput
                                                            yearRange={`${today.getDay() - 1}`}
                                                            value={new Date(formik.values.dt_insert)}
                                                            onChange={e => formik.setFieldValue('dt_insert', moment(e.value).format("YYYY/MM/DD"))}
                                                        />
                                                        <Button
                                                            icon="pi pi-refresh" style={{ color: 'white' }}
                                                            className="p-button-warning p-t"
                                                            onClick={() => formik.setFieldValue('dt_insert', initialValues.dt_insert)} type="button"
                                                        />
                                                    </div>
                                                    <small className="p-error p-d-block"><ErrorMessage name="dt_insert" /></small>
                                                </div>

                            <div className="p-field p-col-12 p-mb-4">
                                <label htmlFor="ds_url_thumbnail">Url Thumbnail</label>
                                <div className="p-inputgroup">
                                    <InputText id="ds_url_thumbnail" value={formik.values.ds_url_thumbnail} onChange={e => formik.setFieldValue('ds_url_thumbnail', e.currentTarget.value)} />
                                    <Button icon="pi pi-refresh" style={{ color: 'white' }} className="p-button-warning p-t" onClick={() => formik.setFieldValue('ds_url_thumbnail', initialValues.ds_url_thumbnail)} type="button" />
                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => formik.setFieldValue('ds_url_thumbnail', '')} type="button" />
                                </div>
                                <small className="p-error p-d-block"><ErrorMessage name="ds_url_thumbnail" /></small>
                            </div>


                        </div>

                            <Fieldset className="p-mp-6" legend="Data">
                                <div className="p-formgrid p-grid">
                                    <p className="p-col-12 p-md-6"><b>id pack: </b>{pPacksDetails.id_pack}</p>
                                    {/*<p className="p-col-12 p-md-6"><b>Status: </b>{status(pPacksDetails.ds_status_name)}</p>*/}
                                    <p className="p-col-12 p-md-6"><b>Qty Videos: </b>{pPacksDetails.count_video}</p>
                                    <p className="p-col-12 p-md-6"><b>Qty Images: </b>{pPacksDetails.count_image}</p>
                                    <p className="p-col-12 p-md-6"><b>Qty Sells: </b>{pPacksDetails.count_seller_of_pack}</p>
                                    <p className="p-col-12 p-md-6"><b>id Hotmer: </b>{pPacksDetails.id_hotmer}</p>
                                </div>
                                {/*<div className="p-formgrid p-grid">
                                    <p className="p-col-12 p-md-6"><b>Qty Videos: </b>{pPacksDetails.count_video}</p>
                                    <p className="p-col-12 p-md-6"><b>Qty Images: </b>{pPacksDetails.count_image}</p>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <p className="p-col-12 p-md-6"><b>Qty Sells: </b>{pPacksDetails.count_seller_of_pack}</p>
                                    <p className="p-col-12 p-md-6"><b>id Hotmer: </b>{pPacksDetails.id_hotmer}</p>
                                </div>*/}
                            </Fieldset>
                    </div>
                    <div className="p-d-flex p-jc-end p-mb-4">
                        <div>
                            <Button icon={`pi ${actionsLoading ? `pi-spin pi-spinner` : `pi-check`}`}
                                label="Save" className="p-button-info p-m-3" type="submit"
                                disabled={!formik.dirty || actionsLoading}
                            />
                        </div>
                    </div>
                </Form>
            }
        </Formik >
    )
};

export default ContentPacks
