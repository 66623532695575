import { createSlice } from '@reduxjs/toolkit';

const initialStatePPendings = {
    listLoading: false,
    actionsLoading: false,
    pPendings: undefined,
    pPendingDetails: undefined,
    success: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const pPendingsSlice = createSlice({
    name: "pPendings",
    initialState: initialStatePPendings,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        catchSuccess: (state, action) => {
            state.error = null;
            state.success = action.payload.success;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        startCall: (state, action) => {
            state.error = null;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            };
        },
        // get List PPendings
        pPendingsFetched: (state, action) => {
            state.listLoading = false;
            state.pPendings = action.payload.pPendings;
            state.error = null;
        },
        // get pPendings detail
        pPendingDetailsFetched: (state, action) => {
            state.listLoading = false;
            state.pPendingDetails = action.payload.pPendingDetails;
            state.error = null;
        },
        clear: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.pPendingDetails = undefined;
            state.error = null;
            state.success = null;
        },
        clearAll: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.pPendings = undefined;
            state.pPendingDetails = undefined;
            state.error = null;
            state.success = null;
        }
    }
});