import { createSlice } from '@reduxjs/toolkit';

const initialStateGenders = {
    listLoading: false,
    actionsLoading: false,
    gendersList: undefined,
    success: null,
    error: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const gendersListSlice = createSlice({
    name: "gendersList",
    initialState: initialStateGenders,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        catchSuccess: (state, action) => {
            state.error = null;
            state.success = action.payload.success;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            };
        },
        startCall: (state, action) => {
            state.error = null;
            state.success = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            };
        },
        // get List genders
        gendersListFetched: (state, action) => {
            state.listLoading = false;
            state.gendersList = action.payload.gendersList;
            state.error = null;
        },
        clearAll: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.gendersList = undefined;
            state.error = null;
            state.success = null;
        }
    }
});