import React, { useEffect, useState, useRef, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AuthContext } from '../../../../auth/provider/authProvieder';
import * as actionsUsersList from './_redux/usersListActions';
import { Link } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { BreadCrumb } from 'primereact/components/breadcrumb/BreadCrumb';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Avatar } from 'primereact/avatar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import DialogCustom from '../../../components/dialogs/DialogCustom';
import DialogUserDeatil from '../../../components/dialogs/DialogUserDeatil';
import '../../../styles/customers.scss'


const UserList = (props) => {
    const [selectedUser, setSelectedUser] = useState('');
    const [deleteUser, setDeleteUser] = useState(false)
    const [resetPassword, setResetPassword] = useState(false)
    const [showDialogDetail, setshowDialogDetail] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');
    const [userStatus, setUserStatus] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const { isUserAuthorized } = useContext(AuthContext);
    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };
    const breadcrumbItems = [
        { label: 'Users' },
        { label: 'Users list' },
    ];

    const dispatch = useDispatch();
    const { usersList, userDetail, listLoading, actionsLoading, error, success } = useSelector(
        state => state.usersList,
        shallowEqual
    );

    useEffect(() => {
        if (!usersList) {
            dispatch(actionsUsersList.fetchUsersList({}));
        };
    }, [usersList, dispatch]);

    useEffect(() => {
        return () => {
            setSelectedUser('');
            setshowDialogDetail(false);
            dispatch(actionsUsersList.clearAll({}));
        }
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Users', detail: error });
        };
    }, [error]);
    useEffect(() => {
        if (success) {
            toast.current.show({ severity: 'success', summary: 'Users', detail: success });
        };
    }, [success]);

    //TOAST DETAIL NOT FOUND
    useEffect(() => {
        if (!listLoading && !userDetail && showDialogDetail) {
            toast.current.show({ severity: 'warn', summary: 'User', detail: 'User detaill not found' });
        }
    }, [listLoading, userDetail, showDialogDetail]);


    const leftToolbarTemplate = () => {
        return (
            <>
                <Button label="Reload" icon="pi pi-refresh" className="p-button-warning p-ml-2"
                    onClick={() => {
                        setSelectedUser('');
                        dispatch(actionsUsersList.fetchUsersList({}));
                    }}
                    disabled={listLoading || actionsLoading}
                />
            </>
        )
    };

    const rightToolbarTemplate = () => {
        return (
            <>
                <Button label="Export" icon="pi pi-upload" className="p-button-help p-ml-2"
                    disabled={listLoading || actionsLoading} /*onClick={exportCSV}*/
                    onClick={() => dt.current.exportCSV()}
                />
            </>
        )
    };
    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Users</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const nameBodyTemplate = (rowData) => {
        // return <Chip label={rowData.ds_full_name} image={rowData.ds_profile_photo_thumbnail_path}  />
        return (
            <div className="p-d-flex p-ai-center">
                <Avatar image={rowData.ds_profile_photo_thumbnail_path} className="p-mr-2" size="small" shape="circle" />
                <span className="image-text" >{rowData.ds_full_name}</span>
            </div>
        )
    };
    const countryBodyTemplate = (rowData) => {
        return (
            <>
                <span className={`flag flag-${rowData.ds_flag_code.toLowerCase()}`} style={{ width: 30, height: 20 }} />
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }} className="image-text">{rowData.ds_country}</span>
            </>
        );
    };

    const nickBodyTemplate = (rowData) => {
        return <span>{rowData.ds_nick_name}</span>
    };

    const mailBodyTemplate = (rowData) => {
        return <span>{rowData.ds_email}</span>
    };

    const idBodyTemplate = (rowData) => {
        return <span>{rowData.id_user}</span>
    };

    const dateBodyTemplate = ({ dt_created_at }) => {
        return <span>{dt_created_at}</span>
    };

    const statusBodyTemplate = (rowData) => {
        const status = rowData.bl_status ? "QUALIFIED" : "UNQUALIFIED";
        return <span className={`customer-badge status-${status.toLowerCase()}`}>{status}</span>
    };

    const actionBodyTemplate = (rowdata) => {
        return (
            <>
                {isUserAuthorized(1) &&
                    <Button tooltip="Details" icon="pi pi-eye" className="p-button-rounded p-button-info p-mr-1"
                        onClick={() => {
                            dispatch(actionsUsersList.fetchUsersDetail(rowdata.id_user));
                            setshowDialogDetail(true);
                        }}
                    />
                }
                {isUserAuthorized(2) &&
                    <Link to={`/users/usersList/edit/${rowdata.id_user}`}>
                        <Button tooltip="Edit" icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-1"
                            onClick={() => {
                                //dispatch(actionsUsersList.fetchUsersDetail(rowdata.id_user));
                                //setEditUser(true);
                            }}
                        />
                    </Link>
                }
               {/*} {isUserAuthorized(4) &&
                    <Button tooltip="Reset Pass" icon="pi pi-replay" className="p-button-rounded p-button-warning p-mr-1" style={{ color: 'white' }}
                        onClick={() => {
                            setSelectedUser(rowdata);
                            setResetPassword(true);
                        }}
                    />
                }
                {isUserAuthorized(3) &&
                    <Button tooltip="Delete" icon="pi pi-trash" className="p-button-rounded p-button-danger"
                        onClick={() => {
                            setSelectedUser(rowdata);
                            setDeleteUser(true);
                        }}
                    />
                }*/}
            </>
        );
    }

    //FILTERS CUSTOMS

    const userStatuses = [
        { label: 'All', value: null },
        { label: 'QUALIFIED', value: true },
        { label: 'UNQUALIFIED', value: false },
    ];
    const onStatusChange = (event) => {
        dt.current.filter(event.value, 'bl_status', 'equals');
        setUserStatus(event.value);
    };
    const statusItemTemplate = ({ label }) => {
        return <span className={`customer-badge status-${label.toLowerCase()}`}>{label}</span>
    };
    const statusFilterCustom =
        <Dropdown className="ui-column-filter" style={{ width: '100%' }}
            value={userStatus} options={userStatuses} onChange={onStatusChange} itemTemplate={statusItemTemplate}
        />;
    return (
        <div className="p-grid crud-demo">
            {/* {editUser && userDetail && <Redirect to="/users/usersEdit" />} */}
            <div className="p-col-12 card">
                <Toast ref={toast} position="bottom-right" />
                <BreadCrumb className="p-mb-2" home={breadcrumbHome} model={breadcrumbItems} />
                {listLoading && !usersList ?
                    <div className="p-d-flex p-jc-center">
                        <ProgressSpinner />
                    </div>
                    :
                    <>
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                        <DataTable ref={dt} rowHover
                            value={usersList} selection={selectedUser} onSelectionChange={(e) => setSelectedUser(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[ 10, 25, 50, 75, 100, 200]}
                            className="p-datatable-customers p-datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                            loading={listLoading || actionsLoading}
                            globalFilter={globalFilter} emptyMessage="Not users found." header={header}>
                            <Column  headerStyle={{ width: '5%' }}className="p-text-center" field="id_user" header="id" sortable body={idBodyTemplate} filter filterPlaceholder="id" />
                            <Column className="p-text-center" field="ds_nick_name" header="NickName" sortable body={nickBodyTemplate} filter filterPlaceholder="NickName" />
                            <Column className="p-text-center" field="ds_full_name" header="Name" sortable body={nameBodyTemplate} filter filterPlaceholder="Name" />
                            <Column className="p-text-center" field="ds_country" header="Country" sortable body={countryBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Country" />
                            <Column headerStyle={{ width: '20%' }}className="p-text-center" field="ds_email" header="Mail" sortable body={mailBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Mail" />
                            <Column className="p-text-center" field="dt_created_at" header="Created" sortable body={dateBodyTemplate} filter filterMatchMode="contains" filterPlaceholder="Date" />
                            <Column headerStyle={{ width: '8%' }} className="p-text-center" field="bl_status" header="Status" sortable body={statusBodyTemplate} filter filterElement={statusFilterCustom} />
                            <Column headerStyle={{ width: '10%' }} className="p-text-center" field="action" header="Actions" sortable body={actionBodyTemplate} />
                        </DataTable>
                    </>
                }
            </div>
            <DialogCustom
                show={resetPassword} setShow={setResetPassword}
                type="warning"
                icon="pi-info-circle"
                position="right"
                header="Reset Password"
                description={`Do you want to reset the password of ${selectedUser.ds_full_name}?`}
                functionOk={() => alert("Add reset password")}
            />
            <DialogCustom
                show={deleteUser} setShow={setDeleteUser}
                type="danger"
                icon="pi-exclamation-triangle"
                position="right"
                header="Delete User"
                description={`You want to delete the user ${selectedUser.ds_full_name}?`}
                functionOk={() => dispatch(actionsUsersList.deleteUser(selectedUser.id_user))}
            />
            <DialogUserDeatil
                user={userDetail}
                show={!listLoading && userDetail && showDialogDetail}
                setShow={setshowDialogDetail}
            />
        </div>
    )
}

export default UserList;