import axios from 'axios';
import { URL_SUBSCRIPTIONS, URL_CANCEL_SUBSCRIPTION } from './config';

export async function getPSubscriptionsList() {
    const result = await axios.get(URL_SUBSCRIPTIONS);
    return result.data.data;
};//SELECT * FROM admin_panel.sp_get_subscriptions_summary()

export async function getPSubscriptionsDetails(id) {
    const result = await axios.get(`${URL_SUBSCRIPTIONS}/${id}`);
    return result.data.data;
};//admin_panel.sp_get_subscription_to_cancel

export async function postMakeCancel(data) {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }//// CAMBIAR POR LA DATA QUE SE NECESITA PARA CANCELAR UNA SUBSCRIPCION
    };
    const result = await axios.post(`${URL_CANCEL_SUBSCRIPTION}`, data, config);
    return result.data;
};